import React from "react";
import { vehicles } from "../data/Vehicles";
import { vehicle } from "../interfaces/Vehicle";

interface VehiclePickerProps {
  selectedVehicle: vehicle;
  onSelect: (vehicle: any) => void;
}

function VehiclePicker(props: VehiclePickerProps) {
  function handleSelected(vehicle: vehicle) {
    props.onSelect(vehicle);
  }

  return (
    <div className="mt-3 mb-12 animate-fadeInSlow">
      <div className="grid grid-cols-2 gap-5 mt-4">
        {vehicles.map((v) => {
          return (
            <div
              key={v.id}
              onClick={() => {
                handleSelected(v);
              }}
              className={` ${
                props.selectedVehicle.id === v.id
                  ? "border-primary border-2"
                  : "border-white border-2"
              } cursor-pointer h-full flex flex-col justify-between bg-white rounded-sm shadow-xl hover:shadow-2xl transition-shadow duration-150 ease-in p-3`}
            >
              <img
                src={v.imagePath}
                className="w-4/5 mx-auto mb-3"
                alt={v.name}
              ></img>
              <h3 className="font-medium text-lg">{v.name}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default VehiclePicker;
