import { useState } from "react";

import { model, towable, vehicle } from "../interfaces/Vehicle";
import { terrain } from "../data/Terrains";
import FordLogo from "./FordLogo";
import LocationSearchBox from "./LocationSearchBox";
import "tippy.js/dist/tippy.css";

import ChargingPanel from "./ChargingPanel";
import RangePanel from "./RangePanel";
import {
  chargeMode,
  chargeModes,
  homeChargingOption
} from "../interfaces/ChargeMode";
interface ControlPanelProps {
  didReset: () => void;
  didProceed: () => void;
  didSelectLocation: () => void;
  didNavigateBack: () => void;
  showChargingPanel: () => void;
  vehicleSelected: (id: number) => void;
  modelSelected: (id: number) => void;
  towableSelected: (id: number) => void;
  terrainSelected: (id: number) => void;
  temperatureSelected: (temp: number) => void;
  extendedRangeSelected: () => void;
  roundTripSelected: () => void;
  plusOneChargeSelected: () => void;
  chargeModeUpdated: (id: number) => void;
  homeChargingOptionUpdated: (id: number) => void;
  toggledChargingStationFilterFree: () => void;
  toggledChargingStationFilterBOCN: () => void;
  toggledChargingStationFilterHighSpeed: () => void;
  toggledRangePanelChargingLocations: () => void;
  selectedExtendedRange: boolean;
  selectedRoundTrip: boolean;
  selectedPlusOneCharge: boolean;
  selectedVehicle: vehicle;
  selectedModel: model;
  selectedTowable?: towable;
  selectedTerrain?: terrain;
  selectedTemperature: number;
  locationPanelVisible: boolean;
  rangePanelVisible: boolean;
  chargingPanelVisible: boolean;
  selectedChargeMode: chargeMode;
  selectedHomeChargingOption: homeChargingOption;
  selectedChargingStationFilterFree: boolean;
  selectedChargingStationFilterBOCN: boolean;
  selectedChargingStationFilterHighSpeed: boolean;
  selectedRangePanelChargingLocations: boolean;
}

function ControlPanel(props: ControlPanelProps) {
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [locationValue, setLocationValue] = useState("");

  function handleReset() {
    setLocationValue("");
    setInputFocused(true);
    props.didSelectLocation();
    props.didReset();
  }

  function handleLocationSelected() {
    props.didSelectLocation();
  }

  return (
    <div
      className={`z-10 text-primary animate-fadeInSlow p-6 flex flex-col bg-gray-100 h-screen overflow-y-scroll fixed top-0 left-0 transition-width ease-in duration-200 ${
        props.chargingPanelVisible &&
        props.selectedChargeMode === chargeModes[0]
          ? "w-[500px]"
          : "w-[370px]"
      }`}
    >
      <div className="flex justify-between items-center mt-4">
        <div className="cursor-pointer" onClick={handleReset}>
          <FordLogo />
        </div>
        {props.selectedVehicle && props.chargingPanelVisible && (
          <div className="rounded-xl border-primary px-3 py-1 bg-white border">
            {props.selectedVehicle.name}
          </div>
        )}
        {props.rangePanelVisible && (
          <div onClick={handleReset} className="hover:underline cursor-pointer">
            ← Back
          </div>
        )}
        {props.chargingPanelVisible && (
          <div
            onClick={props.didNavigateBack}
            className="hover:underline cursor-pointer"
          >
            ← Back
          </div>
        )}
      </div>
      {props.locationPanelVisible && (
        <div className="animate-fadeInSlow">
          <h1 className="text-2xl mt-8">Oh, the places you'll go.</h1>
          <p className="text-lg mt-2 text-gray-400 font-light">
            Discover where electric can take you.
          </p>

          <LocationSearchBox
            visible={props.locationPanelVisible}
            focused={inputFocused}
            addressOptionHidden={!props.rangePanelVisible}
            value={locationValue}
            onClick={handleLocationSelected}
          />
        </div>
      )}

      <RangePanel
        visible={props.rangePanelVisible}
        vehicleSelected={props.vehicleSelected}
        selectedVehicle={props.selectedVehicle}
        modelSelected={props.modelSelected}
        selectedModel={props.selectedModel}
        extendedRangeSelected={props.extendedRangeSelected}
        selectedExtendedRange={props.selectedExtendedRange}
        selectedRoundTrip={props.selectedRoundTrip}
        roundTripSelected={props.roundTripSelected}
        selectedPlusOneCharge={props.selectedPlusOneCharge}
        plusOneChargeSelected={props.plusOneChargeSelected}
        towableSelected={props.towableSelected}
        selectedTowable={props.selectedTowable}
        terrainSelected={props.terrainSelected}
        selectedTerrain={props.selectedTerrain}
        didProceed={props.didProceed}
        rangePanelChargingLocationsVisible={
          props.selectedRangePanelChargingLocations
        }
        toggledRangePanelChargingLocations={
          props.toggledRangePanelChargingLocations
        }
      />

      <ChargingPanel
        visible={props.chargingPanelVisible}
        toggledChargingStationFilterFree={
          props.toggledChargingStationFilterFree
        }
        toggledChargingStationFilterBOCN={
          props.toggledChargingStationFilterBOCN
        }
        toggledChargingStationFilterHighSpeed={
          props.toggledChargingStationFilterHighSpeed
        }
        selectedChargingStationFilterHighSpeed={
          props.selectedChargingStationFilterHighSpeed
        }
        selectedChargingStationFilterFree={
          props.selectedChargingStationFilterFree
        }
        selectedChargingStationFilterBOCN={
          props.selectedChargingStationFilterBOCN
        }
        updatedChargeMode={props.chargeModeUpdated}
        selectedChargeMode={props.selectedChargeMode}
        selectedHomeChargingOption={props.selectedHomeChargingOption}
        updatedHomeChargingOption={props.homeChargingOptionUpdated}
      />
    </div>
  );
}

export default ControlPanel;
