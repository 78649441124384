import * as React from "react";
import { SVGProps } from "react";

const IconCharge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.581.192c.515.349.654 1.056.31 1.58L3.214 5.86H6.88c.413 0 .793.232.987.602.195.37.174.82-.055 1.17l-3.84 5.86a1.108 1.108 0 0 1-1.553.316 1.152 1.152 0 0 1-.31-1.58L4.786 8.14H1.12c-.413 0-.793-.232-.987-.602-.195-.37-.174-.82.055-1.17l3.84-5.86A1.108 1.108 0 0 1 5.581.192Z"
      fill="#003478"
    />
  </svg>
);

export default IconCharge;
