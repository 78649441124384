import { Popup } from "react-map-gl";
import { Check } from "react-feather";
import { toTitleCase } from "./utilities/toTitleCase";
import IconCharge from "./IconCharge";
import IconFordCharging from "./IconFordCharging";

interface PopUpProps {
  index: number;
  marker: any;
  open: boolean;
  onClose: () => void;
}
export const PopUp = (props: PopUpProps) => {
  return (
    <Popup
      key={props.index}
      longitude={props.marker.geometry.coordinates[0]}
      latitude={props.marker.geometry.coordinates[1]}
      className="p-6"
      closeButton={true} // TODO Update this so its not terrible
      closeOnClick={false}
      maxWidth="320px"
      onClose={props.onClose}
    >
      <div className="flex items-center justify-between">
        <div className="text-lg mb-1 text-primary">
          {toTitleCase(props.marker.properties.station_name)}
        </div>
        {/* <div
                          onClick={() => {}}
                          className="bg-slate-200 rounded-full flex items-center justify-center ml-2 p-1 cursor-pointer hover:opacity-70"
                        >
                          <X size={16} className="" />
                        </div> */}
      </div>
      <div className="w-auto flex border-b border-slate-300 py-1 justify-between items-center text-base">
        <div className="text-left text-sm text-slate-500 mr-4">
          <span className="text-black">Time</span> to charge 100 miles
        </div>
        <div className="text-right text-sm">
          {props.marker.properties.minutes_required_for_100_miles
            ? props.marker.properties.minutes_required_for_100_miles
            : Math.floor(Math.random() * (48 - 30 + 1) + 30)}{" "}
          min
        </div>
      </div>
      <div className="w-auto flex border-b border-slate-300 py-1 justify-between items-center text-base">
        <div className="text-left text-sm text-slate-500 mr-4">
          <span className="text-black">Cost</span> to charge 100 miles
        </div>
        <div className="text-right text-sm">
          {props.marker.properties.ev_pricing === "Free"
            ? "Free"
            : `$
            ${
              props.marker.properties.cost_required_for_100_miles
                ? props.marker.properties.cost_required_for_100_miles
                : Math.floor(Math.random() * (15 - 10 + 1) + 10)
            }`}
        </div>
      </div>
      <div className="text-sm mt-3 text-green-500">
        {Math.floor(Math.random() * (9 - 1 + 1) + 1)} of{" "}
        {Math.floor(Math.random() * (10 - 9 + 1) + 9)} available now
      </div>
      <div className="text-xs text-gray-400 mt-1 mb-2">
        {toTitleCase(props.marker.properties.street_address)}
        <br />
        {toTitleCase(props.marker.properties.city)},{" "}
        {props.marker.properties.state}{" "}
        {toTitleCase(props.marker.properties.zip)}
      </div>
      <div className="flex items-center justify-between flex-no-wrap ">
        <div className="bg-slate-200 flex-grow rounded flex items-center justify-center text-slate-500 mx-1 p-1">
          <IconCharge /> <div className="ml-2 text-xs">Level 3 (150 kW) </div>
        </div>
        {props.marker.properties.ev_network === "ChargePoint Network" && (
          <div className="flex items-center justify-center text-xs text-white font-medium bg-primary mx-1 rounded p-1">
            <Check color="#FFF" size={16} className="mr-1" /> In-network
          </div>
        )}
      </div>

      {props.marker.properties.intersection_directions && (
        <div className="text-xs text-gray-500 mt-2">
          {toTitleCase(props.marker.properties.intersection_directions)}
        </div>
      )}
    </Popup>
  );
};
