
export interface terrain {
  id: number,
  name: string,
  dragCoeffecient: number,
  iconPath: string
}

export const terrains:terrain[] = [
  {
    id: 1,
    name: "Flat",
    dragCoeffecient: 0.2,
    iconPath: "images/icons/terrain-flat.svg"
  },
  {
    id: 2,
    name: "Hilly",
    dragCoeffecient: 0.7,
    iconPath: "images/icons/terrain-hilly.svg"
  },
  {
    id: 3,
    name: "Mountainous",
    dragCoeffecient: 0.5,
    iconPath: "images/icons/terrain-mountainous.svg"
  }

  ]