import * as turf from "@turf/turf";

const line = turf.lineString([
  [-86.47338867187499, 43.723474896114794],
  [-86.5283203125, 43.683763524273346],
  [-86.5283203125, 43.624147145668076],
  [-86.51184082031249, 43.54456658436357],
  [-86.46240234375, 43.46886761482923],
  [-86.429443359375, 43.38109758727857],
  [-86.37451171875, 43.265206318396],
  [-86.30859375, 43.16512263158296],
  [-86.2646484375, 43.09697190802465],
  [-86.2261962890625, 43.00866413845207],
  [-86.2042236328125, 42.944360446966314],
  [-86.19873046875, 42.771211138625894],
  [-86.2042236328125, 42.65820178455667],
  [-86.253662109375, 42.56117285531808],
  [-86.28662109375, 42.431565872579185],
  [-86.3140869140625, 42.32606244456202],
  [-86.36352539062499, 42.21224516288584],
  [-86.45690917968749, 42.11859868281563],
  [-86.5447998046875, 41.99624282178583],
  [-86.6162109375, 41.89409955811395],
  [-86.693115234375, 41.832735062152615],
  [-86.75354003906249, 41.78360106648078],
  [-86.781005859375, 41.74672584176937],
  [-86.7755126953125, 41.705728515237524],
  [-86.6546630859375, 41.68932225997044],
  [-86.5338134765625, 41.701627343789184],
  [-86.36352539062499, 41.74262728637672],
  [-86.3360595703125, 41.65649719441145],
  [-86.253662109375, 41.623655390686395],
  [-86.1492919921875, 41.623655390686395],
  [-85.97351074218749, 41.6154423246811],
  [-85.8746337890625, 41.65649719441145],
  [-85.84167480468749, 41.72623044860004],
  [-85.75927734375, 41.74672584176937],
  [-85.6549072265625, 41.75492216766298],
  [-85.54504394531249, 41.75492216766298],
  [-85.418701171875, 41.75492216766298],
  [-85.2593994140625, 41.75492216766298],
  [-85.078125, 41.74262728637672],
  [-85.01220703125, 41.73852846935917],
  [-84.9407958984375, 41.68932225997044],
  [-84.8638916015625, 41.672911819602085],
  [-84.693603515625, 41.65649719441145],
  [-84.57275390625, 41.65649719441145],
  [-84.5013427734375, 41.65649719441145],
  [-84.39147949218749, 41.66060124302088],
  [-84.276123046875, 41.66060124302088],
  [-84.1717529296875, 41.66060124302088],
  [-84.0179443359375, 41.6770148220322],
  [-83.86962890625, 41.693424216151314],
  [-83.7762451171875, 41.7631174470059],
  [-83.81469726562499, 41.79998325207397],
  [-83.69384765625, 41.89818843043047],
  [-83.73779296875, 41.94314874732696],
  [-83.6553955078125, 42.00848901572399],
  [-83.594970703125, 42.09822241118974],
  [-83.51806640624999, 42.19596877629178],
  [-83.5125732421875, 42.248851700720955],
  [-83.5675048828125, 42.27730877423709],
  [-83.5784912109375, 42.354484651067466],
  [-83.6444091796875, 42.382894009614056],
  [-83.682861328125, 42.41940144722477],
  [-83.6334228515625, 42.512601715736665],
  [-83.6334228515625, 42.62183364891663],
  [-83.5675048828125, 42.64204079304426],
  [-83.47412109375, 42.67839711889055],
  [-83.419189453125, 42.718768102606354],
  [-83.3367919921875, 42.819580715795915],
  [-83.3807373046875, 42.871938424448466],
  [-83.309326171875, 42.984558134256076],
  [-83.309326171875, 43.03677585761058],
  [-83.2818603515625, 43.08493742707592],
  [-83.3477783203125, 43.14909399920127],
  [-83.3477783203125, 43.16912913272099],
  [-83.3917236328125, 43.201171681272456],
  [-83.397216796875, 43.26120612479979],
  [-83.4027099609375, 43.329173667843904],
  [-83.4521484375, 43.35713822211053],
  [-83.4686279296875, 43.40504748787035],
  [-83.51806640624999, 43.492782808225],
  [-83.5565185546875, 43.53660274231031],
  [-83.6553955078125, 43.53660274231031],
  [-83.671875, 43.57641143300888],
  [-83.682861328125, 43.60823944964323],
  [-83.726806640625, 43.624147145668076],
  [-83.8092041015625, 43.6599240747891],
  [-83.84765625, 43.671844983221604],
  [-83.90808105468749, 43.683763524273346],
  [-83.95751953125, 43.71156424665851],
  [-83.95751953125, 43.77109381775648],
  [-83.9300537109375, 43.82263823180498],
  [-83.91357421875, 43.84245116699039],
  [-83.91357421875, 43.88205730390537],
  [-83.91357421875, 43.9058083561574],
  [-83.95751953125, 43.93350594453702],
  [-83.9630126953125, 43.97305156068593],
  [-83.9849853515625, 44.02837121279199],
  [-84.0179443359375, 44.08363928284644],
  [-84.04541015625, 44.10730980734024],
  [-84.08935546875, 44.15068115978094],
  [-84.0179443359375, 44.19795903948531],
  [-83.9190673828125, 44.25700308645885],
  [-83.9190673828125, 44.308126684886126],
  [-83.9630126953125, 44.32384807250689],
  [-84.012451171875, 44.32384807250689],
  [-84.0673828125, 44.33956524809713],
  [-84.0838623046875, 44.39846714225848],
  [-84.13330078125, 44.402391829093915],
  [-84.14978027343749, 44.374913492661456],
  [-84.210205078125, 44.35527821160296],
  [-84.2596435546875, 44.351350365612326],
  [-84.3255615234375, 44.351350365612326],
  [-84.3585205078125, 44.35527821160296],
  [-84.407958984375, 44.41024041296011],
  [-84.4573974609375, 44.449467536006935],
  [-84.517822265625, 44.453388800301774],
  [-84.57824707031249, 44.465151013519616],
  [-84.6221923828125, 44.5435052132082],
  [-84.6661376953125, 44.55916341529182],
  [-84.6990966796875, 44.58655513209543],
  [-84.72656249999999, 44.63739123445585],
  [-84.7540283203125, 44.653024159812],
  [-84.7869873046875, 44.71161010858431],
  [-84.825439453125, 44.74673324024678],
  [-84.87487792968749, 44.766236875162335],
  [-84.91333007812499, 44.77793589631623],
  [-84.935302734375, 44.81691551782855],
  [-84.96826171874999, 44.84418558537004],
  [-85.001220703125, 44.87144275016589],
  [-85.0177001953125, 44.91035917458495],
  [-85.0506591796875, 44.883120442385646],
  [-85.0946044921875, 44.865603015341954],
  [-85.11932373046875, 44.84808025602071],
  [-85.14678955078125, 44.83834308566653],
  [-85.17974853515625, 44.80327564555043],
  [-85.2374267578125, 44.79158175909386],
  [-85.25665283203124, 44.811070253260006],
  [-85.29510498046875, 44.78768327030475],
  [-85.352783203125, 44.78573392716592],
  [-85.40496826171874, 44.78573392716592],
  [-85.440673828125, 44.78573392716592],
  [-85.47088623046875, 44.78378451819761],
  [-85.48187255859375, 44.76428680790121],
  [-85.50659179687499, 44.76038647589176],
  [-85.5560302734375, 44.758436211143476],
  [-85.60821533203125, 44.766236875162335],
  [-85.64117431640625, 44.779885502772736],
  [-85.66864013671875, 44.80327564555043],
  [-85.68511962890624, 44.813018740612776],
  [-85.69610595703125, 44.78768327030475],
  [-85.74554443359375, 44.78183504339988],
  [-85.77301025390625, 44.78573392716592],
  [-85.81146240234375, 44.80522439622254],
  [-85.84442138671875, 44.824708282300236],
  [-85.89385986328125, 44.8500274926005],
  [-85.93231201171875, 44.873389196779264],
  [-85.96527099609375, 44.867549659447214],
  [-85.9844970703125, 44.8500274926005],
  [-86.06689453125, 44.89868701215517],
  [-86.06964111328125, 44.84808025602071],
  [-86.06964111328125, 44.80327564555043],
  [-86.0723876953125, 44.77013681219717],
  [-86.10260009765625, 44.735027899515465],
  [-86.1492919921875, 44.72917434046452],
  [-86.1822509765625, 44.72527163862197],
  [-86.23992919921875, 44.70380207177485],
  [-86.2591552734375, 44.67646564865964],
  [-86.2481689453125, 44.63934558051711],
  [-86.21795654296875, 44.5924231071787],
  [-86.2261962890625, 44.56307730757893],
  [-86.24542236328125, 44.5278427984555],
  [-86.24542236328125, 44.471031231561845],
  [-86.24542236328125, 44.42397290075389],
  [-86.2646484375, 44.35920579433503],
  [-86.297607421875, 44.302230078625456],
  [-86.341552734375, 44.25110134697976],
  [-86.3800048828125, 44.201897151875094],
  [-86.41021728515624, 44.15462243076731],
  [-86.451416015625, 44.10336537791152],
  [-86.50634765625, 44.07377376789347],
  [-86.517333984375, 44.04811573082351],
  [-86.4898681640625, 44.004669106432225],
  [-86.46240234375, 43.97305156068593],
  [-86.451416015625, 43.94537239244209],
  [-86.41845703124999, 43.858296779161826],
  [-86.396484375, 43.79488907226601],
  [-86.47338867187499, 43.723474896114794]
]);
export const poly150Stroke = turf.bezierSpline(line);
