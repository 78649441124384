import { vehicle } from "../interfaces/Vehicle";

export const vehicles:vehicle[] = [
  {
    id: 1,
    name: "Mustang Mach-e",
    imagePath: "./images/mach-e.jpg",
    mobilePowerCordIncluded: false,
    models: [
      {
        id: 1,
        name: "Select",
        standardRange: 247
      },
      {
        id: 2,
        name: "GT",
        standardRange: 270
      },
      {
        id: 3,
        name: "Premium",
        standardRange: 303
      },
      {
        id: 4,
        name: "California Route 1",
        standardRange: 314
      }
    ]
  },
  {
    id: 2,
    name: "F-150 Lightning",
    imagePath: "./images/lightning.png",
    mobilePowerCordIncluded: true,
    models: [
      {
        id: 1,
        name: "Pro",
        standardRange: 230,
        standardTowingCapacity: 5000
      },
      {
        id: 2,
        name: "XLT",
        standardRange: 230,
        extendedRange: 320,
        standardTowingCapacity: 5000,
        extendedTowingCapacity: 7700
      },
      {
        id: 3,
        name: "Lariat",
        standardRange: 230,
        extendedRange: 320,
        standardTowingCapacity: 5000,
        extendedTowingCapacity: 7700
      },
      {
        id: 4,
        name: "Platinum",
        standardRange: 300,
        standardTowingCapacity: 8500
      }
    ]
  }
];
