import Tippy from "@tippyjs/react";
import { BatteryCharging, Check, Repeat } from "react-feather";
import { terrain } from "../data/Terrains";
import { vehicles } from "../data/Vehicles";
import { model, towable, vehicle } from "../interfaces/Vehicle";
import DrivingSpeedSlider from "./DrivingSpeedSlider";
import ModelPicker from "./ModelPicker";
// import TerrainPicker from "./TerrainPicker";
import TowablePicker from "./TowablePicker";
import VehiclePicker from "./VehiclePicker";
import CabinTemperatureSlider from "./CabinTemperatureSlider";
import OutdoorTemperatureSlider from "./OutdoorTemperatureSlider";

interface RangePanelProps {
  visible: boolean;
  vehicleSelected: (id: number) => void;
  selectedVehicle: vehicle;
  modelSelected: (id: number) => void;
  selectedModel: model;
  extendedRangeSelected: () => void;
  selectedExtendedRange: boolean;
  selectedRoundTrip: boolean;
  roundTripSelected: () => void;
  selectedPlusOneCharge: boolean;
  plusOneChargeSelected: () => void;
  towableSelected: (id: number) => void;
  selectedTowable?: towable;
  terrainSelected: (id: number) => void;
  selectedTerrain?: terrain;
  didProceed: () => void;
  rangePanelChargingLocationsVisible: boolean;
  toggledRangePanelChargingLocations: () => void;
}
function RangePanel(props: RangePanelProps) {

  if (props.visible) {
    return (
      <>
        <VehiclePicker
          onSelect={(vehicle: vehicle) => {
            props.vehicleSelected(vehicle.id);
          }}
          selectedVehicle={props.selectedVehicle}
        />
        {props.vehicleSelected && (
          <>
            <h3 className="mb-2 text-xl -mt-5 animate-fadeInSlow">Model</h3>
            <ModelPicker
              onSelect={(id: number) => {
                props.modelSelected(id);
              }}
              vehicle={props.selectedVehicle}
              selectedModel={props.selectedModel}
              extendedRangeEnabled={props.selectedExtendedRange}
            />
            {props.selectedModel.extendedRange && (
              <div
                onClick={() => {
                  props.extendedRangeSelected();
                }}
                className={`${
                  props.selectedExtendedRange
                    ? " border-primary text-primary"
                    : "border-slate-200 text-slate-600"
                } "rounded-sm bg-white w-full px-4 mt-2 cursor-pointer py-3 border-2 shadow-lg hover:shadow-xl flex items-center justify-center"`}
              >
                {props.selectedExtendedRange ? (
                  <Check className="text-primary" />
                ) : (
                  <Check className="text-slate-200" />
                )}
                <div className="ml-3">Extended Range</div>
              </div>
            )}
            <div className="w-full grid grid-cols-2 gap-2 mt-6 items-start justify-between flex-nowrap">
              <Tippy
                key="roundTrip"
                placement="bottom"
                content="If you need to get back without charging"
                className="bg-white text-primary"
              >
                <div
                  onClick={props.roundTripSelected}
                  className={`${
                    props.selectedRoundTrip
                      ? " border-primary text-primary"
                      : "border-slate-200 text-slate-600"
                  } rounded-sm animate-fadeInSlow bg-white px-2 cursor-pointer py-3 border shadow-lg hover:shadow-xl flex items-center justify-center`}
                >
                  {props.selectedRoundTrip ? (
                    <Repeat width={18} className="text-primary" />
                  ) : (
                    <Repeat width={18} className="text-slate-200" />
                  )}
                  <div className="ml-2">Round trip</div>
                </div>
              </Tippy>
              <Tippy
                key="plusOne"
                placement="bottom"
                content="If you stopped and charged once"
                className="bg-white text-primary"
              >
                <div
                  onClick={props.plusOneChargeSelected}
                  className={`${
                    props.selectedPlusOneCharge
                      ? " border-primary text-primary"
                      : "border-slate-200 text-slate-600"
                  } rounded-sm animate-fadeInSlow bg-white px-2 cursor-pointer py-3 border shadow-lg hover:shadow-xl flex items-center justify-center`}
                >
                  {props.selectedPlusOneCharge ? (
                    <BatteryCharging className="text-primary" />
                  ) : (
                    <BatteryCharging className="text-slate-200" />
                  )}
                  <div className="ml-2">+1 charge</div>
                </div>
              </Tippy>
            </div>
            <OutdoorTemperatureSlider />
            {props.selectedVehicle === vehicles[1] && ( // Only show towing options for the Lightning
              <TowablePicker
                onSelect={(towable: towable) => {
                  props.towableSelected(towable.id);
                }}
                selectedTowable={props.selectedTowable}
              />
            )}




                <CabinTemperatureSlider />
                <DrivingSpeedSlider />
                {/*<TerrainPicker*/}
                {/*  onSelect={(terrain: terrain) => {*/}
                {/*    props.terrainSelected(terrain.id);*/}
                {/*  }}*/}
                {/*  selectedTerrain={props.selectedTerrain}*/}
                {/*/>*/}



            <div
              onClick={() => {
                props.didProceed();
              }}
              className="rounded-sm mb-4 my-6 animate-fadeInSlow bg-primary flex items-center justify-center py-3 text-white cursor-pointer shadow-lg hover:shadow-xl transition-shadow duration-200 ease-in"
            >
              Continue to Charging →
            </div>
          </>
        )}
      </>
    );
  }
}

export default RangePanel;
