import * as React from "react";
import { SVGProps } from "react";

const IconFordCharging = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={63}
    fill="none"
    {...props}
  >
    <mask id="a" fill="#fff">
      <rect width={41} height={63} rx={2} />
    </mask>
    <rect
      width={41}
      height={63}
      rx={2}
      stroke="#003478"
      strokeWidth={5}
      mask="url(#a)"
    />
    <circle cx={20.5} cy={31} r={13} stroke="#003478" strokeWidth={2} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.884 25.164c.45.3.572.906.271 1.355l-2.344 3.504h3.209a.98.98 0 0 1 .864.516.974.974 0 0 1-.049 1.003l-3.36 5.023a.982.982 0 0 1-1.359.27.975.975 0 0 1-.271-1.354l2.344-3.504H13.98a.98.98 0 0 1-.864-.516.974.974 0 0 1 .049-1.003l3.36-5.023a.982.982 0 0 1 1.359-.27Z"
      fill="#003478"
    />
    <path
      stroke="#003478"
      strokeWidth={2}
      strokeLinecap="round"
      d="M23 34h3M23 31h3"
    />
  </svg>
);

export default IconFordCharging;
