import { useRef, useState } from "react";
import Map, { Layer, MapRef, Marker, Source } from "react-map-gl";
import ControlPanel from "./ControlPanel";
import { Home, MapPin } from "react-feather";
import "mapbox-gl/dist/mapbox-gl.css";
import { vehicles } from "../data/Vehicles";
import { towables } from "../data/Towables";
import { HOME_LAT_LON, PRIMARY_BRAND_COLOR } from "../data/Constants";
import {
  chargeModes,
  homeChargingOption,
  homeChargingOptions
} from "../interfaces/ChargeMode";
import { homeBaseRangeStroke } from "../data/polygons/homebaseRange-stroke";
import { poly150Stroke } from "../data/polygons/poly150-stroke";
import { poly30Stroke } from "../data/polygons/poly30-stroke";
import { polyChargeStroke } from "../data/polygons/polyCharge-stroke";
import { homeBaseRangeExtendedStroke } from "../data/polygons/homebaseRangeExtended-stroke";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { MapboxPopup } from "react-map-gl/dist/esm/types";
import { PopUp } from "./PopUp";
import { terrains } from "../data/Terrains";
import { poly230Stroke } from "../data/polygons/poly230-stroke";
import { poly320Stroke } from "../data/polygons/poly320-stroke";

function MapTiles() {
  const mapRef = useRef<MapRef | null>(null);
  const [chargingLocations, setChargingLocations] = useState(
    require("../data/chargingLocations.json").features.map((f) => ({
      ...f,
      active: true
    }))
  );

  const [filteredChargingLocations, setFilteredChargingLocations] =
    useState(chargingLocations);
  const [homeMapMarkerHidden, setHomeMapMarkerHidden] = useState(true);
  const [homeBaseRangeHidden, setHomeBaseRangeHidden] = useState(true);
  const [homeBaseRange, setHomeBaseRange] = useState(
    require("../data/polygons/homeBaseRange.json")
  );

  const [selectedVehicle, setSelectedVehicle] = useState(vehicles[0]);
  const [selectedModel, setSelectedModel] = useState(vehicles[0].models[1]);
  const [selectedTemperature, setSelectedTemperature] = useState(60);
  const [selectedTowable, setSelectedTowable] = useState(null);
  const [selectedTerrain, setSelectedTerrain] = useState(terrains[0]);

  const [locationSelected, setLocationSelected] = useState(false);
  const [selectedExtendedRange, setSelectedExtendedRange] = useState(false);
  const [selectedRoundTrip, setSelectedRoundTrip] = useState(false);
  const [selectedPlusOneCharge, setSelectedPlusOneCharge] = useState(false);
  const [locationPanelVisible, setLocationPanelVisible] = useState(true);
  const [rangePanelVisible, setRangePanelVisible] = useState(false);
  const [chargingPanelVisible, setChargingPanelVisible] = useState(false);
  const [selectedChargeMode, setSelectedChargeMode] = useState(chargeModes[0]);
  const [selectedHomeChargingOption, setSelectedHomeChargingOption] =
    useState<homeChargingOption>(null);
  const [
    selectedChargingStationFilterFree,
    setSelectedChargingStationFilterFree
  ] = useState(false);
  const [
    selectedChargingStationFilterBOCN,
    setSelectedChargingStationFilterBOCN
  ] = useState(false);
  const [
    selectedChargingStationFilterNearby,
    setSelectedChargingStationFilterNearby
  ] = useState(false);
  const [
    selectedChargingStationFilterOneChargeAway,
    setSelectedChargingStationFilterOneChargeAway
  ] = useState(false);
  const [
    selectedChargingStationFilterHighSpeed,
    setSelectedChargingStationFilterHighSpeed
  ] = useState(false);
  const [popupOpen, setPopupOpen] = useState(null);
  const [
    rangePanelChargingLocationsVisible,
    setRangePanelChargingLocationsVisible
  ] = useState(false);

  function onLoad() {
    mapRef.current?.flyTo({
      center: [-95.2996, 38.1164],
      essential: true,
      zoom: 3.4,
      duration: 8000,
      pitch: 0,
      bearing: 0
    });
  }

  function handleVehicleSelected(id: number) {
    setSelectedExtendedRange(false);
    if (selectedVehicle.id === id) {
      return;
    } else {
      const vehicle = vehicles.find((v) => v.id === id);
      setSelectedVehicle(vehicle);
      setSelectedModel(vehicle.models[1]);
      setSelectedPlusOneCharge(false);
      setSelectedRoundTrip(false);
      if (id === 2) {
        mapRef.current?.zoomTo(6.25);
      } else {
        mapRef.current?.zoomTo(6);
      }
    }
  }

  function handleVehicleModelSelected(id: number) {
    setSelectedExtendedRange(false);
    setSelectedPlusOneCharge(false);
    setSelectedRoundTrip(false);
    setSelectedModel(selectedVehicle.models.find((m) => m.id === id));
  }

  function handleTemperatureSelected(temp: number) {
    setSelectedTemperature(temp);
  }

  function handleTowableSelected(id: number) {
    if (selectedTowable && selectedTowable.id === id) {
      setSelectedTowable(null);
    } else {
      setSelectedTowable(towables.find((t) => t.id === id));
    }
  }

  function handleTerrainSelected(id: number) {
    if (selectedTerrain && selectedTerrain.id === id) {
      setSelectedTerrain(null);
    } else {
      setSelectedTerrain(terrains.find((t) => t.id === id));
    }
  }

  function handleExtendedRangeSelected() {
    if (!selectedExtendedRange) {
      mapRef.current?.zoomTo(5.8);
    } else {
      mapRef.current?.zoomTo(6.25);
    }
    setSelectedPlusOneCharge(false);
    setSelectedRoundTrip(false);
    setSelectedExtendedRange(!selectedExtendedRange);
  }

  function handlePlusOneChargeSelected() {
    setSelectedPlusOneCharge(!selectedPlusOneCharge);
    if (!selectedPlusOneCharge) {
      mapRef.current?.zoomTo(5.4);
    } else {
      mapRef.current?.zoomTo(6);
    }
  }

  function handleRoundTripSelected() {
    setSelectedRoundTrip(!selectedRoundTrip);
    if (!selectedPlusOneCharge) {
      mapRef.current?.zoomTo(6);
    }
  }

  function handleProceed() {
    setChargingPanelVisible(true);
    setRangePanelVisible(false);
    const mode = chargeModes[0];
    mapRef.current?.flyTo({
      center: [mode.lat, mode.lon],
      essential: true,
      zoom: mode.zoom,
      duration: 3000,
      pitch: 0,
      bearing: 0,
      speed: 20
    });
    setSelectedHomeChargingOption(homeChargingOptions[0]);
  }

  function handleReset() {
    setHomeBaseRangeHidden(true);
    setLocationSelected(false);
    setLocationPanelVisible(true);
    setChargingPanelVisible(false);
    setHomeMapMarkerHidden(true);
    setSelectedVehicle(vehicles[0]);
    setSelectedTowable(null);
    setSelectedTerrain(terrains[0]);
    setRangePanelVisible(false);
    setChargingPanelVisible(false);
    setSelectedExtendedRange(false);
    setSelectedPlusOneCharge(false);
    setSelectedTemperature(60);
    setSelectedModel(vehicles[0].models[1]);
    setSelectedHomeChargingOption(null);
    mapRef.current?.flyTo({
      center: [-95.2996, 38.1164],
      essential: true,
      zoom: 3.4,
      duration: 4000,
      pitch: 0,
      bearing: 0
    });
  }

  function handleNavigateBack() {
    setChargingPanelVisible(false);
    setRangePanelVisible(true);
    setSelectedChargeMode(chargeModes[0]);
    setSelectedHomeChargingOption(null);
    setSelectedRoundTrip(false);
    setSelectedPlusOneCharge(false);
    mapRef.current?.flyTo({
      center: [-85.62212232086667, 42.95256696279633],
      essential: true,
      zoom: 6.2,
      duration: 2000,
      pitch: 0,
      bearing: 0
    });
  }

  function handleLocationSelected() {
    setLocationSelected(true);
    setHomeMapMarkerHidden(false);
    setHomeBaseRangeHidden(false);
    setRangePanelVisible(true);
    setLocationPanelVisible(false);
    mapRef.current?.flyTo({
      center: [-85.62212232086667, 42.95256696279633],
      essential: true,
      zoom: 6,
      duration: 4000,
      pitch: 0,
      bearing: 0
    });
  }

  function handleChargeModeUpdated(id) {
    setPopupOpen(false);
    const mode = chargeModes.find((m) => m.id === id);
    if (id === 1) {
      handleHomeChargingOptionUpdated(0);
    } else {
      mapRef.current?.flyTo({
        center: [-85.62212232086667, 42.95256696279633],
        essential: true,
        zoom: 9,
        duration: 4000,
        pitch: 0,
        bearing: 0
      });
    }
    setSelectedChargeMode(mode);
    setSelectedChargingStationFilterFree(false);
    setSelectedChargingStationFilterBOCN(false);
    setSelectedChargingStationFilterNearby(false);
    setSelectedChargingStationFilterOneChargeAway(false);
    setSelectedChargingStationFilterHighSpeed(false);
    setChargingLocations(
      require("../data/chargingLocations.json").features.map((f) => ({
        ...f,
        active: true
      }))
    );
  }

  function handleHomeChargingOptionUpdated(id) {
    setPopupOpen(false);
    if (id) {
      const option = homeChargingOptions.find((c) => c.id === id);
      setSelectedHomeChargingOption(option);
      mapRef.current?.flyTo({
        center: [-85.62212232086667, 42.95256696279633],
        essential: true,
        zoom: option.zoom,
        duration: 4000,
        pitch: 0,
        bearing: 0
      });
    } else {
      const mode = chargeModes[0];
      setSelectedHomeChargingOption(null);
      mapRef.current?.flyTo({
        center: [mode.lat, mode.lon],
        essential: true,
        zoom: mode.zoom,
        duration: 3000,
        pitch: mode.pitch,
        bearing: mode.bearing,
        speed: 20
      });
    }
  }

  function handleSelectedChargingStationFilterFree() {
    setPopupOpen(false);

    setSelectedChargingStationFilterFree(!selectedChargingStationFilterFree);
    const filtered = chargingLocations.filter(
      (l) => l.properties.ev_pricing === "Free"
    );
    if (!selectedChargingStationFilterFree) {
      if (filtered.length === 0) {
        setFilteredChargingLocations(chargingLocations);
      } else {
        setFilteredChargingLocations(filtered);
      }
    } else {
      setFilteredChargingLocations(chargingLocations);
    }
  }

  function handleSelectedChargingStationFilterBOCN() {
    setPopupOpen(false);
    setSelectedChargingStationFilterBOCN(!selectedChargingStationFilterBOCN);
    if (selectedChargingStationFilterBOCN) {
      setFilteredChargingLocations(chargingLocations);
    } else {
      setFilteredChargingLocations(
        chargingLocations.filter(
          (l) => l.properties.ev_network === "ChargePoint Network"
        )
      );
    }
  }

  function handleSelectedChargingStationFilterHighSpeed() {
    setPopupOpen(false);
    setSelectedChargingStationFilterHighSpeed(
      !selectedChargingStationFilterHighSpeed
    );
    const filtered = chargingLocations.filter(
      (l) => l.properties.ev_level2_evse_num === 1
    );

    if (selectedChargingStationFilterHighSpeed) {
      setFilteredChargingLocations(chargingLocations);
    } else {
      setFilteredChargingLocations(filtered);
    }
  }

  function handleRangePanelChargingLocationsToggle() {}

  return (
    <>
      <ControlPanel
        selectedVehicle={selectedVehicle}
        selectedModel={selectedModel}
        selectedTemperature={selectedTemperature}
        selectedTerrain={selectedTerrain ? selectedTerrain : null}
        selectedTowable={selectedTowable ? selectedTowable : null}
        selectedExtendedRange={selectedExtendedRange}
        selectedRoundTrip={selectedRoundTrip}
        selectedPlusOneCharge={selectedPlusOneCharge}
        locationPanelVisible={locationPanelVisible}
        rangePanelVisible={rangePanelVisible}
        chargingPanelVisible={chargingPanelVisible}
        selectedChargeMode={selectedChargeMode}
        selectedHomeChargingOption={selectedHomeChargingOption}
        extendedRangeSelected={handleExtendedRangeSelected}
        plusOneChargeSelected={handlePlusOneChargeSelected}
        roundTripSelected={handleRoundTripSelected}
        selectedChargingStationFilterFree={selectedChargingStationFilterFree}
        selectedChargingStationFilterBOCN={selectedChargingStationFilterBOCN}
        selectedChargingStationFilterHighSpeed={
          selectedChargingStationFilterHighSpeed
        }
        vehicleSelected={(id) => {
          handleVehicleSelected(id);
        }}
        modelSelected={(id) => {
          handleVehicleModelSelected(id);
        }}
        temperatureSelected={(temp) => {
          handleTemperatureSelected(temp);
        }}
        towableSelected={(id) => {
          handleTowableSelected(id);
        }}
        terrainSelected={(id) => {
          handleTerrainSelected(id);
        }}
        didSelectLocation={handleLocationSelected}
        showChargingPanel={() => {
          setChargingPanelVisible(true);
        }}
        chargeModeUpdated={(id) => {
          handleChargeModeUpdated(id);
          setSelectedHomeChargingOption(null);
        }}
        homeChargingOptionUpdated={(id) => {
          handleHomeChargingOptionUpdated(id || null);
        }}
        toggledChargingStationFilterFree={
          handleSelectedChargingStationFilterFree
        }
        toggledChargingStationFilterBOCN={
          handleSelectedChargingStationFilterBOCN
        }
        toggledChargingStationFilterHighSpeed={
          handleSelectedChargingStationFilterHighSpeed
        }
        toggledRangePanelChargingLocations={
          handleRangePanelChargingLocationsToggle
        }
        selectedRangePanelChargingLocations={rangePanelChargingLocationsVisible}
        didReset={handleReset}
        didProceed={handleProceed}
        didNavigateBack={handleNavigateBack}
      />

      <div className=" transition-all w-full h-full duration-200 ease-in pl-[370px] absolute overflow-hidden">
        <Map
          ref={mapRef}
          antialias
          interactiveLayerIds={["charging"]}
          initialViewState={{
            longitude: -95.2996,
            latitude: 47.1164,
            zoom: 3,
            pitch: 350,
            bearing: 0
          }}
          onLoad={onLoad}
          logoPosition="bottom-right"
          mapStyle="mapbox://styles/jordanfulghum/cl6uxitph000314mtou3xe4wp"
          mapboxAccessToken="pk.eyJ1Ijoiam9yZGFuZnVsZ2h1bSIsImEiOiJjbDZ1eGNwaTEyMmdrM2RwMHR0NWtweG5qIn0.oFDJ3nmVvojrpDKqLvLlIA"
        >
          {/* Roundtrip Small */}
          <Source
            id="roundtripSm"
            type="geojson"
            data="/data/polyRoundTripSmall.json"
          >
            <Layer
              id="roundtrip"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": PRIMARY_BRAND_COLOR,

                "fill-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  selectedRoundTrip &&
                  selectedVehicle.id === 2
                    ? 0.2
                    : 0.0
              }}
            />
          </Source>
          {/* Roundtrip */}
          <Source id="roundtrip" type="geojson" data="/data/polyRoundTrip.json">
            <Layer
              id="roundtrip"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": PRIMARY_BRAND_COLOR,

                "fill-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  selectedRoundTrip &&
                  selectedVehicle.id === 1
                    ? 0.2
                    : 0.0
              }}
            />
          </Source>
          {/* Lightning */}
          <Source id="homebase" type="geojson" data={homeBaseRange}>
            <Layer
              id="1"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": "#003478",

                "fill-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  !selectedExtendedRange &&
                  selectedVehicle.id === 2
                    ? 0.15
                    : 0.0
              }}
            />
          </Source>
          {/* Lightning Stroke */}
          <Source
            id="polygon"
            lineMetrics
            type="geojson"
            data={homeBaseRangeStroke}
          >
            <Layer
              id="2"
              type="line"
              paint={{
                "line-width": {
                  type: "exponential",
                  base: 2,
                  stops: [
                    [0, 1 * Math.pow(1.8, 0 - 3)],
                    [6.5, 1 * Math.pow(1.8, 6.5 - 3)]
                  ]
                },
                "line-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  !selectedPlusOneCharge &&
                  !selectedExtendedRange &&
                  selectedVehicle.id === 2
                    ? 1
                    : 0.0,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#003478",
                  0.1,
                  "royalblue",
                  0.3,
                  "#003478",
                  0.5,
                  "royalblue",
                  0.7,
                  "#003478",
                  1,
                  "#003478"
                ]
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round"
              }}
            />
          </Source>
          {/* Lightning Extended */}
          <Source
            id="homebaseExtended"
            type="geojson"
            data="/data/homeBaseRangeExtended.json"
          >
            <Layer
              id="1E"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": "#003478",

                "fill-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  selectedExtendedRange &&
                  selectedVehicle.id === 2
                    ? 0.15
                    : 0.0
              }}
            />
          </Source>
          {/* Lightning Extended Stroke */}
          <Source
            id="polygonExtended"
            lineMetrics
            type="geojson"
            data={homeBaseRangeExtendedStroke}
          >
            <Layer
              id="2E"
              type="line"
              paint={{
                "line-width": {
                  type: "exponential",
                  base: 2,
                  stops: [
                    [0, 1 * Math.pow(1.8, 0 - 3)],
                    [6.5, 1 * Math.pow(1.8, 6.5 - 3)]
                  ]
                },
                "line-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  selectedExtendedRange &&
                  !selectedPlusOneCharge &&
                  selectedVehicle.id === 2
                    ? 1
                    : 0.0,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#003478",
                  0.1,
                  "royalblue",
                  0.3,
                  "#003478",
                  0.5,
                  "royalblue",
                  0.7,
                  "#003478",
                  1,
                  "#003478"
                ]
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round"
              }}
            />
          </Source>
          {/* Mach e */}
          <Source id="homebase3" type="geojson" data="/data/poly150.json">
            <Layer
              id="4"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": "#003478",
                "fill-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  selectedVehicle.id === 1
                    ? 0.15
                    : 0.0
              }}
            />
          </Source>

          {/* Mach e Stroke */}
          <Source id="polygon2" lineMetrics type="geojson" data={poly150Stroke}>
            <Layer
              id="5"
              type="line"
              paint={{
                "line-width": {
                  type: "exponential",
                  base: 2,
                  stops: [
                    [0, 1 * Math.pow(1.8, 0 - 3)],
                    [6.5, 1 * Math.pow(1.8, 6.5 - 3)]
                  ]
                },
                "line-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  !selectedPlusOneCharge &&
                  selectedVehicle.id === 1
                    ? 1
                    : 0.0,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#003478",
                  0.1,
                  "royalblue",
                  0.3,
                  "#003478",
                  0.5,
                  "royalblue",
                  0.7,
                  "#003478",
                  1,
                  "#003478"
                ]
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round"
              }}
            />
          </Source>

          {/* At home charging (standard 120v) */}
          <Source id="poly30" type="geojson" data="/data/poly30.json">
            <Layer
              id="30"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": "#003478",

                "fill-opacity":
                  selectedHomeChargingOption &&
                  selectedHomeChargingOption.id === 1
                    ? 0.15
                    : 0.0
              }}
            />
          </Source>
          <Source id="polygon30" lineMetrics type="geojson" data={poly30Stroke}>
            <Layer
              id="poly30StrokeLayer"
              type="line"
              paint={{
                "line-width": {
                  type: "exponential",
                  base: 2,
                  stops: [
                    [0, 1 * Math.pow(1.8, 0 - 3)],
                    [6.5, 1 * Math.pow(1.8, 6.5 - 3)]
                  ]
                },
                "line-opacity":
                  selectedHomeChargingOption &&
                  selectedHomeChargingOption.id === 1
                    ? 1
                    : 0.0,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#003478",
                  0.1,
                  "royalblue",
                  0.3,
                  "#003478",
                  0.5,
                  "royalblue",
                  0.7,
                  "#003478",
                  1,
                  "#003478"
                ]
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round"
              }}
            />
          </Source>

          {/* At home charging (240v) */}
          <Source id="poly220" type="geojson" data={homeBaseRange}>
            <Layer
              id="220"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": "#003478",

                "fill-opacity":
                  selectedHomeChargingOption &&
                  selectedHomeChargingOption.id === 2
                    ? 0.15
                    : 0.0
              }}
            />
          </Source>
          <Source
            id="polygon230"
            lineMetrics
            type="geojson"
            data={homeBaseRangeStroke}
          >
            <Layer
              id="poly230StrokeLayer"
              type="line"
              paint={{
                "line-width": {
                  type: "exponential",
                  base: 2,
                  stops: [
                    [0, 1 * Math.pow(1.8, 0 - 3)],
                    [6.5, 1 * Math.pow(1.8, 6.5 - 3)]
                  ]
                },
                "line-opacity":
                  selectedHomeChargingOption &&
                  selectedHomeChargingOption.id === 2
                    ? 1
                    : 0.0,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#003478",
                  0.1,
                  "royalblue",
                  0.3,
                  "#003478",
                  0.5,
                  "royalblue",
                  0.7,
                  "#003478",
                  1,
                  "#003478"
                ]
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round"
              }}
            />
          </Source>

          {/* At home charging (standard 48amp) */}
          <Source
            id="poly320"
            type="geojson"
            data="/data/homeBaseRangeExtended.json"
          >
            <Layer
              id="320"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": "#003478",

                "fill-opacity":
                  selectedHomeChargingOption &&
                  selectedHomeChargingOption.id === 3
                    ? 0.15
                    : 0.0
              }}
            />
          </Source>
          <Source
            id="polygon320"
            lineMetrics
            type="geojson"
            data={homeBaseRangeExtendedStroke}
          >
            <Layer
              id="poly320StrokeLayer"
              type="line"
              paint={{
                "line-width": {
                  type: "exponential",
                  base: 2,
                  stops: [
                    [0, 1 * Math.pow(1.8, 0 - 3)],
                    [6.5, 1 * Math.pow(1.8, 6.5 - 3)]
                  ]
                },
                "line-opacity":
                  selectedHomeChargingOption &&
                  selectedHomeChargingOption.id === 3
                    ? 1
                    : 0.0,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#003478",
                  0.1,
                  "royalblue",
                  0.3,
                  "#003478",
                  0.5,
                  "royalblue",
                  0.7,
                  "#003478",
                  1,
                  "#003478"
                ]
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round"
              }}
            />
          </Source>

          {/* At home charging (standard 80amp) */}
          <Source
            id="320b"
            type="geojson"
            data="/data/homeBaseRangeExtended.json"
          >
            <Layer
              id="320b"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": "#003478",

                "fill-opacity":
                  selectedHomeChargingOption &&
                  selectedHomeChargingOption.id === 4
                    ? 0.15
                    : 0.0
              }}
            />
          </Source>
          <Source
            id="polygon320b"
            lineMetrics
            type="geojson"
            data={homeBaseRangeExtendedStroke}
          >
            <Layer
              id="poly320bStrokeLayer"
              type="line"
              paint={{
                "line-width": {
                  type: "exponential",
                  base: 2,
                  stops: [
                    [0, 1 * Math.pow(1.8, 0 - 3)],
                    [6.5, 1 * Math.pow(1.8, 6.5 - 3)]
                  ]
                },
                "line-opacity":
                  selectedHomeChargingOption &&
                  selectedHomeChargingOption.id === 4
                    ? 1
                    : 0.0,
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#003478",
                  0.1,
                  "royalblue",
                  0.3,
                  "#003478",
                  0.5,
                  "royalblue",
                  0.7,
                  "#003478",
                  1,
                  "#003478"
                ]
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round"
              }}
            />
          </Source>

          {/* Plus one charge */}
          <Source id="polyCharge" type="geojson" data="/data/polyCharge.json">
            <Layer
              id="polyCharge"
              type="fill"
              paint={{
                "fill-opacity-transition": {
                  duration: 250,
                  delay: 0
                },
                "fill-color": PRIMARY_BRAND_COLOR,

                "fill-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  selectedPlusOneCharge &&
                  !chargingPanelVisible
                    ? 0.125
                    : 0.0
              }}
            />
          </Source>
          {/* Plus one charge stroke */}
          <Source
            id="plusone"
            lineMetrics
            type="geojson"
            data={polyChargeStroke}
          >
            <Layer
              id="plusoneStrokeLayer"
              type="line"
              paint={{
                "line-width": {
                  type: "exponential",
                  base: 2.5,
                  stops: [
                    [0, 1 * Math.pow(2, 0 - 3)],
                    [6.5, 1 * Math.pow(2, 6.5 - 3)]
                  ]
                },
                "line-color": PRIMARY_BRAND_COLOR,
                "line-opacity":
                  locationSelected &&
                  rangePanelVisible &&
                  selectedPlusOneCharge &&
                  !chargingPanelVisible
                    ? 1
                    : 0.0
              }}
              layout={{
                "line-cap": "round",
                "line-join": "round"
              }}
            />
          </Source>

          {!homeMapMarkerHidden && (
            <Marker
              longitude={HOME_LAT_LON[0]}
              latitude={HOME_LAT_LON[1]}
              anchor="bottom"
            >
              <Home size={28} fill="#FFF" stroke="#003478" />
            </Marker>
          )}



          {selectedChargeMode.id === 2 &&
            filteredChargingLocations
              .filter((l) => l.active)
              .map((m: any, index) => {
                return (
                  <Marker
                    key={m.properties.id}
                    longitude={m.geometry.coordinates[0]}
                    latitude={m.geometry.coordinates[1]}
                    anchor="center"
                    onClick={() => {
                      setPopupOpen(m.properties.id);
                    }}
                  >
                    {popupOpen === m.properties.id && (
                      <PopUp
                        marker={m}
                        onClose={() => {
                          setPopupOpen(false);
                        }}
                        index={index}
                        open={popupOpen}
                      />
                    )}
                    <MapPin
                      className={`${
                        chargingPanelVisible
                          ? "opacity-100 scale-100"
                          : "opacity-0 scale-75"
                      } transition-all duration-200 ease-in`}
                      size={28}
                      fill={
                        m.properties.ev_pricing === "Free"
                          ? "#22C55D"
                          : m.properties.ev_network === "ChargePoint Network"
                          ? "#003478"
                          : "#FFF"
                      }
                      strokeWidth={1.5}
                      fillOpacity={0.9}
                      stroke={
                        m.properties.id === 1 ||
                        m.properties.id === 2 ||
                        m.properties.id === 3
                          ? "#0064E7" // Highlight manually added charging stations for demo
                          : m.properties.ev_network === "ChargePoint Network"
                          ? "#FFF"
                          : m.properties.ev_pricing === "Free"
                          ? "#166534"
                          : "#aaa"
                      }
                    />
                  </Marker>
                );
              })}
        </Map>
      </div>
    </>
  );
}

export default MapTiles;
