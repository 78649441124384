import Select from "react-select";
import CountUp from "react-countup";
import { model, vehicle } from "../interfaces/Vehicle";
import { useState } from "react";
import { PRIMARY_BRAND_COLOR } from "../data/Constants";

interface ModelPickerProps {
  vehicle: vehicle;
  selectedModel: model;
  extendedRangeEnabled: boolean;
  onSelect: (id: number) => void;
}

function ModelPicker(props: ModelPickerProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const options = props.vehicle.models.map((model) => ({
    value: model.id,
    label: model.name,
    range: props.extendedRangeEnabled
      ? model.extendedRange || model.standardRange
      : model.standardRange
  }));

  const formatOptionLabel = ({ value, label, range }) => (
    <div className="justify-between cursor-pointer py-2 flex items-center w-full">
      <div>{label}</div>
      <div className="text-gray-400">
        {!isMenuOpen && (
          <>
            <CountUp preserveValue end={range} duration={0.25} /> miles
          </>
        )}
        {isMenuOpen && <>{range} miles</>}
      </div>
    </div>
  );
  const styles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? PRIMARY_BRAND_COLOR : "white",
      "&:hover": {
        backgroundColor: state.isSelected ? PRIMARY_BRAND_COLOR : "#F2F2F2"
      }
    }),
    container: (provided, state) => ({
      ...provided
    }),
    control: (provided, state) => ({
      ...provided,
      border: "none"
    })
  };
  return (
    <>
      <Select
        options={options}
        isSearchable={false}
        onMenuOpen={() => {
          setIsMenuOpen(true);
        }}
        onMenuClose={() => {
          setIsMenuOpen(false);
        }}
        styles={styles}
        defaultValue={{
          label: props.selectedModel.name,
          value: props.selectedModel.id,
          range: props.extendedRangeEnabled
            ? props.selectedModel.extendedRange
            : props.selectedModel.standardRange
        }}
        className="animate-fadeInSlow border-primary border-2"
        placeholder="Select a model"
        onChange={(e) => {
          props.onSelect(e.value);
        }}
        value={options.find((o) => o.value === props.selectedModel.id)}
        formatOptionLabel={formatOptionLabel}
      />
    </>
  );
}

export default ModelPicker;
