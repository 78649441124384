import { towable } from "../interfaces/Vehicle";

export const towables:towable[] = [
  {
    id: 1,
    name: "ATV or motorbike",
    dragCoeffecient: 0.2,
    iconPath: "images/icons/icon-small-towable.svg"
  },
  {
    id: 2,
    name: "Camper",
    dragCoeffecient: 0.7,
    iconPath: "images/icons/icon-full-trailer.svg"
  },
  {
    id: 3,
    name: "Boat",
    dragCoeffecient: 0.5,
    iconPath: "images/icons/icon-boat.svg"
  },

  ]