import Tippy from "@tippyjs/react";
import { towables } from "../data/Towables";
import { towable } from "../interfaces/Vehicle";

interface TowablePickerProps {
  selectedTowable: towable;
  onSelect: (towable: any) => void;
}

function TowablePicker(props: TowablePickerProps) {
  function handleSelected(towable: towable) {
    props.onSelect(towable);
  }
  return (
    <div className="animate-fadeInSlow mt-3">
      <h3 className="mb-2 text-xl">Payload</h3>
      <div className="grid grid-cols-3  gap-2 items-start cursor-pointer justify-between">
        {towables.map((t: towable) => {
          return (
            <Tippy
              key={t.id}
              placement="bottom"
              content={t.name}
              className="bg-white text-primary"
            >
              <div
                onClick={() => {
                  handleSelected(t);
                }}
                className={`${
                  props.selectedTowable && props.selectedTowable.id === t.id
                    ? "border-primary border-2"
                    : "border-slate-200 border"
                } bg-white flex items-center justify-center h-full shadow-lg p-3 rounded-sm hover:shadow-xl transition-shadow duration-200 ease-in cursor-pointer`}
              >
                <img className="" alt={t.name} src={t.iconPath} />
              </div>
            </Tippy>
          );
        })}
      </div>
    </div>
  );
}

export default TowablePicker;
