import * as React from "react";
import { SVGProps } from "react";

const IconFastCharging = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={63}
    fill="none"
    {...props}
  >
    <mask id="a" fill="#fff">
      <rect width={41} height={63} rx={2} />
    </mask>
    <rect
      width={41}
      height={63}
      rx={2}
      stroke="#003478"
      strokeWidth={5}
      mask="url(#a)"
    />
    <path
      d="M15.5 28v7h-3v-7h3ZM28.5 28v7h-3v-7h3ZM21.5 34v6h-2v-6h2ZM20.7 27c1.753 0 2.8-1.329 2.8-3.555V23h-5.6v.444c0 2.227 1.047 3.556 2.8 3.556Z"
      fill="#003478"
    />
    <circle cx={20.5} cy={31} r={13} stroke="#003478" strokeWidth={2} />
  </svg>
);

export default IconFastCharging;
