import { useState } from "react";
import { CloudSnow } from "react-feather";
import { Range } from "react-range";
interface OutdoorTemperatureSliderProps {}

function OutdoorTemperatureSlider(props: OutdoorTemperatureSliderProps) {
  const initialOutdoorTemp = 60;
  const outdoorStep = 20;

  const [outdoorTemperature, setOutdoorTemperature] =
    useState<number>(initialOutdoorTemp);

  return (
    <>
      <div className="flex items-center mt-8 mb-6 animate-fadeInSlow">
        <h3 className="text-xl mr-2">
          Outside temperature{" "}
          <span className="opacity-50 ml-1 text-sm">°F</span>
        </h3>
      </div>
      <>
        <Range
          step={outdoorStep}
          min={0}
          max={100}
          values={[outdoorTemperature]}
          onChange={(values) => setOutdoorTemperature(values[0])}
          renderMark={({ props, index }) => (
            <div
              {...props}
              className={`${
                index * outdoorStep < outdoorTemperature
                  ? "bg-slate-300"
                  : "bg-slate-200"
              } ${index === 0 ? "hidden" : ""} ${index === 5 ? "hidden" : ""}`}
              style={{
                ...props.style,
                height: "16px",
                width: "1px"
              }}
            />
          )}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="bg-white border-slate-200 border shadow-lg cursor-pointer rounded-sm w-full h-4"
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="rounded-full flex items-center justify-center bg-white border-2 h-10 w-10 text-sm font-medium border-primary shadow-lg hover:shadow-xl"
            >
              {outdoorTemperature}°
            </div>
          )}
        />
        <div className="text-blue-300 text-sm mt-5">
          <div
            className={`${
              outdoorTemperature < 40 ? "opacity-100" : "opacity-0"
            } transition-opacity duration-150 ease-in flex items-center justify-center`}
          >
            <CloudSnow className="mr-2" />
            Cooler outside temps may affect range
          </div>
        </div>
      </>
    </>
  );
}

export default OutdoorTemperatureSlider;
