import * as turf from "@turf/turf";

const line = turf.lineString([
  [-84.23904418945312, 45.64140785643534],
  [-84.27886962890625, 45.65196831328892],
  [-84.31594848632814, 45.66348654108301],
  [-84.3695068359375, 45.66156700098541],
  [-84.39834594726562, 45.665406015366976],
  [-84.42718505859378, 45.674042835203494],
  [-84.43817138671876, 45.66636572782886],
  [-84.42443847656251, 45.658687567438136],
  [-84.43679809570312, 45.64908838613599],
  [-84.48348999023438, 45.66444628645168],
  [-84.50958251953128, 45.68267832232488],
  [-84.58511352539062, 45.71816576784977],
  [-84.63592529296876, 45.73638029782995],
  [-84.70321655273439, 45.761295819489334],
  [-84.72381591796878, 45.77087575105817],
  [-84.73068237304688, 45.787157859029],
  [-84.76776123046876, 45.79003067864973],
  [-84.78836059570312, 45.7842848913437],
  [-84.78561401367188, 45.76895989635986],
  [-84.78424072265626, 45.757463386206155],
  [-84.80484008789064, 45.75171424271875],
  [-84.83779907226564, 45.75171424271875],
  [-84.87762451171876, 45.756505236755174],
  [-84.89822387695314, 45.75075599455501],
  [-84.92843627929688, 45.760337735846605],
  [-84.9888610839844, 45.763211937418774],
  [-85.045166015625, 45.76991783193495],
  [-85.06851196289062, 45.7660859909231],
  [-85.00808715820311, 45.757463386206126],
  [-84.96963500976562, 45.74883944887107],
  [-84.9462890625, 45.728711745959636],
  [-84.95315551757811, 45.70569977255854],
  [-84.99298095703125, 45.683637738627965],
  [-85.01358032226564, 45.66156700098541],
  [-85.07125854492188, 45.638527386311864],
  [-85.10009765625, 45.60491095971412],
  [-85.12344360351562, 45.571274376773175],
  [-85.09597778320312, 45.48950161335787],
  [-85.05615234375001, 45.448088930449615],
  [-84.99710083007814, 45.427853073556236],
  [-84.9407958984375, 45.4317080819897],
  [-84.9188232421875, 45.42399780182689],
  [-84.91607666015624, 45.39796783078109],
  [-84.96963500976562, 45.37964325238118],
  [-85.02456665039062, 45.37192586313687],
  [-85.04379272460939, 45.36710196002505],
  [-85.14129638671875, 45.37289059438987],
  [-85.19210815429689, 45.36420742068031],
  [-85.21545410156251, 45.36324254131908],
  [-85.2374267578125, 45.342976273269684],
  [-85.25802612304688, 45.32849592422861],
  [-85.30334472656249, 45.323668318378665],
  [-85.37200927734376, 45.2753696316852],
  [-85.38917541503905, 45.24298649437897],
  [-85.39054870605469, 45.21880793965786],
  [-85.38917541503905, 45.19558685020612],
  [-85.38230895996094, 45.1776807905905],
  [-85.38162231445311, 45.15008475740561],
  [-85.36720275878906, 45.117145687750295],
  [-85.36994934082031, 45.09048987614683],
  [-85.36514282226562, 45.072550781936066],
  [-85.37750244140625, 45.0555761842945],
  [-85.38093566894531, 45.017243565102596],
  [-85.37406921386719, 45.006564087332585],
  [-85.38299560546875, 44.98811302615805],
  [-85.38368225097656, 44.96917023288548],
  [-85.39810180664065, 44.92640452829737],
  [-85.42076110839844, 44.89479576469787],
  [-85.43174743652344, 44.870469502172696],
  [-85.45166015625, 44.84759343658979],
  [-85.462646484375, 44.83152608719319],
  [-85.46401977539062, 44.82227315654819],
  [-85.47981262207031, 44.81204450516513],
  [-85.49972534179688, 44.80376283939001],
  [-85.51071166992188, 44.78524658109537],
  [-85.50453186035158, 44.78134766441459],
  [-85.506591796875, 44.767211884106985],
  [-85.52444458007812, 44.75356026127114],
  [-85.53337097167969, 44.74527020225553],
  [-85.55122375488283, 44.74819624120938],
  [-85.56358337402344, 44.75307264365524],
  [-85.57525634765625, 44.75989891587616],
  [-85.56015014648438, 44.78622126912216],
  [-85.55946350097656, 44.79694175122944],
  [-85.55946350097656, 44.809121700077355],
  [-85.54092407226562, 44.83396082207883],
  [-85.51002502441406, 44.848567071337264],
  [-85.49903869628906, 44.85635555684335],
  [-85.49835205078125, 44.87144275016589],
  [-85.48942565917969, 44.8972278252169],
  [-85.49079895019531, 44.92543221016552],
  [-85.48530578613281, 44.95799590837475],
  [-85.46333312988281, 44.960425294505754],
  [-85.4681396484375, 44.97305644426211],
  [-85.47431945800781, 44.991026746594535],
  [-85.49148559570312, 44.990055522906864],
  [-85.49766540527344, 44.98277082065612],
  [-85.51895141601565, 44.97305644426214],
  [-85.52101135253906, 44.95508050924061],
  [-85.5278778076172, 44.94584743480024],
  [-85.52993774414062, 44.93515465088411],
  [-85.54023742675781, 44.9147356245024],
  [-85.56221008300781, 44.89528218503055],
  [-85.55877685546876, 44.886525989535045],
  [-85.54916381835938, 44.89479576469787],
  [-85.54710388183594, 44.90160527494205],
  [-85.5340576171875, 44.90063253713748],
  [-85.52719116210939, 44.8972278252169],
  [-85.52993774414062, 44.88506649401471],
  [-85.53131103515625, 44.87290259129754],
  [-85.54985046386719, 44.861222825412064],
  [-85.56427001953125, 44.83201304239909],
  [-85.57662963867188, 44.8188638076915],
  [-85.58212280273439, 44.80327564555043],
  [-85.59104919433594, 44.78427187661121],
  [-85.594482421875, 44.77306159217168],
  [-85.594482421875, 44.76818687659432],
  [-85.60134887695312, 44.76672438169179],
  [-85.62812805175781, 44.766724381691816],
  [-85.63980102539062, 44.77354904110063],
  [-85.63362121582031, 44.79645449978889],
  [-85.64872741699219, 44.829091249447906],
  [-85.6494140625, 44.861709529639704],
  [-85.6439208984375, 44.885552996635894],
  [-85.62812805175781, 44.909872881799686],
  [-85.62400817871094, 44.921542773060374],
  [-85.60203552246094, 44.92397370210939],
  [-85.60409545898438, 44.95362275412842],
  [-85.60340881347656, 44.97208491610468],
  [-85.5999755859375, 44.991026746594535],
  [-85.60752868652342, 44.98956990489135],
  [-85.62675476074219, 44.97062759301016],
  [-85.6329345703125, 44.96722702845027],
  [-85.64117431640625, 44.9681986388968],
  [-85.65010070800781, 44.97597092998747],
  [-85.64048767089844, 44.987627391684704],
  [-85.63156127929688, 45.00025255079322],
  [-85.6109619140625, 45.01238950389271],
  [-85.60066223144531, 45.02161186840545],
  [-85.59585571289062, 45.04296322043135],
  [-85.58761596679688, 45.0555761842945],
  [-85.56838989257811, 45.04102251758532],
  [-85.56564331054688, 45.05606124274415],
  [-85.57044982910156, 45.06673148768116],
  [-85.58212280273438, 45.06818636678991],
  [-85.59173583984374, 45.082248289072425],
  [-85.59791564941406, 45.09291364605112],
  [-85.59516906738281, 45.10260769705975],
  [-85.60478210449219, 45.106484856646844],
  [-85.61370849609375, 45.12053735276011],
  [-85.61370849609375, 45.12974228438219],
  [-85.60684204101562, 45.13555516012536],
  [-85.60066223144531, 45.14960049715516],
  [-85.59104919433594, 45.15153751347067],
  [-85.58349609375, 45.15395869129108],
  [-85.57388305664062, 45.15541134861056],
  [-85.56838989257811, 45.15444291451196],
  [-85.56289672851562, 45.13361760070823],
  [-85.56015014648438, 45.144273362849326],
  [-85.56015014648438, 45.15928492042905],
  [-85.550537109375, 45.167515386234996],
  [-85.53817749023439, 45.1747765744932],
  [-85.52372094418388, 45.1907479300741],
  [-85.52475091244564, 45.19219964931452],
  [-85.52578088070733, 45.19389327496077],
  [-85.52784081723075, 45.19655458486019],
  [-85.52955743100028, 45.19921577030018],
  [-85.53299065853938, 45.20187683128089],
  [-85.53367730404717, 45.20477966495096],
  [-85.53436394955499, 45.20719857986464],
  [-85.53436394955499, 45.21010114198752],
  [-85.53779717709406, 45.2110686297804],
  [-85.54088708187922, 45.21251983061208],
  [-85.5470668914495, 45.2110686297804],
  [-85.54878350521906, 45.20816611702967],
  [-85.55050011898857, 45.209375515342714],
  [-85.55324670101984, 45.207440465698795],
  [-85.55221673275811, 45.20357016892206],
  [-85.5573665740667, 45.20163492178921],
  [-85.55942651059014, 45.20187683128089],
  [-85.55942651059014, 45.199699608826734],
  [-85.55942651059014, 45.1963126527396],
  [-85.55976983334405, 45.19365133152565],
  [-85.56560632016046, 45.19123184060192],
  [-85.57006951596124, 45.18857028178354],
  [-85.5724727752386, 45.186634524469866],
  [-85.57590600277766, 45.18373076506821],
  [-85.58208581234794, 45.18058485857016],
  [-85.58792229916438, 45.17889083691571],
  [-85.59444543148857, 45.178164812206376],
  [-85.59719201351982, 45.179374848245644],
  [-85.6019985320745, 45.18010085752614],
  [-85.6064617278753, 45.18131085242166],
  [-85.60852166439874, 45.18300480207448],
  [-85.60886498715264, 45.18542464271958],
  [-85.61058160092219, 45.18711846996982],
  [-85.61435815121514, 45.18736044117686],
  [-85.61504479672297, 45.18373076506821],
  [-85.62122460629325, 45.17961685236774],
  [-85.62637444760188, 45.17501859815858],
  [-85.62637444760188, 45.17259831521807],
  [-85.63118096615653, 45.16727333068313],
  [-85.63633080746513, 45.1629161548598],
  [-85.64251061703547, 45.156621868090305],
  [-85.64766045834405, 45.15032688598794],
  [-85.65212365414482, 45.144515516118084],
  [-85.6559002044378, 45.137492653711895],
  [-85.66036340023857, 45.13095334902066],
  [-85.66482659603939, 45.12368657546327],
  [-85.67031976010188, 45.118841545456036],
  [-85.67134972836358, 45.115449779642915],
  [-85.67272301937923, 45.109150251114784],
  [-85.67272301937923, 45.10551559143663],
  [-85.67409631039483, 45.1016383660187],
  [-85.67615624691827, 45.098003228038024],
  [-85.67684289242608, 45.09364075696457],
  [-85.67924615170342, 45.090247493499064],
  [-85.68336602475028, 45.08685402842617],
  [-85.68885918881281, 45.084430001369526],
  [-85.69091912533622, 45.078611916760764],
  [-85.69744225766046, 45.0727932396724],
  [-85.706368649262, 45.06697397010412],
  [-85.71907159115653, 45.06236662817483],
  [-85.73040124203544, 45.059214022377176],
  [-85.73829766537528, 45.05484858890543],
  [-85.74207421566825, 45.052908289275614],
  [-85.7482540252386, 45.043690967026514],
  [-85.75203057553155, 45.03544263020227],
  [-85.75546380307063, 45.029619558882025],
  [-85.75821038510185, 45.02476654685843],
  [-85.76061364437919, 45.02161186840545],
  [-85.76645013119564, 45.017243565102575],
  [-85.7709133269964, 45.01481658592836],
  [-85.77365990902763, 45.01263221672503],
  [-85.77468987728938, 45.00850595868393],
  [-85.77365990902763, 45.00510764061731],
  [-85.77262994076592, 45.0002525507932],
  [-85.77297326351982, 44.99151235226668],
  [-85.77400323178158, 44.98762739168473],
  [-85.77400323178158, 44.98325649627403],
  [-85.77503320004328, 44.97961382884983],
  [-85.78052636410577, 44.972570682240615],
  [-85.78498955990653, 44.96941312881114],
  [-85.78670617367607, 44.9664983098149],
  [-85.79048272396906, 44.96358334269835],
  [-85.79288598324638, 44.958967675170506],
  [-85.7987224700628, 44.95410867661361],
  [-85.80215569760188, 44.9516790230432],
  [-85.80730553891044, 44.94876330298185],
  [-85.81314202572689, 44.94633342311663],
  [-85.81829186703544, 44.94438944516438],
  [-85.82378503109794, 44.943174425514286],
  [-85.82859154965267, 44.941959380148965],
  [-85.83820458676202, 44.940258273435674],
  [-85.8436977508245, 44.939286189827236],
  [-85.85022088314874, 44.939286189827236],
  [-85.85502740170341, 44.939286189827236],
  [-85.86120721127374, 44.939286189827236],
  [-85.86670037533622, 44.940015254076535],
  [-85.87013360287528, 44.94123034058637],
  [-85.87425347592217, 44.94293141849847],
  [-85.87905999447686, 44.946090429472754],
  [-85.8814632537542, 44.949006285310965],
  [-85.88489648129327, 44.952407929914756],
  [-85.88626977230888, 44.95508050924061],
  [-85.88867303158625, 44.95872473501453],
  [-85.8917629363714, 44.960425294505754],
  [-85.89485284115655, 44.9623687293504],
  [-85.89965935971125, 44.96504084477169],
  [-85.9037792327581, 44.96625540154595],
  [-85.9085857513128, 44.96771283573073],
  [-85.91442223812923, 44.96917023288548],
  [-85.91922875668388, 44.96941312881114],
  [-85.92575188900811, 44.96941312881114],
  [-85.93055840756281, 44.96771283573073],
  [-85.93467828060969, 44.96576958192206],
  [-85.9367382171331, 44.96261165407722],
  [-85.93742486264091, 44.95726707247752],
  [-85.93982812191827, 44.95289386268606],
  [-85.9415447356878, 44.94924926661156],
  [-85.94429131771905, 44.94633342311663],
  [-85.9477245452581, 44.941473354802504],
  [-85.95184441830499, 44.939043166353606],
  [-85.95665093685966, 44.93661287504356],
  [-85.96008416439872, 44.933453342562984],
  [-85.96351739193778, 44.9295644485644],
  [-85.96523400570733, 44.92664760525884],
  [-85.96592065121516, 44.923487524528454],
  [-85.96729394223077, 44.91911174115028],
  [-85.96832391049247, 44.9147356245024],
  [-85.97072716976983, 44.91206116693206],
  [-85.97278710629327, 44.90890028388591],
  [-85.97725030209406, 44.90549606158295],
  [-85.97965356137138, 44.903550701177956],
  [-85.98411675717216, 44.90160527494202],
  [-85.98960992123466, 44.900146162063585],
  [-85.99510308529716, 44.89965978287517],
  [-85.99956628109794, 44.89965978287517],
  [-86.0026561858831, 44.89965978287517],
  [-86.00540276791435, 44.90063253713748],
  [-86.01020928646903, 44.90111890809696],
  [-86.01295586850028, 44.902577996288876],
  [-86.01810570980888, 44.90379387482871],
  [-86.02188226010186, 44.90476655914563],
  [-86.02531548764092, 44.90695503868511],
  [-86.0290920379339, 44.908657131835966],
  [-86.03115197445732, 44.91060231943465],
  [-86.03355523373466, 44.91303371135819],
  [-86.03698846127375, 44.914249368747086],
  [-86.0414516570745, 44.91449249713907],
  [-86.04625817562919, 44.91279057679455],
  [-86.05175133969169, 44.9123043045815],
  [-86.05690118100028, 44.90962973386418],
  [-86.06239434506283, 44.90695503868511],
  [-86.06411095883233, 44.903550701177956],
  [-86.06651421810967, 44.89795742331477],
  [-86.0672008636175, 44.894066126484326],
  [-86.0672008636175, 44.888715163384624],
  [-86.0672008636175, 44.88336370243937],
  [-86.0672008636175, 44.877525177254235],
  [-86.06617089535577, 44.869739555377535],
  [-86.06514092709403, 44.863899647748184],
  [-86.06582757260185, 44.857085673357986],
  [-86.06411095883233, 44.85148787683413],
  [-86.06376763607842, 44.846619785384696],
  [-86.06239434506283, 44.83858653498305],
  [-86.06170769955497, 44.83201304239911],
  [-86.06239434506283, 44.825682303800384],
  [-86.06239434506283, 44.821055555099875],
  [-86.06445428158624, 44.81204450516511],
  [-86.06685754086358, 44.80595516075284],
  [-86.07097741391047, 44.79986517347136],
  [-86.07406731869561, 44.79523635318714],
  [-86.07406731869561, 44.793530904744074],
  [-86.07200738217217, 44.78646493855739],
  [-86.06994744564872, 44.77939810733008],
  [-86.06994744564872, 44.773792764022204],
  [-86.07063409115656, 44.7691618526244],
  [-86.07063409115656, 44.76501809083848],
  [-86.07166405941827, 44.76111780820081],
  [-86.07372399594169, 44.75648588056624],
  [-86.07887383725031, 44.752097396080465],
  [-86.08024712826591, 44.74844007110308],
  [-86.08333703305107, 44.7442948230232],
  [-86.0850536468206, 44.74161244527702],
  [-86.08951684262139, 44.7379544568698],
  [-86.0956966521917, 44.73454012555644],
  [-86.10290643002374, 44.73112559264325],
  [-86.10977288510188, 44.72966215968059],
  [-86.11698266293392, 44.72893042931362],
  [-86.12281914975031, 44.72893042931362],
  [-86.12899895932061, 44.72917434046452],
  [-86.13277550961361, 44.72917434046452],
  [-86.13964196469169, 44.73112559264325],
  [-86.14341851498466, 44.73161339540224],
  [-86.14650841976983, 44.73234509182648],
  [-86.14994164730889, 44.732588988577454],
  [-86.15303155209406, 44.73210119404702],
  [-86.15646477963313, 44.728198689689506],
  [-86.15955468441827, 44.72551556520138],
  [-86.16264458920342, 44.72356412376624],
  [-86.16710778500419, 44.722344439440825],
  [-86.17260094906672, 44.72063683818543],
  [-86.17912408139091, 44.71868523217971],
  [-86.18530389096124, 44.716001666436846],
  [-86.19148370053155, 44.714293877982776],
  [-86.19594689633234, 44.71380592916732],
  [-86.20315667416438, 44.71258603912906],
  [-86.20933648373467, 44.71063416158254],
  [-86.21414300228936, 44.70965819812381],
  [-86.22409936215266, 44.70721821747761],
  [-86.23062249447688, 44.70477813397545],
  [-86.23577233578546, 44.703558053653275],
  [-86.24092217709403, 44.701117815867164],
  [-86.24572869564874, 44.69867747522525],
  [-86.24813195492608, 44.69501677140782],
  [-86.25122185971124, 44.6915999057147],
  [-86.25293847348075, 44.687206496456994],
  [-86.25293847348075, 44.68330096401703],
  [-86.25293847348075, 44.678662802251225],
  [-86.25225182797294, 44.67426841175558],
  [-86.25396844174249, 44.66938536480116],
  [-86.2543117644964, 44.66621116365773],
  [-86.2543117644964, 44.66279259880061],
  [-86.25293847348075, 44.65937383234911],
  [-86.25156518246514, 44.652535694663875],
  [-86.24916192318778, 44.648383574795965],
  [-86.24744530941828, 44.64374261847816],
  [-86.24572869564874, 44.64154414115327],
  [-86.2443554046331, 44.63812412194278],
  [-86.2426387908636, 44.6344595919402],
  [-86.24126549984794, 44.63177212285837],
  [-86.23954888607844, 44.63201644337207],
  [-86.2388622405706, 44.63348234485533],
  [-86.23645898129325, 44.63323803051274],
  [-86.23027917172296, 44.63323803051274],
  [-86.22547265316825, 44.63177212285837],
  [-86.22306939389094, 44.630550504861766],
  [-86.21860619809017, 44.629084529324516],
  [-86.21860619809017, 44.62639681133873],
  [-86.21757622982841, 44.62199845891657],
  [-86.22032281185967, 44.621021002012085],
  [-86.22272607113702, 44.622487181197634],
  [-86.22547265316825, 44.62566377574355],
  [-86.22856255795341, 44.62688549659285],
  [-86.23233910824639, 44.62810719172907],
  [-86.23577233578546, 44.629573191951046],
  [-86.24092217709403, 44.629573191951046],
  [-86.23954888607844, 44.62761851676016],
  [-86.23645898129325, 44.62395332335629],
  [-86.23439904476984, 44.62077663521467],
  [-86.23199578549249, 44.61735539205565],
  [-86.23062249447688, 44.61393394730626],
  [-86.22856255795341, 44.61148993477701],
  [-86.22718926693781, 44.60806814444481],
  [-86.22444268490655, 44.60317952284558],
  [-86.22272607113702, 44.598534951261264],
  [-86.22032281185967, 44.59046718130883],
  [-86.21963616635186, 44.58557707865175],
  [-86.21826287533622, 44.58068656459201],
  [-86.21723290707452, 44.575795639130696],
  [-86.21723290707452, 44.569925985528336],
  [-86.21894952084406, 44.56527875628479],
  [-86.21894952084406, 44.56014191304743],
  [-86.22066613461357, 44.54815418163019],
  [-86.22272607113702, 44.54374990501309],
  [-86.22444268490655, 44.5400794199491],
  [-86.22581597592216, 44.53469562326322],
  [-86.2275325896917, 44.529800830678624],
  [-86.22993584896905, 44.52637423109218],
  [-86.23062249447688, 44.520989167323734],
  [-86.23130913998466, 44.51584841482664],
  [-86.23233910824639, 44.510462378126554],
  [-86.233712399262, 44.50654494787339],
  [-86.23577233578546, 44.501158051378304],
  [-86.2388622405706, 44.494546179900304],
  [-86.24023553158624, 44.48891325357627],
  [-86.24298211361749, 44.48523931286507],
  [-86.24057885434014, 44.48523931286507],
  [-86.23714562680107, 44.48719877670758],
  [-86.23405572201595, 44.48768863238376],
  [-86.22924920346122, 44.48768863238376],
  [-86.22924920346122, 44.48499437525676],
  [-86.23027917172296, 44.48107523365452],
  [-86.23405572201595, 44.48107523365452],
  [-86.2388622405706, 44.48107523365452],
  [-86.24160882260185, 44.48156514075307],
  [-86.24538537289483, 44.48303483736592],
  [-86.24538537289483, 44.4764209110438],
  [-86.24744530941828, 44.469316229158224],
  [-86.24778863217217, 44.46368086644979],
  [-86.24778863217217, 44.45632957574723],
  [-86.24744530941828, 44.451183121531336],
  [-86.24607201840264, 44.44628131490938],
  [-86.244698727387, 44.440153478144595],
  [-86.2436687591253, 44.434270150140655],
  [-86.2436687591253, 44.43010243440228],
  [-86.24332543637139, 44.42519885890396],
  [-86.2436687591253, 44.42103049629628],
  [-86.244698727387, 44.41490001137302],
  [-86.2436687591253, 44.41146665906269],
  [-86.24744530941828, 44.40459934974494],
  [-86.24813195492608, 44.39969363517526],
  [-86.25156518246514, 44.391843636245156],
  [-86.25259515072685, 44.38939029561889],
  [-86.25396844174249, 44.3844833058524],
  [-86.25568505551202, 44.37785821716272],
  [-86.25774499203547, 44.37442269084738],
  [-86.25808831478938, 44.370741546125],
  [-86.25877496029717, 44.36656930239069],
  [-86.26117821957452, 44.36288766405336],
  [-86.26152154232842, 44.3609240289976],
  [-86.26186486508234, 44.35748750928293],
  [-86.26220818783625, 44.35306883062629],
  [-86.26289483334406, 44.348158796900755],
  [-86.26667138363702, 44.34226621346259],
  [-86.27010461117607, 44.33735527479289],
  [-86.27319451596126, 44.334408514149935],
  [-86.27628442074638, 44.33219834648981],
  [-86.27937432553155, 44.32900573504008],
  [-86.2821209075628, 44.32581294980222],
  [-86.28624078060969, 44.32163750688258],
  [-86.28864403988705, 44.31721612575094],
  [-86.2937938811956, 44.31304007097853],
  [-86.29688378598078, 44.308863719022014],
  [-86.29997369076592, 44.30394998330269],
  [-86.30203362728938, 44.30001869857366],
  [-86.30615350033624, 44.29436701558007],
  [-86.3085567596136, 44.29043508918882],
  [-86.31164666439872, 44.28625712896037],
  [-86.31370660092217, 44.282078871554646],
  [-86.31507989193778, 44.2769170844713],
  [-86.31816979672296, 44.27150901162341],
  [-86.32057305600031, 44.2678214038788],
  [-86.32125970150811, 44.264871151101985],
  [-86.32434960629328, 44.26241249401097],
  [-86.3267528655706, 44.26019961471172],
  [-86.32881280209405, 44.258478428784606],
  [-86.332589352387, 44.25577360624334],
  [-86.3360225799261, 44.25356047707466],
  [-86.33945580746514, 44.25036358790017],
  [-86.34117242123467, 44.24815025513525],
  [-86.34151574398858, 44.24544495753621],
  [-86.34288903500419, 44.24126379833976],
  [-86.34563561703544, 44.236836364702555],
  [-86.34700890805108, 44.23068659863379],
  [-86.34872552182063, 44.226750411085746],
  [-86.35078545834403, 44.22281396030831],
  [-86.35318871762138, 44.21961540022917],
  [-86.35490533139092, 44.2156784723511],
  [-86.35696526791436, 44.21223344452974],
  [-86.35971184994563, 44.20804992552355],
  [-86.36074181820733, 44.205588893180526],
  [-86.36623498226982, 44.19943586247786],
  [-86.37069817807061, 44.19525143452791],
  [-86.37447472836361, 44.192051377359576],
  [-86.376534664887, 44.18885114642453],
  [-86.38031121517999, 44.18663550013902],
  [-86.3816845061956, 44.184665966856564],
  [-86.38374444271905, 44.181711543549135],
  [-86.38580437924249, 44.17752585708042],
  [-86.38790130615236, 44.17284738526843],
  [-86.38824462890628, 44.16890733164],
  [-86.389274597168, 44.16644466445857],
  [-86.39202117919925, 44.16324304341004],
  [-86.39614105224612, 44.15708559147223],
  [-86.39820098876953, 44.1541297862883],
  [-86.40060424804689, 44.15068115978091],
  [-86.40335083007814, 44.14698597918344],
  [-86.40644073486328, 44.142797828180605],
  [-86.40918731689453, 44.13910215406814],
  [-86.41227722167969, 44.13565264951551],
  [-86.41468048095703, 44.131710111843105],
  [-86.41880035400392, 44.12727444235031],
  [-86.42154693603516, 44.12382424688647],
  [-86.4284133911133, 44.11889504665872],
  [-86.43184661865236, 44.11495139037567],
  [-86.43596649169925, 44.11150047522202],
  [-86.44248962402345, 44.10730980734024],
  [-86.44832611083984, 44.10311884233329],
  [-86.45416259765628, 44.09966723628639],
  [-86.45999908447267, 44.09572229691591],
  [-86.46686553955078, 44.092763419666824],
  [-86.47441864013672, 44.08733842686839],
  [-86.4826583862305, 44.08487235655599],
  [-86.4891815185547, 44.08067980107849],
  [-86.4943313598633, 44.076486948484565],
  [-86.50085449218754, 44.07377376789342],
  [-86.50566101074219, 44.067853669357596],
  [-86.50943756103518, 44.06242639216298],
  [-86.5114974975586, 44.058478968944556],
  [-86.51115417480469, 44.05206384489496],
  [-86.51115417480469, 44.047128661183734],
  [-86.50943756103518, 44.04095910326954],
  [-86.50634765625003, 44.03478890283157],
  [-86.50222778320314, 44.027877515542656],
  [-86.4950180053711, 44.01874337471548],
  [-86.48780822753906, 44.0110893623901],
  [-86.4833450317383, 44.006644643819634],
  [-86.48128509521486, 44.00343436215526],
  [-86.47613525390625, 43.99701327763528],
  [-86.47304534912111, 43.992320507176466],
  [-86.46961212158207, 43.987133329129215],
  [-86.46652221679688, 43.98219273809204],
  [-86.46308898925783, 43.97774585459446],
  [-86.46102905273438, 43.974039863926414],
  [-86.45965576171876, 43.96959236982231],
  [-86.45793914794923, 43.962673383661915],
  [-86.45793914794923, 43.95847217744844],
  [-86.45759582519533, 43.95575359159654],
  [-86.45519256591798, 43.95377636012689],
  [-86.45347595214844, 43.95377636012689],
  [-86.45072937011722, 43.95476498408514],
  [-86.44832611083984, 43.95476498408514],
  [-86.44729614257814, 43.95179906286985],
  [-86.44729614257814, 43.949821699825556],
  [-86.44420623779298, 43.94809145319581],
  [-86.4397430419922, 43.94809145319581],
  [-86.4387130737305, 43.94636115619796],
  [-86.4387130737305, 43.943642016294554],
  [-86.43733978271484, 43.94018111289607],
  [-86.43459320068361, 43.939933897801126],
  [-86.43184661865236, 43.9394394645275],
  [-86.42910003662111, 43.93869780690774],
  [-86.42566680908203, 43.93721446391471],
  [-86.42120361328126, 43.93597831648643],
  [-86.4181137084961, 43.93424766691483],
  [-86.41777038574219, 43.930786216670064],
  [-86.41845703125001, 43.929055415997105],
  [-86.42154693603516, 43.929549935614595],
  [-86.42601013183594, 43.93004445112047],
  [-86.4297866821289, 43.93004445112047],
  [-86.43287658691406, 43.93004445112047],
  [-86.43768310546875, 43.93177522301229],
  [-86.44008636474611, 43.93226972001601],
  [-86.44386291503909, 43.935483850319756],
  [-86.44420623779298, 43.937461690316646],
  [-86.44626617431645, 43.9384505856453],
  [-86.44866943359375, 43.94166438187961],
  [-86.45004272460939, 43.94537239244211],
  [-86.45141601562503, 43.949821699825556],
  [-86.45450592041018, 43.951551896087],
  [-86.4569091796875, 43.94957452481932],
  [-86.4558792114258, 43.94611396680085],
  [-86.4510726928711, 43.94092275201342],
  [-86.44901275634766, 43.93647277854148],
  [-86.44729614257814, 43.93276421290811],
  [-86.44626617431645, 43.927819098965294],
  [-86.4455795288086, 43.923862711777474],
  [-86.44283294677737, 43.912981290202],
  [-86.44283294677737, 43.909271259829346],
  [-86.44317626953125, 43.903087361940976],
  [-86.44386291503909, 43.8993767148495],
  [-86.44420623779298, 43.89591323557617],
  [-86.44180297851564, 43.895665836490586],
  [-86.44180297851564, 43.89343919846738],
  [-86.44420623779298, 43.89195472686543],
  [-86.44145965576172, 43.88725365601314],
  [-86.4390563964844, 43.88106747115985],
  [-86.43665313720705, 43.87710797572527],
  [-86.43459320068361, 43.868940685397064],
  [-86.43150329589847, 43.862257524417934],
  [-86.43013000488281, 43.85408819903011],
  [-86.4280700683594, 43.847898571010965],
  [-86.42704010009768, 43.837498549851645],
  [-86.42704010009768, 43.82883048063436],
  [-86.42772674560547, 43.82139970495799],
  [-86.42738342285159, 43.81471121600007],
  [-86.42910003662111, 43.80727868289007],
  [-86.4297866821289, 43.80257093351484],
  [-86.43356323242188, 43.78918898861837],
  [-86.43665313720705, 43.78497553389674],
  [-86.4390563964844, 43.779522386908056],
  [-86.44111633300781, 43.774812450590474],
  [-86.44351959228518, 43.76886252706789],
  [-86.4455795288086, 43.76415175114463],
  [-86.44969940185548, 43.75944060423707],
  [-86.45484924316409, 43.75200119590342],
  [-86.45931243896487, 43.745056913682724],
  [-86.46549224853517, 43.739352079154706],
  [-86.46961212158207, 43.73538317799622],
  [-86.47819519042972, 43.72719648853415],
  [-86.4836883544922, 43.72074558139473],
  [-86.48952484130862, 43.71454213098033],
  [-86.4936447143555, 43.71032341785253],
  [-86.50051116943364, 43.704118888483805],
  [-86.5049743652344, 43.69965122967144],
  [-86.51115417480469, 43.693445595677424],
  [-86.51458740234376, 43.69021841219658],
  [-86.52282714843751, 43.68028752768313],
  [-86.52660369873047, 43.67507315518335],
  [-86.53003692626955, 43.66960999321699],
  [-86.5331268310547, 43.66389797397276],
  [-86.53621673583987, 43.65744025422564],
  [-86.5382766723633, 43.6499881760459],
  [-86.53793334960939, 43.64178982199116],
  [-86.53690338134766, 43.630111446719226],
  [-86.53553009033205, 43.624147145668054],
  [-86.5331268310547, 43.61395676232751],
  [-86.5317535400391, 43.60898521690245],
  [-86.53003692626955, 43.60326743161364],
  [-86.52660369873047, 43.59754910283444],
  [-86.52420043945317, 43.59008959635457],
  [-86.52008056640626, 43.58536476400977],
  [-86.51733398437503, 43.58063956078784],
  [-86.51390075683597, 43.57491908172946],
  [-86.51012420654298, 43.56944681061763],
  [-86.50634765625003, 43.56173503918958],
  [-86.50360107421875, 43.55725678337675],
  [-86.49330139160159, 43.54108253290259],
  [-86.4888381958008, 43.53710051325697],
  [-86.4847183227539, 43.53137589928477],
  [-86.48300170898439, 43.52714431360086],
  [-86.47853851318361, 43.51868025160502],
  [-86.47270202636719, 43.51195794467572],
  [-86.46961212158207, 43.50349175219821],
  [-86.46411895751953, 43.49377906918119],
  [-86.46102905273438, 43.48506122856573],
  [-86.45862579345706, 43.47708953029804],
  [-86.45416259765628, 43.469864270218466],
  [-86.45347595214844, 43.461142978338984],
  [-86.45175933837893, 43.454912713790264],
  [-86.44832611083984, 43.44544148221772],
  [-86.44695281982425, 43.440705310255076],
  [-86.44523620605469, 43.43123185399347],
  [-86.44283294677737, 43.424998508158126],
  [-86.43733978271484, 43.41302868475145],
  [-86.43493652343753, 43.40579576975358],
  [-86.43253326416016, 43.40030815462976],
  [-86.42910003662111, 43.39382215044703],
  [-86.42601013183594, 43.388832444040816],
  [-86.42395019531251, 43.38459087060759],
  [-86.42326354980472, 43.38034900037737],
  [-86.42326354980472, 43.37685547262911],
  [-86.42051696777347, 43.37236349833036],
  [-86.41742706298828, 43.367372025533484],
  [-86.41365051269533, 43.35938481464255],
  [-86.40884399414064, 43.351895849388924],
  [-86.40609741210938, 43.34415628101457],
  [-86.40335083007814, 43.33666543652568],
  [-86.40060424804689, 43.33266994157184],
  [-86.39404412533624, 43.322430285857024],
  [-86.39232751156669, 43.31693512483893],
  [-86.38820763851983, 43.31268852365456],
  [-86.38511773373466, 43.3056934748018],
  [-86.38065453793389, 43.30044665986483],
  [-86.3758480193792, 43.2939499758991],
  [-86.37069817807061, 43.28595310431108],
  [-86.36726495053156, 43.280704585930856],
  [-86.3634884002386, 43.27370585706565],
  [-86.36005517269952, 43.268706272179735],
  [-86.35799523617607, 43.26370627659918],
  [-86.35627862240656, 43.26120612479979],
  [-86.34906884457452, 43.252204728201605],
  [-86.34460564877374, 43.24420236973],
  [-86.33945580746514, 43.236949324263584],
  [-86.33876916195732, 43.23119629494612],
  [-86.33499261166435, 43.228444654157364],
  [-86.33430596615653, 43.22444204579973],
  [-86.3270961883245, 43.21843764047716],
  [-86.32331963803155, 43.21343351765924],
  [-86.31988641049251, 43.20867922062147],
  [-86.31576653744561, 43.20167221264031],
  [-86.31096001889091, 43.19516498456403],
  [-86.30787011410577, 43.18990863988786],
  [-86.29791375424249, 43.17313537107139],
  [-86.29413720394953, 43.16862833444991],
  [-86.29139062191825, 43.16387054608572],
  [-86.28967400814875, 43.159613263439475],
  [-86.28761407162528, 43.15585659121691],
  [-86.28521081234797, 43.15184921961155],
  [-86.2831508758245, 43.14734061245446],
  [-86.28006097103935, 43.1405770781429],
  [-86.2766277435003, 43.13331170781402],
  [-86.27285119320736, 43.12729833473083],
  [-86.26907464291436, 43.12153496408085],
  [-86.26701470639092, 43.11827716657796],
  [-86.26598473812922, 43.11401670811261],
  [-86.26186486508234, 43.107249487936684],
  [-86.25774499203547, 43.09998015877999],
  [-86.25499841000422, 43.09270996646064],
  [-86.25293847348075, 43.088447728285686],
  [-86.25087853695733, 43.08268069864581],
  [-86.24916192318778, 43.079420833039805],
  [-86.24778863217217, 43.07189740429314],
  [-86.24710198666438, 43.06637963617602],
  [-86.24710198666438, 43.06061052932151],
  [-86.24641534115656, 43.05785119934999],
  [-86.24538537289483, 43.055844335920945],
  [-86.24298211361749, 43.0525830427663],
  [-86.24023553158624, 43.04881979669318],
  [-86.23851891781672, 43.042296289854015],
  [-86.23302575375419, 43.03125492868639],
  [-86.23027917172296, 43.02322359999163],
  [-86.22890588070732, 43.016697371696736],
  [-86.22615929867611, 43.00665566595925],
  [-86.22547265316825, 43.001634197412905],
  [-86.22306939389094, 42.99259451971113],
  [-86.22169610287528, 42.98430698028034],
  [-86.22032281185967, 42.9767718838891],
  [-86.21860619809017, 42.97049193148626],
  [-86.21757622982841, 42.963457623478156],
  [-86.21585961605888, 42.953155935455975],
  [-86.21482964779717, 42.94863269835028],
  [-86.21345635678153, 42.942098546783484],
  [-86.21345635678153, 42.93631775765239],
  [-86.21345635678153, 42.930536425795545],
  [-86.21242638851983, 42.92475455122091],
  [-86.21242638851983, 42.91746358799962],
  [-86.21208306576592, 42.912434834811414],
  [-86.21242638851983, 42.907154202320626],
  [-86.21242638851983, 42.902124607912725],
  [-86.21139642025813, 42.89784913003374],
  [-86.20967980648857, 42.892567248047264],
  [-86.20933648373467, 42.88829110747604],
  [-86.20967980648857, 42.88225369843702],
  [-86.20933648373467, 42.875460907001774],
  [-86.20864983822686, 42.8714351962427],
  [-86.20761986996516, 42.86564777696645],
  [-86.20761986996516, 42.86162142612777],
  [-86.20727654721125, 42.85734314063882],
  [-86.20727654721125, 42.85381962412136],
  [-86.20727654721125, 42.848785680348904],
  [-86.20727654721125, 42.843247868318876],
  [-86.20761986996516, 42.83645078414529],
  [-86.20761986996516, 42.82864580245116],
  [-86.20761986996516, 42.82486884455128],
  [-86.20761986996516, 42.81932888885888],
  [-86.20727654721125, 42.813788436758806],
  [-86.20727654721125, 42.80799561516835],
  [-86.20761986996516, 42.80396550624674],
  [-86.20693322445733, 42.79741601927622],
  [-86.20693322445733, 42.79338522116176],
  [-86.20693322445733, 42.7901100043747],
  [-86.20693322445733, 42.78305510262813],
  [-86.20693322445733, 42.779023369211956],
  [-86.20864983822686, 42.77675540379361],
  [-86.20864983822686, 42.7744873553083],
  [-86.20693322445733, 42.771715183242215],
  [-86.20418664242608, 42.76617046685292],
  [-86.20418664242608, 42.757600383443204],
  [-86.20384331967219, 42.750793884499174],
  [-86.20315667416438, 42.74070880826687],
  [-86.20281335141048, 42.73087427928485],
  [-86.20281335141048, 42.72028150388752],
  [-86.20281335141048, 42.708425539263935],
  [-86.20315667416438, 42.69909052694583],
  [-86.20555993344169, 42.687735241914154],
  [-86.20830651547297, 42.68016389846512],
  [-86.21070977475033, 42.676377880719656],
  [-86.21070977475033, 42.67107706833862],
  [-86.21208306576592, 42.665018443429155],
  [-86.21482964779717, 42.65794930135694],
  [-86.21826287533622, 42.652394411593924],
  [-86.22306939389094, 42.64507129879993],
  [-86.22444268490655, 42.64103059165477],
  [-86.22444268490655, 42.63572676526465],
  [-86.22409936215266, 42.619559934653786],
  [-86.22306939389094, 42.61021157035975],
  [-86.2220394256292, 42.60111451766069],
  [-86.22238274838311, 42.592268887294566],
  [-86.22444268490655, 42.582410848693954],
  [-86.22856255795341, 42.57583795687153],
  [-86.23062249447688, 42.56901152817755],
  [-86.2326824310003, 42.55889692710369],
  [-86.23405572201595, 42.549033612225145],
  [-86.23645898129325, 42.539927630324506],
  [-86.23714562680107, 42.527784255084725],
  [-86.2392055633245, 42.5174099190076],
  [-86.24126549984794, 42.5088055044771],
  [-86.2419521453558, 42.50424974662176],
  [-86.2443554046331, 42.48754246068187],
  [-86.24538537289483, 42.48323834594141],
  [-86.24710198666438, 42.475135679579985],
  [-86.24916192318778, 42.46931124005786],
  [-86.25087853695733, 42.46449933772237],
  [-86.25259515072685, 42.456647545121584],
  [-86.25465508725028, 42.451074707889624],
  [-86.25911828305108, 42.444994683542305],
  [-86.26049157406669, 42.43916743978628],
  [-86.26323815609796, 42.435620156499816],
  [-86.26598473812922, 42.429031811945784],
  [-86.26804467465263, 42.422189336512744],
  [-86.27319451596126, 42.41407876891736],
  [-86.27559777523857, 42.409262623071186],
  [-86.27971764828544, 42.404953126475725],
  [-86.28006097103935, 42.40267150842346],
  [-86.27971764828544, 42.39937569141588],
  [-86.27971764828544, 42.39531906359705],
  [-86.281434262055, 42.39024791005226],
  [-86.2821209075628, 42.38619069220356],
  [-86.28486748959405, 42.38213321212493],
  [-86.28795739437919, 42.37553924771847],
  [-86.29001733090264, 42.37021284789701],
  [-86.29207726742608, 42.36285660077778],
  [-86.29619714047294, 42.35524578349561],
  [-86.29757043148858, 42.349663931625564],
  [-86.30272027279717, 42.34129022434778],
  [-86.30752679135185, 42.33164639191574],
  [-86.30924340512138, 42.3280930282246],
  [-86.31336327816827, 42.321239545673116],
  [-86.31782647396905, 42.31362368943789],
  [-86.32057305600031, 42.30727643850876],
  [-86.3260662200628, 42.297881332524916],
  [-86.33052941586357, 42.29026264986023],
  [-86.33464928891044, 42.284929023632834],
  [-86.3373958709417, 42.2785788798019],
  [-86.34048577572685, 42.27375234246752],
  [-86.34460564877374, 42.26790919743791],
  [-86.34735223080499, 42.262319595341644],
  [-86.34906884457452, 42.25800001792789],
  [-86.35559197689875, 42.250376512405275],
  [-86.36280175473077, 42.24198959200801],
  [-86.36692162777766, 42.23614350327826],
  [-86.37104150082453, 42.22928001237996],
  [-86.37722131039482, 42.222415775126244],
  [-86.38408776547296, 42.21464406733724],
  [-86.38855096127374, 42.20981263621225],
  [-86.39370080258233, 42.20447220341947],
  [-86.39953728939874, 42.19760526931611],
  [-86.40674706723077, 42.189211336398394],
  [-86.41121026303156, 42.185141149461764],
  [-86.41670342709403, 42.180307462110235],
  [-86.42219659115656, 42.17394678417533],
  [-86.43009301449639, 42.16834885838393],
  [-86.43695946957455, 42.16071452481277],
  [-86.44142266537531, 42.15613348248783],
  [-86.44485589291438, 42.15282474567412],
  [-86.45137902523857, 42.145697648105376],
  [-86.45618554379327, 42.141115518789825],
  [-86.46026842645371, 42.13540417611067],
  [-86.47400133660994, 42.12420100926025],
  [-86.48430101922713, 42.115033308085856],
  [-86.48567431024277, 42.10790195686796],
  [-86.49322741082871, 42.095165405549544],
  [-86.49940722039902, 42.082935909408015],
  [-86.5035270934459, 42.07529127706795],
  [-86.5138267760631, 42.05796003430605],
  [-86.52137987664904, 42.047253079589574],
  [-86.53373949578963, 42.03093424950211],
  [-86.54541246942247, 42.0125705565935],
  [-86.55159227899276, 42.002366213375524],
  [-86.55639879754746, 41.993180905029675],
  [-86.55983202508655, 41.983994270935625],
  [-86.56532518914904, 41.97276436226525],
  [-86.56669848016463, 41.96102188584735],
  [-86.57150499871933, 41.95234127309014],
  [-86.57699816278183, 41.94212727375355],
  [-86.5866111998912, 41.92935747337991],
  [-86.59210436395372, 41.92118345933602],
  [-86.59897081903186, 41.91300839806807],
  [-86.61064379266465, 41.90074384269173],
  [-86.62025682977404, 41.88949926385018],
  [-86.63055651239122, 41.879786443521795],
  [-86.64085619500838, 41.87365126992508],
  [-86.6518425231334, 41.864447405239375],
  [-86.66694872430529, 41.8547307771611],
  [-86.67244188836776, 41.84808170729207],
  [-86.67587511590682, 41.843989628462175],
  [-86.68480150750842, 41.83682786072717],
  [-86.69922106317244, 41.82966529146938],
  [-86.70608751825058, 41.82403699620285],
  [-86.71638720086779, 41.81840820614334],
  [-86.72874682000838, 41.81226714359978],
  [-86.73629992059432, 41.80663731900798],
  [-86.74316637567246, 41.80203073088396],
  [-86.75003283075057, 41.79742381156327],
  [-86.75689928582871, 41.79179268262892],
  [-86.77131884149279, 41.78616105896385],
  [-86.79123156121932, 41.775408403663256],
  [-86.80221788934435, 41.77028745790562],
  [-86.8111442809459, 41.7656782571705],
  [-86.82075731805527, 41.76106872528616],
  [-86.83792345575057, 41.75360778851667],
  [-86.85096972039901, 41.74746051633357],
  [-86.86332933953963, 41.741312655483206],
  [-86.87568895868027, 41.737213754552705],
  [-86.89079515985213, 41.732089760488044],
  [-86.89697496942246, 41.72901516783828],
  [-86.90109484246933, 41.72696535765168],
  [-86.90658800653183, 41.72337803245158],
  [-86.91002123407088, 41.7197905069603],
  [-86.91688768914902, 41.71517767988515],
  [-86.92238085321152, 41.711589696596405],
  [-86.93611376336777, 41.705951032493154],
  [-86.94710009149276, 41.70287518907534],
  [-86.95739977410994, 41.698773835633276],
  [-86.97044603875838, 41.69467222061243],
  [-86.97799913934432, 41.69159583768823],
  [-86.9869255309459, 41.690570344017004],
  [-86.9979118590709, 41.68544263044023],
  [-87.01370470575058, 41.678263144827326],
  [-87.02743761590682, 41.67313445039713],
  [-87.04803698114122, 41.66595359162368],
  [-87.07069628289902, 41.65877193187189],
  [-87.09266893914901, 41.65158947116516],
  [-87.11601488641463, 41.646458651721325],
  [-87.12974779657091, 41.646458651721325],
  [-87.14279406121933, 41.644919326204956],
  [-87.1455406432506, 41.63619578695278],
  [-87.14966051629744, 41.64081427836608],
  [-87.15446703485215, 41.63978797554411],
  [-87.15584032586776, 41.63311660880125],
  [-87.15996019891463, 41.633629815375066],
  [-87.16751329950057, 41.63260339814132],
  [-87.17369310907091, 41.630037283551005],
  [-87.18330614618026, 41.62849756576446],
  [-87.19909899285999, 41.62644455151184],
  [-87.21283190301621, 41.62490474791976],
  [-87.22725145868029, 41.622338326880936],
  [-87.24441759637558, 41.62079842522694],
  [-87.25952379754747, 41.62028511650381],
  [-87.27119677118027, 41.62079842522694],
  [-87.28149645379746, 41.621825030415486],
  [-87.29179613641463, 41.621825030415486],
  [-87.30278246453963, 41.622851619260516],
  [-87.31308214715685, 41.622851619260516],
  [-87.32406847528186, 41.62541801986977],
  [-87.33505480340682, 41.62541801986977],
  [-87.34741442254749, 41.62952404837487],
  [-87.3597740416881, 41.62952404837487],
  [-87.36870043328966, 41.63209018339536],
  [-87.38174669793807, 41.63465621626445],
  [-87.38929979852401, 41.63773532086599],
  [-87.39685289910994, 41.63876165637737],
  [-87.40165941766463, 41.64132742364771],
  [-87.40989916375842, 41.641840564843186],
  [-87.42088549188338, 41.64235370195241],
  [-87.4256920104381, 41.64799794046104],
  [-87.4318718200084, 41.65261558601757],
  [-87.43461840203965, 41.656206859278896],
  [-87.43118517450058, 41.65774591509415],
  [-87.42088549188338, 41.660310926389684],
  [-87.41401903680527, 41.660823916389255],
  [-87.40646593621935, 41.66954412113418],
  [-87.40783922723496, 41.67672457940954],
  [-87.40440599969588, 41.68134016531812],
  [-87.40646593621935, 41.683904236671324],
  [-87.41264574578963, 41.68800653831604],
  [-87.41607897332871, 41.68954483399765],
  [-87.42225878289904, 41.68954483399765],
  [-87.43599169305526, 41.68082733878677],
  [-87.4318718200084, 41.67467310163735],
  [-87.4318718200084, 41.66595359162368],
  [-87.44011156610213, 41.66954412113418],
  [-87.44079821160999, 41.67672457940954],
  [-87.44423143914901, 41.682365806120075],
  [-87.45041124871933, 41.68134016531812],
  [-87.46139757684432, 41.67621171609665],
  [-87.46689074090682, 41.67416022197737],
  [-87.47238390496935, 41.67672457940954],
  [-87.47925036004744, 41.68134016531812],
  [-87.48886339715683, 41.68544263044023],
  [-87.49504320672713, 41.69005759105087],
  [-87.49984972528185, 41.69262131501125],
  [-87.50671618035996, 41.69621035689872],
  [-87.51152269891466, 41.7018498752381],
  [-87.51564257196154, 41.709539330508875],
  [-87.52044909051621, 41.709539330508875],
  [-87.52319567254746, 41.71363999728588],
  [-87.52182238153182, 41.71722786612664],
  [-87.51976244500841, 41.72132804241036],
  [-87.51976244500841, 41.72594042803251],
  [-87.52250902703965, 41.73055248255777],
  [-87.5245689635631, 41.733114592003],
  [-87.5252556090709, 41.73772613147641],
  [-87.52662890008655, 41.74336200783993],
  [-87.53006212762557, 41.75002195128343],
  [-87.5355552916881, 41.7515587631973],
  [-87.53898851922716, 41.7530955383189],
  [-87.54173510125838, 41.7546322766479],
  [-87.53967516473494, 41.758730065649054],
  [-87.54585497430527, 41.758730065649054],
  [-87.55066149285997, 41.76129105091008],
  [-87.55615465692246, 41.76385193396638],
  [-87.56096117547713, 41.77255817185182],
  [-87.56576769403186, 41.777678936327156],
  [-87.5692009215709, 41.78382331402049],
  [-87.57194750360215, 41.78791923873242],
  [-87.5746940856334, 41.79201490177259],
  [-87.57194750360215, 41.797646011184156],
  [-87.57675402215682, 41.801741052736965],
  [-87.58018724969588, 41.806347661687155],
  [-87.58430712274276, 41.8099303507805],
  [-87.58842699578963, 41.81555988597479],
  [-87.59117357782088, 41.82067721589089],
  [-87.59460680535997, 41.82630580650196],
  [-87.59941332391463, 41.83142227768814],
  [-87.60215990594591, 41.83602675211938],
  [-87.60071263415739, 41.8408529450634],
  [-87.60345921618865, 41.85006020574708],
  [-87.60757908923553, 41.85619764340928],
  [-87.61169896228239, 41.865402695677204],
  [-87.61169896228239, 41.87358385192156],
  [-87.60620579821992, 41.87971903198994],
  [-87.60208592517304, 41.891987625200194],
  [-87.60895238025114, 41.89914321656889],
  [-87.61581883532928, 41.9042538624167],
  [-87.62405858142301, 41.916517743529724],
  [-87.62680516345428, 41.92469235527146],
  [-87.62955174548554, 41.9338875417028],
  [-87.63367161853242, 41.943081402677926],
  [-87.6391647825949, 41.958401558721015],
  [-87.63092503650115, 41.96554970477025],
  [-87.64603123767304, 41.97473899993669],
  [-87.65152440173551, 41.98698933134961],
  [-87.65289769275117, 42.00025786409107],
  [-87.65976414782928, 42.012503285318],
  [-87.66525731189178, 42.025766498442316],
  [-87.66663060290739, 42.04310652605084],
  [-87.67075047595426, 42.05636335498209],
  [-87.67624364001679, 42.071656258133274],
  [-87.68997655017301, 42.083887928412516],
  [-87.70508275134489, 42.094079185992804],
  [-87.71744237048551, 42.10324991790546],
  [-87.73117528064176, 42.11955016652522],
  [-87.74628148181367, 42.13482784249153],
  [-87.75452122790742, 42.15213808775021],
  [-87.77374730212617, 42.1755502980575],
  [-87.79022679431365, 42.20098852597393],
  [-87.80121312243867, 42.21828067358829],
  [-87.81082615954803, 42.23353451963146],
  [-87.81219945056368, 42.241668397134184],
  [-87.81906590564176, 42.25590015998011],
  [-87.8259323607199, 42.26911249323194],
  [-87.83005223376676, 42.28537002557093],
  [-87.83005223376676, 42.30060765286429],
  [-87.82730565173551, 42.32091875453638],
  [-87.82181248767303, 42.33310226947203],
  [-87.82181248767303, 42.34832834485092],
  [-87.81769261462617, 42.36152129434985],
  [-87.80670628650114, 42.36456542784775],
  [-87.7984665404074, 42.38384151480013],
  [-87.79709324939179, 42.40311168523325],
  [-87.79983983142301, 42.421362178220576],
  [-87.79983983142301, 42.437580380256335],
  [-87.79434666736054, 42.461899815066],
  [-87.79434666736054, 42.48317157472475],
  [-87.80121312243867, 42.49937377699889],
  [-87.80807957751678, 42.51557178210961],
  [-87.81219945056368, 42.53277756346316],
  [-87.81219945056368, 42.554025221596454],
  [-87.81494603259489, 42.60054244107994],
  [-87.81494603259489, 42.617724805391816],
  [-87.81494603259489, 42.63692301519029],
  [-87.80807957751678, 42.657125260348295],
  [-87.80121312243867, 42.676311316009034],
  [-87.78885350329806, 42.69145401912474],
  [-87.78336033923551, 42.70558387860518],
  [-87.77924046618865, 42.71466566178271],
  [-87.77786717517304, 42.72475497345706],
  [-87.77649388415739, 42.732825241570346],
  [-87.77649388415739, 42.739885864838634],
  [-87.77649388415739, 42.75501306472687],
  [-87.77374730212617, 42.76408761267904],
  [-87.76688084704803, 42.77416888500151],
  [-87.75589451892303, 42.7792089058589],
  [-87.75177464587615, 42.78827990966904],
  [-87.76825413806364, 42.792311040290976],
  [-87.77786717517304, 42.79835724395358],
  [-87.7874802122824, 42.80843293731307],
  [-87.80258641345428, 42.81749965830899],
  [-87.81631932361053, 42.8295865519599],
  [-87.81769261462617, 42.84368494114816],
  [-87.82867894275117, 42.85576671459088],
  [-87.83279881579801, 42.86583305383719],
  [-87.83966527087615, 42.88294206393738],
  [-87.84241185290739, 42.89199785486978],
  [-87.84241185290739, 42.9000463307067],
  [-87.8355453978293, 42.91211707481947],
  [-87.83966527087615, 42.92720218108893],
  [-87.83966527087615, 42.93625147206468],
  [-87.83966527087615, 42.944294170036635],
  [-87.83966527087615, 42.96138141491238],
  [-87.85202489001679, 42.97544970133248],
  [-87.86301121814178, 42.98348727747736],
  [-87.87949071032926, 42.99755050748913],
  [-87.8863571654074, 43.011610519643526],
  [-87.89047703845429, 43.02064739960872],
  [-87.8918503294699, 43.03670968026051],
  [-87.88361058337615, 43.04775005996783],
  [-87.87674412829801, 43.0537712478651],
  [-87.86713109118867, 43.06079521997404],
  [-87.85889134509493, 43.071831264832376],
  [-87.86301121814178, 43.082865322802355],
  [-87.87125096423554, 43.09490021085304],
  [-87.87949071032926, 43.102922155841576],
  [-87.88773045642304, 43.114953102707474],
  [-87.89459691150117, 43.122972420178435],
  [-87.89459691150117, 43.13399726554432],
  [-87.88773045642304, 43.15303549970435],
  [-87.8808640013449, 43.16806149655299],
  [-87.8808640013449, 43.18408515332271],
  [-87.89322362048551, 43.20110568148768],
  [-87.89734349353239, 43.215119022733],
  [-87.90009007556364, 43.22712789565015],
  [-87.90832982165739, 43.24613710685903],
  [-87.90558323962618, 43.26514038792271],
  [-87.89597020251678, 43.28213829675457],
  [-87.88910374743864, 43.30212975809251],
  [-87.88361058337615, 43.32710984345913],
  [-87.87674412829801, 43.34708651837864],
  [-87.87262425525115, 43.37104985298604],
  [-87.86301121814178, 43.388018155409384],
  [-87.86026463611053, 43.396999451831036],
  [-87.8355453978293, 43.42393535501698],
  [-87.82043919665742, 43.44188596851019],
  [-87.80945286853239, 43.45085927859886],
  [-87.7984665404074, 43.46680854309889],
  [-87.79022679431365, 43.485742830289624],
  [-87.78885350329806, 43.50367510325021],
  [-87.79022679431365, 43.523593605531],
  [-87.79022679431365, 43.53852816781056],
  [-87.78610692126676, 43.557439971056255],
  [-87.78610692126676, 43.56739116836417],
  [-87.77786717517304, 43.57933043541367],
  [-87.76413426501678, 43.5942511906067],
  [-87.73117528064176, 43.63402178904661],
  [-87.71744237048551, 43.66283902567487],
  [-87.69684300525115, 43.68667731747932],
  [-87.69546971423554, 43.70157144102315],
  [-87.70233616931364, 43.71447668918225],
  [-87.70370946032929, 43.732340907201895],
  [-87.70370946032929, 43.74523952944063],
  [-87.69546971423554, 43.75416771707757],
  [-87.69409642321989, 43.76111094225127],
  [-87.70096287829803, 43.77400336576437],
  [-87.71057591540739, 43.79184982616453],
  [-87.72156224353239, 43.80869992469426],
  [-87.72842869861051, 43.83148955274504],
  [-87.72980198962615, 43.845357241240244],
  [-87.7325485716574, 43.85427048184641],
  [-87.7325485716574, 43.86318239043447],
  [-87.7325485716574, 43.875062863161816],
  [-87.72705540759492, 43.891889479122455],
  [-87.71881566150117, 43.918604335791024],
  [-87.71606907946992, 43.9344297045324],
  [-87.70096287829803, 43.965079375157586],
  [-87.6872299681418, 43.9996648455737],
  [-87.67487034900114, 44.0273187149927],
  [-87.65976414782928, 44.04212798333239],
  [-87.6501511107199, 44.063841548953924],
  [-87.64877781970429, 44.086533582449064],
  [-87.64603123767304, 44.100341864179946],
  [-87.63504490954801, 44.11217496808156],
  [-87.61169896228239, 44.12499149086108],
  [-87.59109959704801, 44.13189154392653],
  [-87.56638035876678, 44.14174736431673],
  [-87.5457809935324, 44.15455747148024],
  [-87.53067479236051, 44.16440950824335],
  [-87.51694188220429, 44.18115419526688],
  [-87.51007542712615, 44.19789412792088],
  [-87.50595555407929, 44.21266070840523],
  [-87.50732884509489, 44.22742358727668],
  [-87.51419530017303, 44.25103649398685],
  [-87.52518162829803, 44.27267333366123],
  [-87.53616795642304, 44.28643808560947],
  [-87.54028782946992, 44.309044606015846],
  [-87.54028782946992, 44.325748179248045],
  [-87.53067479236051, 44.347357509674545],
  [-87.51831517321989, 44.36306838447479],
  [-87.51282200915742, 44.38171957742061],
  [-87.51419530017303, 44.39742123804924],
  [-87.50732884509489, 44.410175735697884],
  [-87.50458226306367, 44.41900414355344],
  [-87.49771580798554, 44.432734573271325],
  [-87.4963425169699, 44.43959857871894],
  [-87.49496922595426, 44.45234387829624],
  [-87.49496922595426, 44.46312619051851],
  [-87.48947606189176, 44.46998662527245],
  [-87.46613011462614, 44.551270319795805],
  [-87.4455307493918, 44.57964392283472],
  [-87.43454442126679, 44.601159493303705],
  [-87.41257176501678, 44.61973471264548],
  [-87.40021214587615, 44.63048607170216],
  [-87.37686619861053, 44.666635120593526],
  [-87.35764012439179, 44.692023676921835],
  [-87.34116063220429, 44.72325585887219],
  [-87.32742772204803, 44.746668936201],
  [-87.32330784900114, 44.76129729580938],
  [-87.31781468493867, 44.77494709062111],
  [-87.3150681029074, 44.79054290636274],
  [-87.30270848376676, 44.8002881517418],
  [-87.29172215564178, 44.81198027381584],
  [-87.28210911853243, 44.81879958393739],
  [-87.27798924548553, 44.83146187754456],
  [-87.26150975329801, 44.84898969242147],
  [-87.24777684314176, 44.857751600262674],
  [-87.21344456775115, 44.86803631018749],
  [-87.20245823962615, 44.87776846096592],
  [-87.20245823962615, 44.88457998727908],
  [-87.21069798571989, 44.903064350790615],
  [-87.1997116575949, 44.91570812366967],
  [-87.17773900134489, 44.92445987557592],
  [-87.16812596423551, 44.93418248087224],
  [-87.17636571032928, 44.941959380148965],
  [-87.18323216540739, 44.95556641938244],
  [-87.18323216540739, 44.96528375818376],
  [-87.18185887439178, 44.97499945120349],
  [-87.16812596423551, 44.98471349844102],
  [-87.1612595091574, 44.99733929940481],
  [-87.15851292712615, 45.00413665556811],
  [-87.15027318103242, 45.00510764061731],
  [-87.13242039782928, 45.01093320529783],
  [-87.12418065173553, 45.03228854011639],
  [-87.12280736071992, 45.047814689534796],
  [-87.12143406970426, 45.06430660687769],
  [-87.10907445056365, 45.07206586337762],
  [-87.09808812243864, 45.06527657154241],
  [-87.08847508532928, 45.059456536687186],
  [-87.07748875720428, 45.06139661413673],
  [-87.0733688841574, 45.069156265623505],
  [-87.07748875720428, 45.07691486381235],
  [-87.08847508532928, 45.08370277319465],
  [-87.09122166736053, 45.090489876146805],
  [-87.08160863025114, 45.09339838768867],
  [-87.06924901111054, 45.08273312122759],
  [-87.06100926501676, 45.07885434878183],
  [-87.05551610095426, 45.08758121648526],
  [-87.05002293689178, 45.10212303359642],
  [-87.05002293689178, 45.12053735276011],
  [-87.06512913806365, 45.12635116659009],
  [-87.07474217517301, 45.13216438794406],
  [-87.07748875720428, 45.14282042163618],
  [-87.07611546618867, 45.15541134861056],
  [-87.06375584704806, 45.15831655216083],
  [-87.05276951892304, 45.15153751347067],
  [-87.04727635486051, 45.14088310908355],
  [-87.03766331775114, 45.13603953969362],
  [-87.02805028064176, 45.14572626703284],
  [-87.04040989978243, 45.15928492042907],
  [-87.04178319079803, 45.173808469545804],
  [-87.03766331775114, 45.190264015431914],
  [-87.03629002673553, 45.205747243259445],
  [-87.03079686267303, 45.21542212118822],
  [-87.02942357165742, 45.23186563670456],
  [-87.01157078845426, 45.22993135232711],
  [-87.00470433337615, 45.21832426364806],
  [-86.99509129626678, 45.21251983061208],
  [-86.97861180407929, 45.21735689928546],
  [-86.97723851306364, 45.22702980233117],
  [-86.97723851306364, 45.236701059645675],
  [-86.97586522204803, 45.24346996055824],
  [-86.97723851306364, 45.2512048598815],
  [-86.98135838611053, 45.25700534317157],
  [-86.98135838611053, 45.26667149874901],
  [-86.96625218493865, 45.27730236908757],
  [-86.96762547595429, 45.28503266041519],
  [-86.97861180407929, 45.289863557641816],
  [-86.98822484118864, 45.291795801333556],
  [-86.99921116931367, 45.295660091233316],
  [-87.01981053454801, 45.29469404344385],
  [-87.01981053454801, 45.28599887277452],
  [-87.02805028064176, 45.28599887277452],
  [-87.04040989978243, 45.28696506867684],
  [-87.05002293689178, 45.290829687716204],
  [-87.06375584704806, 45.29662612256588],
  [-87.06512913806365, 45.28889741111048],
  [-87.06924901111054, 45.27730236908757],
  [-87.0733688841574, 45.26667149874901],
  [-87.0733688841574, 45.25797203278648],
  [-87.08572850329803, 45.25797203278648],
  [-87.09808812243864, 45.25797203278648],
  [-87.10907445056365, 45.24830439606437],
  [-87.11319432361051, 45.2405691017678],
  [-87.11868748767304, 45.22993135232711],
  [-87.12006077868865, 45.222193556524964],
  [-87.12006077868865, 45.20961739191955],
  [-87.11868748767304, 45.19897384949402],
  [-87.11731419665739, 45.19123184060192],
  [-87.12555394275114, 45.190264015431914],
  [-87.14340672595428, 45.190264015431914],
  [-87.15713963611056, 45.18832831571938],
  [-87.17087254626678, 45.18252082160239],
  [-87.17224583728239, 45.170904055958275],
  [-87.16949925525117, 45.16122160759284],
  [-87.16949925525117, 45.15444291451196],
  [-87.17773900134489, 45.15444291451196],
  [-87.18460545642303, 45.16122160759284],
  [-87.1997116575949, 45.16509478442965],
  [-87.21344456775115, 45.16993588518069],
  [-87.22443089587614, 45.17574466298317],
  [-87.23267064196992, 45.16993588518069],
  [-87.23541722400117, 45.16025327223976],
  [-87.23953709704804, 45.14572626703284],
  [-87.24091038806365, 45.13410199673413],
  [-87.2491501341574, 45.12150636287594],
  [-87.2587631712668, 45.11181552112129],
  [-87.2587631712668, 45.09630675111088],
  [-87.2587631712668, 45.08758121648526],
  [-87.26700291736054, 45.077884614525985],
  [-87.28073582751678, 45.07303569638012],
  [-87.28073582751678, 45.06139661413673],
  [-87.28073582751678, 45.049755161971525],
  [-87.28760228259492, 45.045874151266844],
  [-87.30133519275115, 45.05169556857705],
  [-87.3040817747824, 45.046844428629726],
  [-87.3150681029074, 45.04005214147558],
  [-87.32208251953125, 45.03659171478303],
  [-87.32620239257812, 45.02591584579862],
  [-87.33444213867188, 45.018150326651934],
  [-87.34268188476567, 45.01038375420438],
  [-87.34542846679688, 45.0026161284559],
  [-87.3577880859375, 44.98416379587037],
  [-87.37289428710938, 44.96181881255858],
  [-87.39212036132812, 44.93460447330159],
  [-87.396240234375, 44.92293723517865],
  [-87.40036010742188, 44.91126762713704],
  [-87.38662719726562, 44.898622877374486],
  [-87.38113403320312, 44.881110170614285],
  [-87.37976074218751, 44.87040533592566],
  [-87.3783874511719, 44.85288403826033],
  [-87.37701416015625, 44.84412138982687],
  [-87.37014770507814, 44.8334096758519],
  [-87.36328125, 44.82367002601362],
  [-87.37564086914064, 44.82854005665195],
  [-87.38662719726562, 44.83243578492702],
  [-87.38800048828126, 44.84120021077909],
  [-87.39761352539065, 44.8499633035676],
  [-87.39761352539065, 44.856778120777435],
  [-87.40447998046875, 44.863592131563756],
  [-87.41134643554689, 44.87040533592566],
  [-87.42233276367189, 44.87819086773676],
  [-87.42645263671876, 44.88597534625637],
  [-87.4237060546875, 44.89181301392284],
  [-87.43881225585938, 44.890840110456054],
  [-87.4443054199219, 44.885002344043336],
  [-87.45254516601565, 44.87819086773676],
  [-87.46490478515628, 44.87137858500389],
  [-87.4717712402344, 44.87040533592566],
  [-87.47863769531251, 44.863592131563756],
  [-87.48138427734376, 44.858725063290294],
  [-87.49237060546876, 44.858725063290294],
  [-87.50335693359378, 44.85385758357605],
  [-87.52120971679688, 44.85191047648702],
  [-87.53356933593751, 44.854831112434134],
  [-87.53906250000001, 44.84509508326097],
  [-87.53906250000001, 44.836331249881326],
  [-87.5431823730469, 44.82951401340703],
  [-87.5555419921875, 44.826592093769186],
  [-87.55966186523439, 44.83146187754456],
  [-87.56103515625004, 44.836331249881326],
  [-87.55828857421875, 44.84412138982687],
  [-87.55004882812503, 44.85288403826033],
  [-87.56103515625004, 44.85288403826033],
  [-87.57751464843753, 44.85191047648702],
  [-87.58987426757815, 44.846068760237436],
  [-87.60910034179689, 44.836331249881326],
  [-87.61871337890625, 44.82367002601362],
  [-87.6365661621094, 44.80808316311038],
  [-87.64892578125, 44.79736475093087],
  [-87.65991210937503, 44.7837202553825],
  [-87.67364501953126, 44.7710474783922],
  [-87.69149780273439, 44.75349596482042],
  [-87.70660400390628, 44.736914639312914],
  [-87.7134704589844, 44.72813436502851],
  [-87.71896362304688, 44.71447351099651],
  [-87.71759033203128, 44.7066658604746],
  [-87.71896362304688, 44.69495240985682],
  [-87.72720336914062, 44.681283722315165],
  [-87.7409362792969, 44.666635120593526],
  [-87.75604248046879, 44.65100586415933],
  [-87.76977539062501, 44.64416673971082],
  [-87.78076171875001, 44.638303991234835],
  [-87.80136108398439, 44.634395163120395],
  [-87.82196044921876, 44.62559933715905],
  [-87.8411865234375, 44.62071219120539],
  [-87.85903930664064, 44.616802178227495],
  [-87.87002563476562, 44.607025993838825],
  [-87.88787841796875, 44.59235863170578],
  [-87.89337158203125, 44.58649065011287],
  [-87.90298461914062, 44.57964392283472],
  [-87.89749145507814, 44.573774657667144],
  [-87.90161132812503, 44.56594804924097],
  [-87.90573120117189, 44.554206161890356],
  [-87.93045043945312, 44.53658888779365],
  [-87.94281005859379, 44.53365215708899],
  [-87.96478271484376, 44.53365215708899],
  [-87.97988891601564, 44.5356099940144],
  [-87.99774169921879, 44.54539819130008],
  [-88.01559448242188, 44.552248950283165],
  [-88.02520751953126, 44.560077401771615],
  [-88.03619384765625, 44.56790480008332],
  [-88.03070068359376, 44.573774657667144],
  [-88.01696777343751, 44.57866575311358],
  [-88.01147460937501, 44.59431449392089],
  [-88.01147460937501, 44.61191429173712],
  [-88.0101013183594, 44.629508757706425],
  [-88.00048828125, 44.653936670645386],
  [-87.99499511718753, 44.674448168967835],
  [-87.98675537109375, 44.68421299832633],
  [-87.98675537109375, 44.70568983010298],
  [-87.97714233398438, 44.72228010826979],
  [-87.94692993164064, 44.748619598092546],
  [-87.93319702148439, 44.76617259281679],
  [-87.91946411132817, 44.78274552512732],
  [-87.91122436523438, 44.80223700332862],
  [-87.89611816406254, 44.82367002601362],
  [-87.88101196289065, 44.8334096758519],
  [-87.8617858886719, 44.84509508326097],
  [-87.84942626953129, 44.85969850986025],
  [-87.83706665039062, 44.87429823349259],
  [-87.83020019531251, 44.89278590093193],
  [-87.83569335937501, 44.903486571822484],
  [-87.83569335937501, 44.92488193944358],
  [-87.82745361328126, 44.93752091253225],
  [-87.80822753906253, 44.95307275351097],
  [-87.78762817382815, 44.958903607663004],
  [-87.76016235351565, 44.9637622001999],
  [-87.7423095703125, 44.9637622001999],
  [-87.71209716796875, 44.972506629923856],
  [-87.68325805664065, 44.97347815093749],
  [-87.64068603515626, 44.978335509137956],
  [-87.62420654296878, 44.986106426451926],
  [-87.62557983398438, 45.01620878228696],
  [-87.61871337890625, 45.044354732341404],
  [-87.6104736328125, 45.069577265057276],
  [-87.59811401367189, 45.08121468162804],
  [-87.58300781250001, 45.091880231577555],
  [-87.5926208496094, 45.09769696493222],
  [-87.60223388671875, 45.10642095406738],
  [-87.60498046875, 45.11223620622697],
  [-87.60498046875, 45.128709536533165],
  [-87.60498046875, 45.14227223723683],
  [-87.58575439453126, 45.15486328529116],
  [-87.57888793945312, 45.16745155201321],
  [-87.56378173828125, 45.17810098991997],
  [-87.55691528320314, 45.18294098521326],
  [-87.53906250000001, 45.19261974148837],
  [-87.53219604492188, 45.204232076634604],
  [-87.50885009765625, 45.22454796065217],
  [-87.46490478515628, 45.268057558763466],
  [-87.43743896484376, 45.28931678892399],
  [-87.43194580078126, 45.30187531732281],
  [-87.42095947265628, 45.32312187563111],
  [-87.40173339843753, 45.333742167875315],
  [-87.40036010742188, 45.343395251051454],
  [-87.39212036132812, 45.35401174180289],
  [-87.37014770507814, 45.38199112717856],
  [-87.34130859375001, 45.40706432707182],
  [-87.32345581054689, 45.430198945578184],
  [-87.32894897460939, 45.43887198380005],
  [-87.32208251953125, 45.45139735248441],
  [-87.31658935546875, 45.463919940141096],
  [-87.3028564453125, 45.47355080747389],
  [-87.28912353515628, 45.49473292325495],
  [-87.26715087890625, 45.53322545644789],
  [-87.2575378417969, 45.55246185001779],
  [-87.24380493164062, 45.56784622589655],
  [-87.20397949218751, 45.62550011934037],
  [-87.17514038085939, 45.65142504612715],
  [-87.1531677246094, 45.66582260033972],
  [-87.12432861328129, 45.68117591106179],
  [-87.10784912109375, 45.68213535312938],
  [-87.09548950195312, 45.690769591346786],
  [-87.08450317382812, 45.69940249686766],
  [-87.06527709960938, 45.71474659388184],
  [-87.05841064453126, 45.730086479003305],
  [-87.05429077148442, 45.74350542346101],
  [-87.05841064453126, 45.7607536137967],
  [-87.05566406250001, 45.77512303386322],
  [-87.05429077148442, 45.79236145176166],
  [-87.05017089843751, 45.814380562190436],
  [-87.02957153320314, 45.83543417665606],
  [-87.01721191406251, 45.83926125111078],
  [-86.99935913085938, 45.84882778569605],
  [-87.00897216796876, 45.85456691681716],
  [-87.01309204101565, 45.86604340240825],
  [-87.00347900390626, 45.875605330865724],
  [-86.99386596679688, 45.88707747359738],
  [-86.98425292968753, 45.903325620897796],
  [-86.97052001953125, 45.90810358301641],
  [-86.95816040039065, 45.90810358301641],
  [-86.94854736328126, 45.897591523516816],
  [-86.94305419921876, 45.88325368922061],
  [-86.95404052734376, 45.87082457226481],
  [-86.96914672851565, 45.85552338109384],
  [-86.98974609375001, 45.83926125111078],
  [-87.00073242187503, 45.83160683898813],
  [-86.99386596679688, 45.82299436685628],
  [-86.98425292968753, 45.80863728544418],
  [-86.98013305664064, 45.79044633522134],
  [-86.978759765625, 45.773207325062245],
  [-86.97601318359375, 45.75596298450901],
  [-86.978759765625, 45.741588628823976],
  [-86.97738647460939, 45.72529321734885],
  [-86.978759765625, 45.71186989652116],
  [-86.9732666015625, 45.69748418858939],
  [-86.96365356445312, 45.68405418790507],
  [-86.96365356445312, 45.67062096240597],
  [-86.95404052734376, 45.68213535312938],
  [-86.93893432617189, 45.696525009770795],
  [-86.93069458007814, 45.7051570268327],
  [-86.92108154296875, 45.70227983588815],
  [-86.91421508789065, 45.69748418858939],
  [-86.90734863281251, 45.69844335095495],
  [-86.89910888671878, 45.70995201601696],
  [-86.87850952148439, 45.71186989652116],
  [-86.86065673828126, 45.70995201601696],
  [-86.85516357421876, 45.71666430985874],
  [-86.84555053710939, 45.71954076042907],
  [-86.83731079101564, 45.72721057136819],
  [-86.83593750000004, 45.74254703436859],
  [-86.8304443359375, 45.74733881530778],
  [-86.81671142578126, 45.75308840951133],
  [-86.81671142578126, 45.76554383178376],
  [-86.8180847167969, 45.773207325062245],
  [-86.80435180664062, 45.77703867685699],
  [-86.80297851562501, 45.785658255967846],
  [-86.80709838867188, 45.79236145176166],
  [-86.79885864257814, 45.79810640654633],
  [-86.77963256835939, 45.79810640654633],
  [-86.77688598632812, 45.8057654249791],
  [-86.77139282226565, 45.818209084330846],
  [-86.77551269531251, 45.82586533895898],
  [-86.78237915039065, 45.83160683898813],
  [-86.78237915039065, 45.84117468963342],
  [-86.77963256835939, 45.850740895205355],
  [-86.77001953125001, 45.86221817041759],
  [-86.75628662109376, 45.86508711908611],
  [-86.73843383789064, 45.86126182129402],
  [-86.72058105468751, 45.84404472403697],
  [-86.69998168945315, 45.83926125111078],
  [-86.67800903320314, 45.83926125111078],
  [-86.64642333984378, 45.836390969945924],
  [-86.6326904296875, 45.84308806235314],
  [-86.63131713867189, 45.85265393891232],
  [-86.6258239746094, 45.867955919701714],
  [-86.61346435546876, 45.875605330865724],
  [-86.60247802734378, 45.880385678213024],
  [-86.59011840820314, 45.89090099477511],
  [-86.57089233398442, 45.899502955109284],
  [-86.55029296875, 45.89472425275491],
  [-86.53106689453125, 45.881341698332236],
  [-86.52420043945317, 45.869868371193995],
  [-86.53656005859378, 45.845957998052555],
  [-86.54754638671879, 45.83447736691537],
  [-86.55715942382814, 45.82395137400817],
  [-86.56127929687501, 45.81342339052743],
  [-86.56127929687501, 45.8057654249791],
  [-86.57501220703126, 45.80002125986285],
  [-86.57501220703126, 45.78948875227381],
  [-86.56402587890628, 45.779912017959354],
  [-86.58187866210938, 45.77799647367621],
  [-86.60247802734378, 45.77703867685699],
  [-86.61346435546876, 45.767459803814795],
  [-86.62445068359375, 45.75787928558029],
  [-86.6326904296875, 45.74829712213886],
  [-86.63955688476564, 45.74063020682694],
  [-86.6532897949219, 45.72912785957743],
  [-86.66290283203126, 45.72049954438053],
  [-86.66290283203126, 45.71186989652116],
  [-86.66290283203126, 45.7051570268327],
  [-86.671142578125, 45.6955658144993],
  [-86.68212890625, 45.68789166001888],
  [-86.69723510742189, 45.68789166001888],
  [-86.70822143554688, 45.685013580613195],
  [-86.71234130859376, 45.679256977567114],
  [-86.71234130859376, 45.663903140339606],
  [-86.70959472656251, 45.65622464220194],
  [-86.69586181640628, 45.65526475589407],
  [-86.69174194335939, 45.65046507755156],
  [-86.68350219726564, 45.63798398878761],
  [-86.67388916015626, 45.627420895632255],
  [-86.66290283203126, 45.61973739557064],
  [-86.6436767578125, 45.61685581155722],
  [-86.62719726562501, 45.61589525064475],
  [-86.6258239746094, 45.60821017099902],
  [-86.61621093750001, 45.60148486246796],
  [-86.60247802734378, 45.60244567019207],
  [-86.60934448242188, 45.61205284245447],
  [-86.61895751953126, 45.631262250769446],
  [-86.62170410156251, 45.64182461995809],
  [-86.62170410156251, 45.663903140339606],
  [-86.61071777343753, 45.66582260033972],
  [-86.58462524414064, 45.66582260033972],
  [-86.58050537109376, 45.68117591106179],
  [-86.5777587890625, 45.694606602774805],
  [-86.5763854980469, 45.71378771121436],
  [-86.56402587890628, 45.71282881209412],
  [-86.52969360351565, 45.71282881209412],
  [-86.52832031250001, 45.71762314316819],
  [-86.52694702148438, 45.72816922369904],
  [-86.52145385742188, 45.735837850056804],
  [-86.52557373046876, 45.749255412517726],
  [-86.50909423828128, 45.75308840951133],
  [-86.49398803710939, 45.74829712213886],
  [-86.47613525390625, 45.75500480929524],
  [-86.46789550781251, 45.763627793944885],
  [-86.45965576171876, 45.756921143270674],
  [-86.44180297851564, 45.7607536137967],
  [-86.4280700683594, 45.76841776515241],
  [-86.42532348632812, 45.78278521099619],
  [-86.41571044921878, 45.79331898535451],
  [-86.39511108398439, 45.795234003185676],
  [-86.38137817382815, 45.79236145176166],
  [-86.36764526367188, 45.79236145176166],
  [-86.35528564453125, 45.79714895521097],
  [-86.34841918945314, 45.80193604737375],
  [-86.34841918945314, 45.81055177683116],
  [-86.3470458984375, 45.817251978472456],
  [-86.34292602539064, 45.8287361630028],
  [-86.34155273437503, 45.836390969945924],
  [-86.34017944335938, 45.845957998052555],
  [-86.32507324218751, 45.85265393891232],
  [-86.33056640625001, 45.86221817041759],
  [-86.33193969726562, 45.87464921204586],
  [-86.32644653320312, 45.90045864623095],
  [-86.30996704101564, 45.916702878509156],
  [-86.30035400390625, 45.92339029828073],
  [-86.28387451171876, 45.92530084160022],
  [-86.27151489257815, 45.9358076537404],
  [-86.27700805664062, 45.94440265598515],
  [-86.26602172851562, 45.950131917280956],
  [-86.24267578125, 45.952996325870146],
  [-86.23306274414065, 45.94822222931101],
  [-86.22207641601562, 45.951086736592785],
  [-86.20285034179689, 45.96445247984661],
  [-86.18499755859376, 45.958724698933416],
  [-86.16165161132814, 45.955860586420734],
  [-86.13555908203126, 45.955860586420734],
  [-86.13006591796876, 45.96254328533641],
  [-86.10122680664064, 45.96636160856278],
  [-86.0765075683594, 45.96636160856278],
  [-86.04217529296876, 45.960634025032036],
  [-86.02157592773439, 45.95490584935257],
  [-85.97625732421876, 45.94822222931101],
  [-85.96115112304689, 45.943447721531534],
  [-85.94192504882812, 45.93485257124601],
  [-85.92956542968751, 45.92530084160022],
  [-85.9185791015625, 45.91956901425015],
  [-85.90896606445315, 45.918613652119205],
  [-85.92132568359378, 45.9367627197858],
  [-85.92132568359378, 45.94917708152041],
  [-85.91033935546875, 45.95967937020702],
  [-85.89385986328126, 45.96540705242895],
  [-85.87600708007812, 45.971134142505434],
  [-85.84716796875001, 45.971134142505434],
  [-85.83068847656253, 45.971134142505434],
  [-85.82107543945314, 45.978769341499195],
  [-85.79910278320317, 45.978769341499195],
  [-85.7867431640625, 45.97686064044045],
  [-85.76065063476565, 45.970179668613724],
  [-85.7318115234375, 45.96349789081576],
  [-85.71945190429689, 45.958724698933416],
  [-85.69335937500001, 45.958724698933416],
  [-85.67413330078129, 45.96445247984661],
  [-85.65353393554688, 45.96445247984661],
  [-85.65216064453126, 45.97495187358871],
  [-85.638427734375, 45.987357682078546],
  [-85.6205749511719, 45.9949906441254],
  [-85.6109619140625, 46.01406844385118],
  [-85.59997558593751, 46.02741898914063],
  [-85.57937622070314, 46.03981303755959],
  [-85.56701660156251, 46.05125123058942],
  [-85.54367065429689, 46.07316781410998],
  [-85.51483154296876, 46.094123361782216],
  [-85.49697875976564, 46.094123361782216],
  [-85.47775268554689, 46.094123361782216],
  [-85.45715332031251, 46.09031385426172],
  [-85.44204711914064, 46.08840900182607],
  [-85.43243408203129, 46.09602801686742],
  [-85.41870117187501, 46.1007893667796],
  [-85.39810180664065, 46.09888487615192],
  [-85.38986206054689, 46.08840900182607],
  [-85.37338256835938, 46.081741500252775],
  [-85.36102294921876, 46.09221864091373],
  [-85.33355712890625, 46.093171009570874],
  [-85.31433105468751, 46.08745655093932],
  [-85.30471801757815, 46.078883752887414],
  [-85.28823852539064, 46.07602585750672],
  [-85.27313232421876, 46.06649847056345],
  [-85.25527954101564, 46.06459279581834],
  [-85.2305603027344, 46.05792241614577],
  [-85.20172119140625, 46.05411040879364],
  [-85.18661499023439, 46.04839190436442],
  [-85.1756286621094, 46.05887537686722],
  [-85.15777587890626, 46.05220430643751],
  [-85.13580322265625, 46.05125123058942],
  [-85.11657714843751, 46.040766310770024],
  [-85.09597778320314, 46.029325946739085],
  [-85.07125854492188, 46.028372476163476],
  [-85.03967285156253, 46.02074411945675],
  [-85.00808715820314, 46.00929961070771],
  [-84.98748779296875, 45.996898720157716],
  [-84.9613952636719, 45.978769341499195],
  [-84.93804931640628, 45.95777001121115],
  [-84.92019653320312, 45.94058281947719],
  [-84.91195678710939, 45.92625608858613],
  [-84.90646362304689, 45.92147968916408],
  [-84.88998413085938, 45.92625608858613],
  [-84.87350463867188, 45.91574746703005],
  [-84.86114501953128, 45.904281246220734],
  [-84.83917236328125, 45.89854724753791],
  [-84.83490452868867, 45.89090099477511],
  [-84.82941136462614, 45.87751751915494],
  [-84.81705174548553, 45.867955919701714],
  [-84.7991989622824, 45.869868371193995],
  [-84.79095921618865, 45.86030545572006],
  [-84.77447972400114, 45.85265393891232],
  [-84.76623997790739, 45.84500136927006],
  [-84.7497604857199, 45.83830450717324],
  [-84.73053441150115, 45.84500136927006],
  [-84.71542821032928, 45.84882778569605],
  [-84.69757542712615, 45.85169742528412],
  [-84.71130833728239, 45.86699966928009],
  [-84.71680150134493, 45.8784735886244],
  [-84.72641453845428, 45.89281265666472],
  [-84.73190770251676, 45.90714802349187],
  [-84.72916112048554, 45.9147920391015],
  [-84.71130833728239, 45.91956901425015],
  [-84.71954808337614, 45.930076912037556],
  [-84.73190770251676, 45.93771776938213],
  [-84.73053441150115, 45.951086736592785],
  [-84.72504124743867, 45.96445247984661],
  [-84.71542821032928, 45.969225178273604],
  [-84.70169530017304, 45.970179668613724],
  [-84.68796239001676, 45.9730430409437],
  [-84.68246922595429, 45.97972366735621],
  [-84.68658909900117, 45.987357682078546],
  [-84.68658909900117, 45.993082502301505],
  [-84.6838425169699, 46.00834579473627],
  [-84.6893356810324, 46.01883686580664],
  [-84.6838425169699, 46.03313966456968],
  [-84.67697606189176, 46.04362603171967],
  [-84.66049656970428, 46.05029813829448],
  [-84.64951024157926, 46.04839190436442],
  [-84.63303074939178, 46.04267280785007],
  [-84.62067113025114, 46.035999780247764],
  [-84.60556492907929, 46.02741898914063],
  [-84.58359227282926, 46.02551196575319],
  [-84.57260594470428, 46.03027940086749],
  [-84.55337987048556, 46.03027940086749],
  [-84.54102025134489, 46.023604876576584],
  [-84.53140721423553, 46.00166862240119],
  [-84.52866063220428, 45.9854492770867],
  [-84.53278050525114, 45.97208859994875],
  [-84.51904759509492, 45.97208859994875],
  [-84.51080784900115, 45.97495187358871],
  [-84.51080784900115, 45.97972366735621],
  [-84.51080784900115, 45.989266021278205],
  [-84.51080784900115, 45.99785273350206],
  [-84.49982152087617, 45.99785273350206],
  [-84.48608861071989, 45.99403658143743],
  [-84.47784886462614, 45.9854492770867],
  [-84.47235570056364, 45.971134142505434],
  [-84.46136937243865, 45.973997465490356],
  [-84.46411595446992, 45.99022016620601],
  [-84.46411595446992, 45.99976071084721],
  [-84.46411595446992, 46.01025341023155],
  [-84.45587620837615, 46.00834579473627],
  [-84.44077000720429, 46.00739196231715],
  [-84.43253026111053, 46.00834579473627],
  [-84.42429051501678, 46.00357646816399],
  [-84.40643773181365, 46.00357646816399],
  [-84.38995823962617, 45.996898720157716],
  [-84.38583836657928, 45.987357682078546],
  [-84.37210545642303, 45.97686064044045],
  [-84.3542526732199, 45.97208859994875],
  [-84.3542526732199, 45.9854492770867],
  [-84.35562596423551, 45.99403658143743],
  [-84.33914647204803, 45.993082502301505],
  [-84.32404027087617, 45.9883118599024],
  [-84.31168065173556, 45.986403487806626],
  [-84.30069432361054, 45.986403487806626],
  [-84.29932103259489, 45.996898720157716],
  [-84.28833470446993, 45.99212840671761],
  [-84.27460179431365, 45.9883118599024],
  [-84.2608688841574, 45.9854492770867],
  [-84.24026951892303, 45.98067797676505],
  [-84.22790989978239, 45.97972366735621],
  [-84.22104344470426, 45.98354080630241],
  [-84.19907078845429, 45.978769341499195],
  [-84.18945775134489, 45.97972366735621],
  [-84.18121800525114, 45.97972366735621],
  [-84.16473851306365, 45.97972366735621],
  [-84.13727269275114, 45.97972366735621],
  [-84.12079320056365, 45.98354080630241],
  [-84.09607396228239, 45.977814999193974],
  [-84.07135472400115, 45.969225178273604],
  [-84.04526219470428, 45.96349789081576],
  [-84.02054295642301, 45.960634025032036],
  [-84.00955662829803, 45.952996325870146],
  [-83.99582371814178, 45.950131917280956],
  [-83.99445042712614, 45.95777001121115],
  [-83.98621068103239, 45.96636160856278],
  [-83.9642380247824, 45.96827067148509],
  [-83.94638524157928, 45.969225178273604],
  [-83.93951878650114, 45.9730430409437],
  [-83.9257858763449, 45.97208859994875],
  [-83.90518651111051, 45.97495187358871],
  [-83.90518651111051, 45.98163226972562],
  [-83.89694676501678, 45.99212840671761],
  [-83.90106663806364, 45.99976071084721],
  [-83.90243992907928, 46.011207193307804],
  [-83.91754613025115, 46.015022161137175],
  [-83.92441258532926, 46.01883686580664],
  [-83.94501195056368, 46.023604876576584],
  [-83.95462498767303, 46.028372476163476],
  [-83.95737156970429, 46.021697721610714],
  [-83.9697311888449, 46.01979050085545],
  [-83.98071751696992, 46.02265130731734],
  [-83.98621068103239, 46.03123283854874],
  [-83.99994359118868, 46.044579239142344],
  [-84.00955662829803, 46.04743876272916],
  [-84.0191696654074, 46.05315736583898],
  [-84.03290257556365, 46.06268705528738],
  [-84.03976903064179, 46.07030962269674],
  [-84.05212864978242, 46.079836351788686],
  [-84.07135472400115, 46.09507569754773],
  [-84.06723485095426, 46.10459815068697],
  [-84.06311497790742, 46.11221492911446],
  [-84.05075535876678, 46.120782546770755],
  [-84.03702244861053, 46.12458994926315],
  [-84.0191696654074, 46.12839708863321],
  [-84.03427586657926, 46.138865365187314],
  [-84.04800877673553, 46.14267151786069],
  [-84.06311497790742, 46.148380253529076],
  [-84.0795944700949, 46.149331651917564],
  [-84.09744725329803, 46.149331651917564],
  [-84.10568699939179, 46.15599098018968],
  [-84.10568699939179, 46.16455178968454],
  [-84.11941990954801, 46.174062238032974],
  [-84.13177952868864, 46.170258256022926],
  [-84.14413914782928, 46.17501319242534],
  [-84.15100560290739, 46.17976771772811],
  [-84.15787205798551, 46.17501319242534],
  [-84.16473851306365, 46.18071857345693],
  [-84.17572484118867, 46.18357104198045],
  [-84.17572484118867, 46.17501319242534],
  [-84.18671116931368, 46.174062238032974],
  [-84.19509887695312, 46.1669922604376],
  [-84.20608520507815, 46.1669922604376],
  [-84.22531127929689, 46.1669922604376],
  [-84.2362976074219, 46.1669922604376],
  [-84.23904418945312, 46.17460041311367],
  [-84.24453735351562, 46.187912147928095],
  [-84.25552368164067, 46.19931964112396],
  [-84.22805786132814, 46.20692332111272],
  [-84.20333862304688, 46.241126858199145],
  [-84.17587280273439, 46.244925935797895],
  [-84.18960571289065, 46.26771487683373],
  [-84.2088317871094, 46.27910579592006],
  [-84.22256469726564, 46.299982998515375],
  [-84.22256469726564, 46.322749053259535],
  [-84.22256469726564, 46.339817379869864],
  [-84.22531127929689, 46.36067143443812],
  [-84.24179077148438, 46.37204301966532],
  [-84.25277709960938, 46.392884777778],
  [-84.26651000976562, 46.415612167198596],
  [-84.26925659179689, 46.438330088481734],
  [-84.28848266601564, 46.449685498688495],
  [-84.2912292480469, 46.47049760342009],
  [-84.30496215820312, 46.495083471058855],
  [-84.35165405273438, 46.496974231448355],
  [-84.38186645507814, 46.493192644927696],
  [-84.40658569335938, 46.493192644927696],
  [-84.4505310058594, 46.48184630755262],
  [-84.45327758789062, 46.464822363804664],
  [-84.45877075195315, 46.44400808944975],
  [-84.48348999023438, 46.43454442576159],
  [-84.49447631835938, 46.44400808944975],
  [-84.53018188476564, 46.42129253514273],
  [-84.55764770507814, 46.409931207495845],
  [-84.59060668945317, 46.40803742275868],
  [-84.59609985351565, 46.41939914491193],
  [-84.58786010742188, 46.43454442576159],
  [-84.58236694335938, 46.4534701094454],
  [-84.5988464355469, 46.464822363804664],
  [-84.62081909179688, 46.47428076777367],
  [-84.64553833007815, 46.48373752813864],
  [-84.70321655273439, 46.4780636691506],
  [-84.72793579101562, 46.4610385420017],
  [-84.76638793945314, 46.449685498688495],
  [-84.81307983398439, 46.44779309468955],
  [-84.8405456542969, 46.44779309468955],
  [-84.85153198242189, 46.466714176088146],
  [-84.8680114746094, 46.466714176088146],
  [-84.89822387695314, 46.466714176088146],
  [-84.92019653320312, 46.466714176088146],
  [-84.9339294433594, 46.48562868298163],
  [-84.9613952636719, 46.48373752813864],
  [-84.99710083007815, 46.48373752813864],
  [-85.02731323242192, 46.493192644927696],
  [-85.04104614257814, 46.506427047278436],
  [-85.04928588867189, 46.52721745699695],
  [-85.04928588867189, 46.54044357622674],
  [-85.03005981445312, 46.549888832158466],
  [-85.03005981445312, 46.559332444715295],
  [-85.03005981445312, 46.57632680610708],
  [-85.0328063964844, 46.59709046178544],
  [-85.0328063964844, 46.62916410443853],
  [-85.0328063964844, 46.655563430152014],
  [-85.03005981445312, 46.680065555620665],
  [-85.02182006835938, 46.702673044474004],
  [-85.0163269042969, 46.71773944647638],
  [-85.00259399414064, 46.73091909829098],
  [-84.97512817382812, 46.74785963371697],
  [-84.94216918945315, 46.761031926011036],
  [-84.94216918945315, 46.770438735006586],
  [-84.98611450195314, 46.76855750461982],
  [-85.0328063964844, 46.76291341922302],
  [-85.06576538085938, 46.75726874246586],
  [-85.09323120117189, 46.75726874246586],
  [-85.13992309570314, 46.76291341922302],
  [-85.17837524414062, 46.75726874246586],
  [-85.21408081054692, 46.753505296090644],
  [-85.23880004882814, 46.74974158688334],
  [-85.27725219726565, 46.744095530257496],
  [-85.30197143554689, 46.73091909829098],
  [-85.32669067382812, 46.72715381204664],
  [-85.35964965820312, 46.71209003858014],
  [-85.38986206054689, 46.70078944850857],
  [-85.43930053710938, 46.693254407490976],
  [-85.50521850585938, 46.67818117105438],
  [-85.53817749023439, 46.67818117105438],
  [-85.5931091308594, 46.67818117105438],
  [-85.61782836914064, 46.67818117105438],
  [-85.65353393554688, 46.67818117105438],
  [-85.69747924804692, 46.67818117105438],
  [-85.75241088867192, 46.680065555620665],
  [-85.78536987304689, 46.680065555620665],
  [-85.81832885742189, 46.68760243670695],
  [-85.85952758789064, 46.68948649268507],
  [-85.89797973632815, 46.68948649268507],
  [-85.9446716308594, 46.6706429756028],
  [-85.96389770507814, 46.66875826244199],
  [-85.99411010742192, 46.66687348356153],
  [-86.03805541992188, 46.66498863896117],
  [-86.08474731445312, 46.659333710838176],
  [-86.10946655273439, 46.659333710838176],
  [-86.14791870117189, 46.66875826244199],
  [-86.1863708496094, 46.65744860335566],
  [-86.21658325195315, 46.638593913825275],
  [-86.23855590820312, 46.62916410443853],
  [-86.28799438476565, 46.60275189664713],
  [-86.30172729492189, 46.593315843232865],
  [-86.32644653320312, 46.58576581737151],
  [-86.35116577148439, 46.57255074148127],
  [-86.38961791992189, 46.561220970024316],
  [-86.42532348632812, 46.55555519689568],
  [-86.46102905273438, 46.549888832158466],
  [-86.47476196289065, 46.542332758884484],
  [-86.48574829101565, 46.53288618823181],
  [-86.49673461914064, 46.5158782196006],
  [-86.53518676757812, 46.50075555500317],
  [-86.54617309570314, 46.48184630755262],
  [-86.57913208007815, 46.464822363804664],
  [-86.59561157226564, 46.449685498688495],
  [-86.62307739257815, 46.41939914491193],
  [-86.65878295898438, 46.409931207495845],
  [-86.66152954101562, 46.42129253514273],
  [-86.6532897949219, 46.44211548820838],
  [-86.67526245117188, 46.445900624943405],
  [-86.70822143554688, 46.445900624943405],
  [-86.72470092773439, 46.4610385420017],
  [-86.7466735839844, 46.47428076777367],
  [-86.76040649414062, 46.476172251334134],
  [-86.77963256835939, 46.476172251334134],
  [-86.79061889648439, 46.47049760342009],
  [-86.80709838867188, 46.4534701094454],
  [-86.81259155273442, 46.438330088481734],
  [-86.83456420898439, 46.4307585000463],
  [-86.8675231933594, 46.445900624943405],
  [-86.88949584960938, 46.45536231620382],
  [-86.93618774414062, 46.464822363804664],
  [-86.94168090820315, 46.47428076777367],
  [-86.95541381835938, 46.498864926096374],
  [-86.96365356445312, 46.5158782196006],
  [-86.99386596679688, 46.52721745699695],
  [-87.01858520507812, 46.52721745699695],
  [-87.0433044433594, 46.5158782196006],
  [-87.06527709960938, 46.510207713426645],
  [-87.09823608398438, 46.495083471058855],
  [-87.15866088867188, 46.493192644927696],
  [-87.19436645507817, 46.49130175305465],
  [-87.27676391601564, 46.49130175305465],
  [-87.34268188476567, 46.506427047278436],
  [-87.37564086914064, 46.525327748443345],
  [-87.40859985351564, 46.56688615155069],
  [-87.44155883789065, 46.60463891014406],
  [-87.48001098632812, 46.63482218724328],
  [-87.50747680664065, 46.66498863896117],
  [-87.52944946289062, 46.683834127599084],
  [-87.56240844726564, 46.721505389841674],
  [-87.58438110351567, 46.75162347434115],
  [-87.60635375976564, 46.79300837468438],
  [-87.63931274414065, 46.815568553214504],
  [-87.69424438476565, 46.83436147506294],
  [-87.72171020507812, 46.86066052871709],
  [-87.75466918945314, 46.88319232664616],
  [-87.78762817382815, 46.88319232664616],
  [-87.86453247070312, 46.898208270105194],
  [-87.93045043945312, 46.90571466534108],
  [-88.01284790039062, 46.90571466534108],
  [-88.09524536132814, 46.90196159909572],
  [-88.15567016601562, 46.88319232664616],
  [-88.21060180664062, 46.849391082387356],
  [-88.24905395507812, 46.86441648528394],
  [-88.22158813476564, 46.90571466534108],
  [-88.15017700195315, 46.909467468843616],
  [-88.15567016601562, 46.95448061777253],
  [-88.22708129882814, 46.93948043839813],
  [-88.28750610351564, 46.90196159909572],
  [-88.35342407226562, 46.86066052871709],
  [-88.41384887695312, 46.8118091804638],
  [-88.45779418945315, 46.80428964656367],
  [-88.46328735351564, 46.849391082387356],
  [-88.46328735351564, 46.879437683895816],
  [-88.46328735351564, 46.92447605547139],
  [-88.44680786132812, 46.95448061777253],
  [-88.42483520507814, 46.98821565290464],
  [-88.39187622070315, 46.99570943771265],
  [-88.35342407226562, 47.03316260094472],
  [-88.34243774414064, 47.05562189093554],
  [-88.34243774414064, 47.078071725611395],
  [-88.30947875976567, 47.09303302937347],
  [-88.30947875976567, 47.11172875008274],
  [-88.33145141601564, 47.13041790553795],
  [-88.38088989257814, 47.137891729520014],
  [-88.41934204101565, 47.14536450313261],
  [-88.46328735351564, 47.152836226396346],
  [-88.52371215820314, 47.171510939414695],
  [-88.57315063476564, 47.171510939414695],
  [-88.62258911132814, 47.171510939414695],
  [-88.69949340820315, 47.16777652195969],
  [-88.76541137695314, 47.14162824762116],
  [-88.82583618164064, 47.11920520007756],
  [-88.88076782226562, 47.07058949794366],
  [-88.89175415039065, 47.03316260094472],
  [-88.93569946289062, 47.01818448773633],
  [-88.99063110351562, 46.999455936085624],
  [-89.06204223632814, 46.98821565290464],
  [-89.10598754882814, 46.99570943771265],
  [-89.17190551757812, 46.95073096700378],
  [-89.19937133789064, 46.913220009605595],
  [-89.25979614257812, 46.87568277838665],
  [-89.34219360351564, 46.79676906145095],
  [-89.43008422851564, 46.76291341922302],
  [-89.46304321289062, 46.721505389841674],
  [-89.53445434570315, 46.67252762304416],
  [-89.58389282226564, 46.653678191226945],
  [-89.60037231445315, 46.62727794538748],
  [-89.64431762695314, 46.58199041005887],
  [-89.68826293945312, 46.54799991244305],
  [-89.71572875976562, 46.510207713426645],
  [-89.75418090820312, 46.48373752813864],
  [-89.75967407226567, 46.449685498688495],
  [-89.79263305664064, 46.40803742275868],
  [-89.79263305664064, 46.36256686303411],
  [-89.80361938476562, 46.30188053143883],
  [-89.80361938476562, 46.26771487683373],
  [-89.87503051757814, 46.23732751752227],
  [-89.91897583007814, 46.210724766468964],
  [-89.96841430664064, 46.19931964112396],
  [-89.99588012695312, 46.16889439727126],
  [-90.00137329101567, 46.127032192241984],
  [-90.02883911132815, 46.10418484314801],
  [-90.01785278320315, 46.043212267295],
  [-90.04531860351562, 45.997438626389744],
  [-90.08926391601562, 45.95544616516127],
  [-90.06179809570314, 45.901955158019945],
  [-90.10025024414065, 45.86371571258982],
  [-90.10574340820312, 45.8177936348495],
  [-90.09475708007817, 45.77566509989655],
  [-90.08377075195315, 45.73733879277785],
  [-90.06729125976564, 45.6913124767407],
  [-90.09475708007817, 45.63756719669653],
  [-90.11123657226565, 45.58761466314763],
  [-90.10574340820312, 45.53376986898195],
  [-90.09475708007817, 45.506828120969516],
  [-90.13320922851562, 45.46831753032875],
  [-90.11672973632814, 45.4220700672261],
  [-90.14419555664062, 45.391217361482845],
  [-90.17715454101565, 45.341045774001124],
  [-90.14968872070315, 45.29855813586015],
  [-90.17166137695312, 45.244436880573886],
  [-90.19912719726562, 45.20187683128089],
  [-90.19912719726562, 45.14378905322604],
  [-90.19363403320315, 45.089520339384045],
  [-90.19363403320315, 45.031318015886306],
  [-90.21560668945314, 44.96917023288548],
  [-90.23208618164062, 44.9147356245024],
  [-90.22659301757812, 44.852461445751175],
  [-90.23208618164062, 44.79011985665554],
  [-90.22109985351564, 44.71990499364751],
  [-90.24307250976565, 44.676953912213165],
  [-90.28152465820312, 44.63397097045481],
  [-90.31997680664062, 44.59486792195009],
  [-90.30899047851564, 44.53224827839475],
  [-90.34194946289065, 44.46172061276446],
  [-90.39688110351564, 44.38325649413709],
  [-90.40786743164064, 44.320409378877656],
  [-90.40786743164064, 44.28896054892438],
  [-90.47378540039062, 44.24175571484553],
  [-90.47927856445312, 44.21813908312183],
  [-90.46829223632812, 44.17087739135609],
  [-90.46279907226564, 44.147232331753735],
  [-90.51223754882815, 44.11174697584436],
  [-90.55618286132814, 44.0644000050782],
  [-90.58364868164065, 43.989356460945814],
  [-90.57266235351562, 43.93400042715015],
  [-90.55618286132814, 43.88651134774927],
  [-90.53970336914065, 43.82709671571044],
  [-90.54519653320314, 43.7755561494101],
  [-90.54519653320314, 43.716031017652014],
  [-90.55068969726564, 43.66439469287585],
  [-90.57266235351562, 43.628620426937914],
  [-90.61111450195314, 43.557007981627656],
  [-90.63858032226564, 43.5092190123469],
  [-90.61660766601562, 43.42948658186482],
  [-90.62759399414062, 43.377604102663],
  [-90.59463500976565, 43.3096907441499],
  [-90.61660766601562, 43.261706163373475],
  [-90.62210083007812, 43.20968015605925],
  [-90.62210083007812, 43.18164775501802],
  [-90.61660766601562, 43.13757082270372],
  [-90.62210083007812, 43.09346209534862],
  [-90.64407348632814, 43.03727773561135],
  [-90.61660766601562, 42.98506043912932],
  [-90.63858032226564, 42.92877678228986],
  [-90.64956665039064, 42.888542653363295],
  [-90.62210083007812, 42.84425478015878],
  [-90.62210083007812, 42.79590450075042],
  [-90.63858032226564, 42.73541348646227],
  [-90.63858032226564, 42.66274630684548],
  [-90.63858032226564, 42.614254278479216],
  [-90.66604614257812, 42.56167860586976],
  [-90.69351196289064, 42.52120560764628],
  [-90.64956665039064, 42.46044692255494],
  [-90.69351196289064, 42.43207267241209],
  [-90.66604614257812, 42.383401202818575],
  [-90.69351196289064, 42.3509325778528],
  [-90.68801879882815, 42.32250876543571],
  [-90.72097778320312, 42.30219817693161],
  [-90.70449829101564, 42.27375234246752],
  [-90.73196411132814, 42.24122708941077],
  [-90.72647094726565, 42.19240775778097],
  [-90.74295043945314, 42.16798394024223],
  [-90.70449829101564, 42.123182441295484],
  [-90.74844360351562, 42.0701943438771],
  [-90.73196411132814, 42.02940414432761],
  [-90.72097778320312, 41.996753126923714],
  [-90.74295043945314, 41.96000069865691],
  [-90.73745727539064, 41.886432216946986],
  [-90.76492309570312, 41.833246676287196],
  [-90.75393676757815, 41.780016905285514],
  [-90.73745727539064, 41.7185429899891],
  [-90.73745727539064, 41.66932147792169],
  [-90.70999145507812, 41.615955671950104],
  [-90.66604614257812, 41.55021401530998],
  [-90.63858032226564, 41.484405435611926],
  [-90.62210083007812, 41.44324109726409],
  [-90.52871704101565, 41.42264912910954],
  [-90.52322387695312, 41.36495687061056],
  [-90.48477172851565, 41.33196687858672],
  [-90.43533325195315, 41.32784195426919],
  [-90.42984008789067, 41.261807650073955],
  [-90.37490844726562, 41.22876543240591],
  [-90.27603149414064, 41.2205022674202],
  [-90.26504516601564, 41.17090135180694],
  [-90.18264770507812, 41.15022321163027],
  [-90.16067504882814, 41.092289714670926],
  [-90.10025024414065, 41.07986874098991],
  [-89.99038696289062, 41.07158678754193],
  [-89.96292114257815, 41.00943888076623],
  [-89.94644165039062, 40.97212197190468],
  [-89.88601684570314, 40.95967830900989],
  [-89.84756469726562, 40.91403147143875],
  [-89.77615356445315, 40.86004454780482],
  [-89.72671508789064, 40.85173495823771],
  [-89.64981079101562, 40.83095642445156],
  [-89.64981079101562, 40.73112880602221],
  [-89.50698852539065, 40.69365477446243],
  [-89.50698852539065, 40.63115119323153],
  [-89.45205688476565, 40.54772199417564],
  [-89.41909790039062, 40.47254674190491],
  [-89.43557739257814, 40.40983633607083],
  [-89.41909790039062, 40.3596258981446],
  [-89.36965942382817, 40.305189027180255],
  [-89.36965942382817, 40.24232269648897],
  [-89.31472778320312, 40.179397932816514],
  [-89.29275512695315, 40.08279985497752],
  [-89.22134399414062, 40.040757875802896],
  [-89.20486450195312, 39.99027326196773],
  [-89.15542602539064, 39.93975130761637],
  [-89.14993286132814, 39.88076184888241],
  [-89.11697387695315, 39.825940444829826],
  [-89.02359008789065, 39.792182435672274],
  [-89.02359008789065, 39.72461669561136],
  [-88.93020629882812, 39.733066037939444],
  [-88.88626098632817, 39.68658184173333],
  [-88.79837036132815, 39.69926245589766],
  [-88.73245239257812, 39.66544230870888],
  [-88.68301391601562, 39.63160560004501],
  [-88.62808227539062, 39.58928643027501],
  [-88.56765747070315, 39.55964762177082],
  [-88.43032836914064, 39.56388251310396],
  [-88.29849243164065, 39.60621720230201],
  [-88.22158813476564, 39.57658563548227],
  [-88.15017700195315, 39.5681171458385],
  [-88.03482055664064, 39.57235151996704],
  [-87.96340942382812, 39.5681171458385],
  [-87.91946411132817, 39.52999614229508],
  [-87.83157348632815, 39.538469286383034],
  [-87.75466918945314, 39.55964762177082],
  [-87.68875122070312, 39.57235151996704],
  [-87.63931274414065, 39.55964762177082],
  [-87.56790161132812, 39.538469286383034],
  [-87.45254516601565, 39.580819492376925],
  [-87.40859985351564, 39.580819492376925],
  [-87.32620239257812, 39.627374847167154],
  [-87.27676391601564, 39.57658563548227],
  [-87.21633911132814, 39.58928643027501],
  [-87.13943481445312, 39.58928643027501],
  [-87.06253051757815, 39.580819492376925],
  [-86.97738647460939, 39.56176509976175],
  [-86.94168090820315, 39.54482346574673],
  [-86.93344116210939, 39.510927787044295],
  [-86.88949584960938, 39.493973743303236],
  [-86.84555053710939, 39.49185419703965],
  [-86.83456420898439, 39.462173764858576],
  [-86.8180847167969, 39.43248067006646],
  [-86.77688598632812, 39.41126356545072],
  [-86.70547485351562, 39.42399460327164],
  [-86.6807556152344, 39.407019369506344],
  [-86.65878295898438, 39.381548769326415],
  [-86.6258239746094, 39.38579451509386],
  [-86.59561157226564, 39.36668662525671],
  [-86.55715942382814, 39.381548769326415],
  [-86.52420043945317, 39.364563203559946],
  [-86.49124145507814, 39.3454495031272],
  [-86.45004272460939, 39.334828521261365],
  [-86.4115905761719, 39.34120130403343],
  [-86.36764526367188, 39.3454495031272],
  [-86.3346862792969, 39.36243971730151],
  [-86.28799438476565, 39.37093327496134],
  [-86.24404907226565, 39.34969744400848],
  [-86.20285034179689, 39.36668662525671],
  [-86.18087768554692, 39.37305650296729],
  [-86.12319946289065, 39.364563203559946],
  [-86.06552124023439, 39.364563203559946],
  [-86.01333618164064, 39.364563203559946],
  [-85.97213745117189, 39.381548769326415],
  [-85.91720581054689, 39.37093327496134],
  [-85.87600708007812, 39.39428523176663],
  [-85.82656860351564, 39.37305650296729],
  [-85.79910278320317, 39.39216264945857],
  [-85.77713012695314, 39.381548769326415],
  [-85.75241088867192, 39.379425799588226],
  [-85.71945190429689, 39.39853020265668],
  [-85.69747924804692, 39.38579451509386],
  [-85.66726684570315, 39.38579451509386],
  [-85.62881469726564, 39.38791729112128],
  [-85.57937622070314, 39.407019369506344],
  [-85.53268432617189, 39.4133855665495],
  [-85.48599243164062, 39.39853020265668],
  [-85.47500610351564, 39.42399460327164],
  [-85.43380737304689, 39.407019369506344],
  [-85.40084838867188, 39.42399460327164],
  [-85.37612915039064, 39.407019369506344],
  [-85.34591674804688, 39.41126356545072],
  [-85.3239440917969, 39.43884454194957],
  [-85.27999877929688, 39.43460202528739],
  [-85.24703979492189, 39.419751182340946],
  [-85.2195739746094, 39.43035925025338],
  [-85.19760131835938, 39.44096570338884],
  [-85.16464233398438, 39.43672331591542],
  [-85.1261901855469, 39.43672331591542],
  [-85.08773803710939, 39.45581202472926],
  [-85.04653930664064, 39.462173764858576],
  [-85.03005981445312, 39.45581202472926],
  [-84.98611450195314, 39.44096570338884],
  [-84.94491577148439, 39.44096570338884],
  [-84.9339294433594, 39.46429421569262],
  [-84.88998413085938, 39.46641460192051],
  [-84.86251831054688, 39.46641460192051],
  [-84.83779907226565, 39.472775372958374],
  [-84.82406616210938, 39.47489550075251],
  [-84.80484008789064, 39.48549517054308],
  [-84.78836059570312, 39.472775372958374],
  [-84.76364135742189, 39.462173764858576],
  [-84.71694946289064, 39.460053249419396],
  [-84.71420288085938, 39.496093224947764],
  [-84.66751098632812, 39.47489550075251],
  [-84.64828491210938, 39.45793266937596],
  [-84.61532592773439, 39.470655180554346],
  [-84.58511352539062, 39.506689663850075],
  [-84.54666137695312, 39.496093224947764],
  [-84.51644897460939, 39.47913556250761],
  [-84.48348999023438, 39.472775372958374],
  [-84.43954467773438, 39.493973743303236],
  [-84.40933227539064, 39.50457050531535],
  [-84.36264038085939, 39.50245128215681],
  [-84.33242797851562, 39.50245128215681],
  [-84.28573608398438, 39.50245128215681],
  [-84.23904418945312, 39.517284487133914],
  [-84.21157836914062, 39.50033199437543],
  [-84.17312622070314, 39.493973743303236],
  [-84.14840698242188, 39.51940325790628],
  [-84.10995483398438, 39.510927787044295],
  [-84.09622192382815, 39.493973743303236],
  [-84.05776977539062, 39.49185419703965],
  [-84.02481079101562, 39.517284487133914],
  [-83.98086547851564, 39.538469286383034],
  [-83.9671325683594, 39.51940325790628],
  [-83.92593383789064, 39.50457050531535],
  [-83.88473510742189, 39.52999614229508],
  [-83.85177612304689, 39.52787769468348],
  [-83.81057739257812, 39.51940325790628],
  [-83.77487182617188, 39.52999614229508],
  [-83.75289916992189, 39.51940325790628],
  [-83.7254333496094, 39.51516565173217],
  [-83.6869812011719, 39.506689663850075],
  [-83.65676879882814, 39.506689663850075],
  [-83.61831665039062, 39.53635109731666],
  [-83.57711791992192, 39.540587410810765],
  [-83.5386657714844, 39.53211452527173],
  [-83.50296020507814, 39.53211452527173],
  [-83.47000122070312, 39.540587410810765],
  [-83.42330932617188, 39.55329479991475],
  [-83.38760375976565, 39.538469286383034],
  [-83.34640502929688, 39.540587410810765],
  [-83.29971313476562, 39.55964762177082],
  [-83.29147338867188, 39.538469286383034],
  [-83.25027465820315, 39.57235151996704],
  [-83.22830200195312, 39.55541247184639],
  [-83.18710327148442, 39.56599986179649],
  [-83.17062377929688, 39.59140300310023],
  [-83.14041137695317, 39.59140300310023],
  [-83.10470581054688, 39.57235151996704],
  [-83.06900024414064, 39.58505309064353],
  [-83.03878784179688, 39.57870259625761],
  [-82.98385620117188, 39.59140300310023],
  [-82.94265747070312, 39.612565174816254],
  [-82.92068481445314, 39.59775233360283],
  [-82.89596557617188, 39.58716979278919],
  [-82.86575317382814, 39.60198489728464],
  [-82.84652709960938, 39.593519511263864],
  [-82.81631469726562, 39.593519511263864],
  [-82.79983520507812, 39.62525937371226],
  [-82.75588989257812, 39.623143835581246],
  [-82.7201843261719, 39.62525937371226],
  [-82.70370483398442, 39.65275548355981],
  [-82.67074584960939, 39.650640786288825],
  [-82.62405395507814, 39.65275548355981],
  [-82.55264282226565, 39.65698468403725],
  [-82.50869750976562, 39.661213625756034],
  [-82.45376586914062, 39.6696707328887],
  [-82.41531372070317, 39.703488809634415],
  [-82.36587524414062, 39.749961615889575],
  [-82.33291625976562, 39.7752972152002],
  [-82.29995727539064, 39.821721600259394],
  [-82.26699829101562, 39.825940444829826],
  [-82.20108032226564, 39.834377356837365],
  [-82.13516235351565, 39.83859542426053],
  [-82.06924438476565, 39.84281323262067],
  [-82.01980590820314, 39.88076184888241],
  [-81.93191528320317, 39.884977066231485],
  [-81.88796997070312, 39.884977066231485],
  [-81.81106567382814, 39.88919202444144],
  [-81.71768188476564, 39.90183534416827],
  [-81.62979125976564, 39.91026292816486],
  [-81.56387329101564, 39.91026292816486],
  [-81.49795532226562, 39.92290236029078],
  [-81.44851684570317, 39.93975130761637],
  [-81.37710571289064, 39.956596107293834],
  [-81.30020141601562, 39.97764627359865],
  [-81.23428344726564, 39.96080665909224],
  [-81.18484497070314, 39.95238529624027],
  [-81.15188598632812, 39.95238529624027],
  [-81.06948852539062, 40.01131305630903],
  [-80.96511840820317, 40.02814022365228],
  [-80.89920043945314, 40.02814022365228],
  [-80.83877563476564, 40.023933820854076],
  [-80.71243286132815, 40.03655225113337],
  [-80.59707641601564, 40.03655225113337],
  [-80.53115844726562, 40.03655225113337],
  [-80.44326782226565, 40.040757875802896],
  [-80.34439086914062, 40.04496324108295],
  [-80.28945922851562, 40.10381111538506],
  [-80.25650024414065, 40.18359473454377],
  [-80.17959594726564, 40.21296507712462],
  [-80.14663696289067, 40.26747624192569],
  [-80.10269165039062, 40.284240077494196],
  [-80.07522583007814, 40.3135667885186],
  [-79.98184204101562, 40.422383097039905],
  [-79.96536254882814, 40.48508180254747],
  [-80.00930786132812, 40.535198637933945],
  [-80.04226684570315, 40.56024300905358],
  [-80.06423950195312, 40.60196281341105],
  [-80.09170532226562, 40.65615965408625],
  [-80.10269165039062, 40.71447628470931],
  [-80.11367797851564, 40.75609977566358],
  [-80.14663696289067, 40.80601358609007],
  [-80.17959594726564, 40.83095642445156],
  [-80.20156860351565, 40.86835309505012],
  [-80.21255493164064, 40.91818248731055],
  [-80.20156860351565, 40.963826457359396],
  [-80.19058227539062, 41.0177286587949],
  [-80.18508911132812, 41.07158678754193],
  [-80.18508911132812, 41.18330510423471],
  [-80.19058227539062, 41.23289662333081],
  [-80.24002075195317, 41.261807650073955],
  [-80.26748657226564, 41.29483315820069],
  [-80.30593872070312, 41.33196687858672],
  [-80.32791137695314, 41.36083403578614],
  [-80.33889770507812, 41.4020506290836],
  [-80.36636352539064, 41.430886700228896],
  [-80.37734985351565, 41.50497780464056],
  [-80.38284301757812, 41.554324829679274],
  [-80.34439086914062, 41.57898422585703],
  [-80.36087036132814, 41.615955671950104],
  [-80.39932250976562, 41.677527678945395],
  [-80.40481567382815, 41.739040835793865],
  [-80.41580200195314, 41.79640000673744],
  [-80.36087036132814, 41.8537078883212],
  [-80.36087036132814, 41.919139792195],
  [-80.43777465820312, 42.000835420718914],
  [-80.60256958007814, 41.94365947797709],
  [-80.76736450195315, 41.902788098873515],
  [-80.9239196777344, 41.86189054099241],
  [-81.03378295898439, 41.83733944214672],
  [-81.12991333007815, 41.80458998739727],
  [-81.20407104492188, 41.76977534084381],
  [-81.27822875976565, 41.75953214107033],
  [-81.31668090820315, 41.743139620108494],
  [-81.36611938476562, 41.71444263601197],
  [-81.41555786132814, 41.68778395896666],
  [-81.45126342773438, 41.65906225544114],
  [-81.48147583007814, 41.63648621227495],
  [-81.50619506835938, 41.62211552048467],
  [-81.54739379882812, 41.60568795028219],
  [-81.56936645507814, 41.59131039443244],
  [-81.58859252929688, 41.56665570423205],
  [-81.62429809570314, 41.546102939522335],
  [-81.66549682617189, 41.52348735004112],
  [-81.72592163085939, 41.492635167290615],
  [-81.78909301757812, 41.48852043212605],
  [-81.83853149414067, 41.48234783935022],
  [-81.90444946289064, 41.48852043212605],
  [-81.95388793945314, 41.5070346821719],
  [-82.01431274414064, 41.51114824120619],
  [-82.0884704589844, 41.492635167290615],
  [-82.14614868164064, 41.48029017775285],
  [-82.19009399414065, 41.451476055470145],
  [-82.23953247070312, 41.430886700228896],
  [-82.2917175292969, 41.424708619868895],
  [-82.33566284179689, 41.41852995163519],
  [-82.38235473632814, 41.4020506290836],
  [-82.42630004882812, 41.38968839401562],
  [-82.4784851074219, 41.38968839401562],
  [-82.52517700195315, 41.39174892980349],
  [-82.55264282226565, 41.408230864902784],
  [-82.58560180664064, 41.412350695543594],
  [-82.61032104492192, 41.42264912910954],
  [-82.64328002929689, 41.43500509386452],
  [-82.68997192382814, 41.44324109726409],
  [-82.71194458007815, 41.44735870701869],
  [-82.7476501464844, 41.44735870701869],
  [-82.80258178710938, 41.45765158819394],
  [-82.83004760742189, 41.451476055470145],
  [-82.86300659179688, 41.43706419269923],
  [-82.91519165039065, 41.424708619868895],
  [-82.94815063476562, 41.43294592970754],
  [-82.98934936523438, 41.43912322621111],
  [-83.02780151367189, 41.43912322621111],
  [-83.02780151367189, 41.45765158819394],
  [-83.0058288574219, 41.46382653295957],
  [-82.98110961914064, 41.46794283615212],
  [-82.96463012695314, 41.48029017775285],
  [-82.9508972167969, 41.490577832377326],
  [-82.91519165039065, 41.490577832377326],
  [-82.86026000976565, 41.496749641100934],
  [-82.82730102539064, 41.5008638535525],
  [-82.80532836914067, 41.5008638535525],
  [-82.76138305664062, 41.5070346821719],
  [-82.72293090820314, 41.5173180896782],
  [-82.7201843261719, 41.531712115661925],
  [-82.76962280273438, 41.533768143694914],
  [-82.83554077148438, 41.531712115661925],
  [-82.91519165039065, 41.519374575145434],
  [-82.96463012695314, 41.5173180896782],
  [-83.0058288574219, 41.54404730359805],
  [-83.04702758789067, 41.57898422585703],
  [-83.07998657226564, 41.597472596288675],
  [-83.12393188476562, 41.615955671950104],
  [-83.15414428710939, 41.624168672581796],
  [-83.19534301757815, 41.62211552048467],
  [-83.22006225585939, 41.63032773662242],
  [-83.22830200195312, 41.64264409952472],
  [-83.2475280761719, 41.661114230707746],
  [-83.30245971679689, 41.67957906572997],
  [-83.34365844726565, 41.68368164312976],
  [-83.38485717773438, 41.6959878059345],
  [-83.42605590820312, 41.70214000452562],
  [-83.46725463867188, 41.691886013236356],
  [-83.46725463867188, 41.70829161455628],
  [-83.45352172851564, 41.73289216878347],
  [-83.47824096679688, 41.73084248230531],
  [-83.47000122070312, 41.75748330488168],
  [-83.43978881835938, 41.7656782571705],
  [-83.43978881835938, 41.78206502192826],
  [-83.43978881835938, 41.79640000673744],
  [-83.42330932617188, 41.82096680841674],
  [-83.40133666992189, 41.84552418869496],
  [-83.36837768554689, 41.87007214667565],
  [-83.3518981933594, 41.89869952106349],
  [-83.33541870117192, 41.9109644691261],
  [-83.31893920898438, 41.92935747337991],
  [-83.30795288085939, 41.939573518226915],
  [-83.27224731445312, 41.939573518226915],
  [-83.25302124023438, 41.949787926673366],
  [-83.25576782226564, 41.97225386481823],
  [-83.2475280761719, 41.984504674276074],
  [-83.22555541992188, 41.99267057124887],
  [-83.20632934570314, 42.01512138664375],
  [-83.19534301757815, 42.04164395443229],
  [-83.18710327148442, 42.06611650263103],
  [-83.18435668945312, 42.094655890282816],
  [-83.16238403320314, 42.098731897803056],
  [-83.14041137695317, 42.115033308085856],
  [-83.13766479492188, 42.14355068927746],
  [-83.14041137695317, 42.184267533475655],
  [-83.14041137695317, 42.21478796951036],
  [-83.14041137695317, 42.23512673690766],
  [-83.11569213867189, 42.24935997537791],
  [-83.0827331542969, 42.3042295307175],
  [-83.04977416992188, 42.3184471720729],
  [-82.99758911132812, 42.33063116562982],
  [-82.94815063476562, 42.3468728197949],
  [-82.83004760742189, 42.377314613944506],
  [-82.72842407226564, 42.45842061661181],
  [-82.68997192382814, 42.5110832589007],
  [-82.64053344726562, 42.54751604081074],
  [-82.61856079101565, 42.56370156708076],
  [-82.60208129882812, 42.54953946116451],
  [-82.58010864257815, 42.553586105099654],
  [-82.56912231445315, 42.56774729272214],
  [-82.55538940429688, 42.57988289537318],
  [-82.5224304199219, 42.61021157035975],
  [-82.50320434570314, 42.63446388282484],
  [-82.50320434570314, 42.672844056034904],
  [-82.47573852539064, 42.72129041756775],
  [-82.4784851074219, 42.73137865204421],
  [-82.45925903320312, 42.76163351153994],
  [-82.4784851074219, 42.8120254615352],
  [-82.45925903320312, 42.87042872752173],
  [-82.45925903320312, 42.89055498353176],
  [-82.4510192871094, 42.90665126134546],
  [-82.43453979492189, 42.93883121091809],
  [-82.40432739257815, 42.981041885223775],
  [-82.43179321289065, 43.00715779023924],
  [-82.44003295898439, 43.02723939568957],
  [-82.4674987792969, 43.069389389453065],
  [-82.48672485351564, 43.09747329341403],
  [-82.49496459960939, 43.13556657029558],
  [-82.51419067382812, 43.17764207509921],
  [-82.52792358398438, 43.25370505337167],
  [-82.5334167480469, 43.31168928024891],
  [-82.5334167480469, 43.36362815136275],
  [-82.54165649414064, 43.40554634348621],
  [-82.54165649414064, 43.42749192352222],
  [-82.54440307617189, 43.45740489664407],
  [-82.58285522460939, 43.54108253290259],
  [-82.59384155273438, 43.57889860295371],
  [-82.60482788085938, 43.624644193359174],
  [-82.60757446289062, 43.66836829614057],
  [-82.61306762695312, 43.73587930502765],
  [-82.61306762695312, 43.75968856858633],
  [-82.63504028320314, 43.82511520841795],
  [-82.64328002929689, 43.84888895466104],
  [-82.65975952148438, 43.87067323445261],
  [-82.67623901367189, 43.89047021826108],
  [-82.68173217773439, 43.90630306901634],
  [-82.70645141601562, 43.9419115897792],
  [-82.71743774414064, 43.96563876212758],
  [-82.73666381835939, 43.9873803478874],
  [-82.75039672851562, 44.0031874102159],
  [-82.78884887695312, 44.01306468595375],
  [-82.81906127929689, 44.03676343687805],
  [-82.83554077148438, 44.03873790513037],
  [-82.87124633789064, 44.05058333295965],
  [-82.89321899414062, 44.052557340648974],
  [-82.91793823242189, 44.06242639216298],
  [-82.9344177246094, 44.06242639216298],
  [-82.94540405273442, 44.06045271345168],
  [-82.96463012695314, 44.06045271345168],
  [-82.99484252929688, 44.04663512019559],
  [-83.02230834960939, 44.03873790513037],
  [-83.04977416992188, 44.0110893623901],
  [-83.06625366210939, 43.99726025527434],
  [-83.08822631835938, 43.99726025527434],
  [-83.12393188476562, 43.99528440537827],
  [-83.15963745117189, 43.983427924400125],
  [-83.20907592773439, 43.98145161397166],
  [-83.2585144042969, 43.97156907498665],
  [-83.27499389648439, 43.9419115897792],
  [-83.2969665527344, 43.92213170963767],
  [-83.3244323730469, 43.920153359804736],
  [-83.3518981933594, 43.920153359804736],
  [-83.37112426757815, 43.920153359804736],
  [-83.38760375976565, 43.92608821194918],
  [-83.39309692382812, 43.90828187933754],
  [-83.34640502929688, 43.90828187933754],
  [-83.32717895507812, 43.89442882564507],
  [-83.3409118652344, 43.87463315746137],
  [-83.35739135742189, 43.85879188670803],
  [-83.38211059570317, 43.854830911225235],
  [-83.38485717773438, 43.833040842930224],
  [-83.41506958007814, 43.80133199043724],
  [-83.42880249023442, 43.77753930104483],
  [-83.44802856445314, 43.749769193052295],
  [-83.47549438476567, 43.73191017368391],
  [-83.49197387695315, 43.710075249008575],
  [-83.52218627929688, 43.72198619440862],
  [-83.52767944335938, 43.710075249008575],
  [-83.55789184570314, 43.6763147135459],
  [-83.60183715820314, 43.638559860152576],
  [-83.6320495605469, 43.606747887385055],
  [-83.67050170898442, 43.59083558861119],
  [-83.70071411132812, 43.60475908017313],
  [-83.7254333496094, 43.62066769675857],
  [-83.74740600585939, 43.632596397492996],
  [-83.78585815429689, 43.642535173141106],
  [-83.8243103027344, 43.642535173141106],
  [-83.85726928710938, 43.654459533921504],
  [-83.89572143554688, 43.65843379478089],
  [-83.9176940917969, 43.67234163636449],
  [-83.9286804199219, 43.704118888483805],
  [-83.94515991210939, 43.74183250878847],
  [-83.94515991210939, 43.76563940467282],
  [-83.9286804199219, 43.77753930104483],
  [-83.9176940917969, 43.78943682964686],
  [-83.91494750976562, 43.81322478337459],
  [-83.90945434570312, 43.84294640609329],
  [-83.90945434570312, 43.86473285658867],
  [-83.90670776367188, 43.892449554844156],
  [-83.89572143554688, 43.920153359804736],
  [-83.87649536132812, 43.94784427099419],
  [-83.84628295898439, 43.96959236982231],
  [-83.83255004882814, 43.989356460945814],
  [-83.81607055664062, 43.989356460945814],
  [-83.78311157226562, 43.989356460945814],
  [-83.74465942382814, 43.99133250821387],
  [-83.72817993164062, 44.001211757693305],
  [-83.71170043945312, 43.99726025527434],
  [-83.68423461914064, 43.999236039379404],
  [-83.6869812011719, 44.01503994372519],
  [-83.66775512695312, 44.03478890283157],
  [-83.64578247070314, 44.052557340648974],
  [-83.6320495605469, 44.052557340648974],
  [-83.61007690429689, 44.052557340648974],
  [-83.59085083007814, 44.04860925947513],
  [-83.57437133789062, 44.05453128254301],
  [-83.57986450195312, 44.084132515414545],
  [-83.5661315917969, 44.093996303179054],
  [-83.56063842773438, 44.113718943810696],
  [-83.56063842773438, 44.149203115302264],
  [-83.55789184570314, 44.182696367769395],
  [-83.54415893554689, 44.220107497705754],
  [-83.53591918945315, 44.24962581956623],
  [-83.51943969726565, 44.26142900238289],
  [-83.48648071289062, 44.27322981605989],
  [-83.46450805664065, 44.2791293344632],
  [-83.44253540039064, 44.2751963880046],
  [-83.4452819824219, 44.24962581956623],
  [-83.41232299804688, 44.2771628941391],
  [-83.40133666992189, 44.29879011810966],
  [-83.38760375976565, 44.31451400659744],
  [-83.36563110351562, 44.32826895387169],
  [-83.34640502929688, 44.32826895387169],
  [-83.32992553710938, 44.342020676298326],
  [-83.32717895507812, 44.3714777935801],
  [-83.31893920898438, 44.3969953168216],
  [-83.31893920898438, 44.416616611940874],
  [-83.31893920898438, 44.44211445584185],
  [-83.31893920898438, 44.457799907934785],
  [-83.30245971679689, 44.49111750693034],
  [-83.30245971679689, 44.52049959138877],
  [-83.30795288085939, 44.54595208497531],
  [-83.31069946289064, 44.57726295996623],
  [-83.31069946289064, 44.596823699714406],
  [-83.30245971679689, 44.620287898534244],
  [-83.29421997070315, 44.64374261847816],
  [-83.28048706054688, 44.661327437863605],
  [-83.27224731445312, 44.68867100323748],
  [-83.26675415039064, 44.70819422207882],
  [-83.27224731445312, 44.717953362956266],
  [-83.28598022460938, 44.72771085813057],
  [-83.29421997070315, 44.739417680004166],
  [-83.2969665527344, 44.75502308943305],
  [-83.29421997070315, 44.77062428581209],
  [-83.28872680664067, 44.78622126912216],
  [-83.28872680664067, 44.79791624176749],
  [-83.28872680664067, 44.811557381269715],
  [-83.29421997070315, 44.827143305192756],
  [-83.30795288085939, 44.84467243360177],
  [-83.31893920898438, 44.862196229752904],
  [-83.30795288085939, 44.87387579814654],
  [-83.31619262695314, 44.88166086046243],
  [-83.33267211914062, 44.887498965976505],
  [-83.34640502929688, 44.887498965976505],
  [-83.36563110351562, 44.88944486948625],
  [-83.38485717773438, 44.903064350790615],
  [-83.40133666992189, 44.91084546325572],
  [-83.41506958007814, 44.918625522424925],
  [-83.43154907226562, 44.93612680443821],
  [-83.43978881835938, 44.9516790230432],
  [-83.4452819824219, 44.96917023288548],
  [-83.4452819824219, 44.992483551267654],
  [-83.44802856445314, 45.00607860920865],
  [-83.4342956542969, 45.0080204970178],
  [-83.43978881835938, 45.0157873899414],
  [-83.44802856445314, 45.02549452489164],
  [-83.44802856445314, 45.04296322043138],
  [-83.44253540039064, 45.0526657471931],
  [-83.42330932617188, 45.06236662817483],
  [-83.39584350585939, 45.07206586337762],
  [-83.37661743164062, 45.07400551292483],
  [-83.36288452148439, 45.07206586337762],
  [-83.34915161132814, 45.0526657471931],
  [-83.3244323730469, 45.033259047888656],
  [-83.28872680664067, 45.02549452489164],
  [-83.27224731445312, 45.029376918052684],
  [-83.27224731445312, 45.03908174890801],
  [-83.28048706054688, 45.04878493398207],
  [-83.31344604492189, 45.05072537350321],
  [-83.31893920898438, 45.06042658364084],
  [-83.31893920898438, 45.07012614799934],
  [-83.31893920898438, 45.08176345280282],
  [-83.29971313476562, 45.09339838768867],
  [-83.31619262695314, 45.10890794766983],
  [-83.31619262695314, 45.12635116659009],
  [-83.32168579101564, 45.14185177358866],
  [-83.34640502929688, 45.15153751347067],
  [-83.36288452148439, 45.163158228926385],
  [-83.37661743164062, 45.190264015431914],
  [-83.39309692382812, 45.20961739191955],
  [-83.40408325195314, 45.230898502744424],
  [-83.41506958007814, 45.252171648239454],
  [-83.4178161621094, 45.27150395939559],
  [-83.38760375976565, 45.279235040661646],
  [-83.4178161621094, 45.29662612256588],
  [-83.43978881835938, 45.3043537807767],
  [-83.47824096679688, 45.323668318378665],
  [-83.49472045898438, 45.342976273269684],
  [-83.51119995117189, 45.348767376116],
  [-83.55514526367189, 45.35069761207847],
  [-83.59085083007814, 45.36420742068031],
  [-83.61831665039062, 45.36806677356038],
  [-83.64028930664065, 45.37578468941034],
  [-83.69522094726564, 45.39893211771302],
  [-83.72817993164062, 45.41050227731152],
  [-83.76113891601565, 45.41050227731152],
  [-83.81057739257812, 45.42592547060359],
  [-83.84902954101562, 45.449052361668066],
  [-83.89022827148439, 45.47120673790688],
  [-83.946533203125, 45.495277708287695],
  [-84.01931762695315, 45.495277708287695],
  [-84.05776977539062, 45.495277708287695],
  [-84.10171508789062, 45.49912810913339],
  [-84.11270141601565, 45.522224985238346]
]);
export const polyChargeStroke = turf.bezierSpline(line);
