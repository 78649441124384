import * as turf from "@turf/turf";

const line = turf.lineString([
  [-86.297607421875, 43.16512263158296],
  [-86.2646484375, 43.12103377575541],
  [-86.253662109375, 43.092960677116295],
  [-86.23168945312499, 43.052833917627936],
  [-86.2261962890625, 42.98053954751642],
  [-86.220703125, 42.896088552971065],
  [-86.220703125, 42.81555136172695],
  [-86.209716796875, 42.74701217318067],
  [-86.209716796875, 42.70262285884388],
  [-86.2261962890625, 42.64608143458068],
  [-86.2371826171875, 42.55712670332118],
  [-86.2481689453125, 42.47209690919285],
  [-86.2811279296875, 42.407234661551875],
  [-86.253662109375, 42.36260292171998],
  [-86.209716796875, 42.33012354634199],
  [-86.1383056640625, 42.334184385939416],
  [-86.0723876953125, 42.32606244456202],
  [-86.0614013671875, 42.24478535602799],
  [-86.0504150390625, 42.18375873465217],
  [-85.9954833984375, 42.134894984239224],
  [-85.93505859374999, 42.134894984239224],
  [-85.869140625, 42.094146370922736],
  [-85.8306884765625, 42.02889410108475],
  [-85.7757568359375, 42.0125705565935],
  [-85.73181152343749, 42.04929263868686],
  [-85.6768798828125, 42.05745022024682],
  [-85.6439208984375, 42.00848901572399],
  [-85.5780029296875, 42.00848901572399],
  [-85.50659179687499, 41.97582726102573],
  [-85.462646484375, 41.97582726102573],
  [-85.39672851562499, 41.98807738309159],
  [-85.3912353515625, 42.05337156043361],
  [-85.31982421875, 42.08599350447723],
  [-85.2978515625, 42.04113400940807],
  [-85.242919921875, 42.04113400940807],
  [-85.1824951171875, 42.04113400940807],
  [-85.1385498046875, 42.04113400940807],
  [-85.0836181640625, 42.08599350447723],
  [-85.05615234375, 42.05745022024682],
  [-85.0286865234375, 42.09822241118974],
  [-84.990234375, 42.06968462804663],
  [-84.9517822265625, 42.094146370922736],
  [-84.9188232421875, 42.0615286181226],
  [-84.8638916015625, 42.09007006868398],
  [-84.847412109375, 42.05337156043361],
  [-84.7650146484375, 42.037054301883806],
  [-84.715576171875, 42.08599350447723],
  [-84.649658203125, 42.05745022024682],
  [-84.61669921875, 42.09007006868398],
  [-84.561767578125, 42.08599350447723],
  [-84.5343017578125, 42.10229818948117],
  [-84.48486328124999, 42.10637370579324],
  [-84.4573974609375, 42.094146370922736],
  [-84.4464111328125, 42.13082130188811],
  [-84.4134521484375, 42.13082130188811],
  [-84.375, 42.12674735753131],
  [-84.33654785156249, 42.15525946577863],
  [-84.3145751953125, 42.18375873465217],
  [-84.3145751953125, 42.22851735620852],
  [-84.3804931640625, 42.24071874922669],
  [-84.4024658203125, 42.26511445833756],
  [-84.4024658203125, 42.30575300304638],
  [-84.407958984375, 42.35042512243457],
  [-84.407958984375, 42.37883631647602],
  [-84.35302734375, 42.39506551565123],
  [-84.3145751953125, 42.415346114253616],
  [-84.3145751953125, 42.44372793752476],
  [-84.26513671875, 42.44778143462245],
  [-84.232177734375, 42.459940352216556],
  [-84.232177734375, 42.49235259142821],
  [-84.24316406249999, 42.524748042348165],
  [-84.254150390625, 42.56117285531808],
  [-84.26513671875, 42.593532625649935],
  [-84.26513671875, 42.61374895431491],
  [-84.254150390625, 42.62991729384457],
  [-84.2156982421875, 42.62991729384457],
  [-84.14978027343749, 42.62991729384457],
  [-84.1058349609375, 42.65820178455667],
  [-84.13330078125, 42.70262285884388],
  [-84.14978027343749, 42.73087427928485],
  [-84.1552734375, 42.767178634023345],
  [-84.1278076171875, 42.79943131987838],
  [-84.100341796875, 42.85583308674896],
  [-84.08935546875, 42.896088552971065],
  [-84.111328125, 42.92827401776914],
  [-84.09484863281249, 42.94838139765314],
  [-84.078369140625, 42.976520698105524],
  [-84.0673828125, 43.01669737169671],
  [-84.09484863281249, 43.08493742707592],
  [-84.1387939453125, 43.104993581605505],
  [-84.1607666015625, 43.11702412135048],
  [-84.2156982421875, 43.16111586765961],
  [-84.2376708984375, 43.197167282501276],
  [-84.276123046875, 43.23319741022136],
  [-84.2816162109375, 43.265206318396],
  [-84.30908203125, 43.31318813919643],
  [-84.2706298828125, 43.337164854911094],
  [-84.2486572265625, 43.35314407444698],
  [-84.2156982421875, 43.369119087738554],
  [-84.2047119140625, 43.389081939117496],
  [-84.2047119140625, 43.4249985081581],
  [-84.2376708984375, 43.44494295526125],
  [-84.276123046875, 43.44893105587766],
  [-84.3145751953125, 43.44893105587766],
  [-84.33654785156249, 43.46886761482923],
  [-84.3475341796875, 43.50075243569041],
  [-84.30908203125, 43.51270490464819],
  [-84.287109375, 43.54456658436357],
  [-84.3035888671875, 43.56845179881218],
  [-84.342041015625, 43.58834891179792],
  [-84.375, 43.620170616189895],
  [-84.4024658203125, 43.64005063334696],
  [-84.42993164062499, 43.65197548731187],
  [-84.44091796875, 43.683763524273346],
  [-84.4189453125, 43.71950494269107],
  [-84.39147949218749, 43.74332071724287],
  [-84.385986328125, 43.76315996157264],
  [-84.4354248046875, 43.775060351224695],
  [-84.4793701171875, 43.79885402720353],
  [-84.451904296875, 43.82263823180498],
  [-84.42993164062499, 43.8543357707896],
  [-84.4464111328125, 43.91372326852401],
  [-84.451904296875, 43.94141717295212],
  [-84.4793701171875, 43.96909818325171],
  [-84.5233154296875, 43.98886243884903],
  [-84.561767578125, 44.01652134387754],
  [-84.5892333984375, 44.03232064275084],
  [-84.627685546875, 44.040218713142146],
  [-84.66064453125, 44.06390660801779],
  [-84.6990966796875, 44.10730980734024],
  [-84.6990966796875, 44.15068115978094],
  [-84.7210693359375, 44.18220395771566],
  [-84.7869873046875, 44.18220395771566],
  [-84.825439453125, 44.213709909702054],
  [-84.8638916015625, 44.24519901522129],
  [-84.90234375, 44.272738162790894],
  [-84.891357421875, 44.31205742666618],
  [-84.8968505859375, 44.34742225636393],
  [-84.90234375, 44.402391829093915],
  [-84.9462890625, 44.42593442145313],
  [-84.9737548828125, 44.44554600843547],
  [-85.0067138671875, 44.469071224701096],
  [-84.9847412109375, 44.51609322284931],
  [-84.9737548828125, 44.5435052132082],
  [-84.9847412109375, 44.6061127451739],
  [-85.001220703125, 44.66083904265621],
  [-85.0341796875, 44.68427737181225],
  [-85.089111328125, 44.67255939212045],
  [-85.1220703125, 44.69599298172069],
  [-85.198974609375, 44.72332018895825],
  [-85.2593994140625, 44.72332018895825],
  [-85.286865234375, 44.70770622183535],
  [-85.352783203125, 44.70380207177485],
  [-85.39672851562499, 44.69989765840318],
  [-85.462646484375, 44.66865287227321],
  [-85.50659179687499, 44.64911632343077],
  [-85.53955078125, 44.61393394730626],
  [-85.594482421875, 44.610023477890515],
  [-85.616455078125, 44.629573191951046],
  [-85.63842773437499, 44.653024159812],
  [-85.69335937499999, 44.680371641890375],
  [-85.726318359375, 44.65693173288727],
  [-85.792236328125, 44.66474608911831],
  [-85.84167480468749, 44.69599298172069],
  [-85.89111328125, 44.66865287227321],
  [-85.9515380859375, 44.66865287227321],
  [-85.9954833984375, 44.67646564865964],
  [-86.02294921875, 44.645208223744035],
  [-86.08337402343749, 44.6334823448553],
  [-86.1163330078125, 44.645208223744035],
  [-86.165771484375, 44.62175409623324],
  [-86.2042236328125, 44.6061127451739],
  [-86.2042236328125, 44.56699093657141],
  [-86.220703125, 44.5435052132082],
  [-86.2261962890625, 44.51609322284931],
  [-86.2371826171875, 44.453388800301774],
  [-86.2371826171875, 44.39846714225848],
  [-86.2261962890625, 44.35920579433503],
  [-86.27014160156249, 44.351350365612326],
  [-86.28662109375, 44.315987905196906],
  [-86.341552734375, 44.25700308645885],
  [-86.3800048828125, 44.190082025040525],
  [-86.451416015625, 44.11519787660427],
  [-86.517333984375, 44.071800467511565],
  [-86.50634765625, 44.02837121279199],
  [-86.4788818359375, 43.99281450048989],
  [-86.4459228515625, 43.94537239244209],
  [-86.4349365234375, 43.89789239125797],
  [-86.4349365234375, 43.8503744993026],
  [-86.4459228515625, 43.79488907226601],
  [-86.46240234375, 43.75919263886012],
  [-86.5008544921875, 43.70759350405294],
  [-86.5338134765625, 43.644025847699496],
  [-86.5283203125, 43.60823944964323],
  [-86.5008544921875, 43.55651037504758],
  [-86.4678955078125, 43.50075243569041],
  [-86.4404296875, 43.44494295526125],
  [-86.4129638671875, 43.37710501700073],
  [-86.3690185546875, 43.28520334369384],
  [-86.32507324218749, 43.23319741022136],
  [-86.297607421875, 43.16512263158296]
]);

export const homeBaseRangeStroke = turf.bezierSpline(line);
