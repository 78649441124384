import { useEffect } from "react";
import { CheckSquare, Square } from "react-feather";
import {
  chargeMode,
  chargeModes,
  homeChargingOption,
  homeChargingOptions
} from "../interfaces/ChargeMode";
import IconFastCharging from "./IconFastCharging";
import IconFordCharging from "./IconFordCharging";
import IconFordChargingPro from "./IconFordChargingPro";
import IconStandardCharging from "./IconStandardCharging";

interface ChargingPanelProps {
  visible: boolean;
  selectedChargeMode: chargeMode;
  updatedChargeMode: (id: number) => void;
  selectedHomeChargingOption: homeChargingOption;
  updatedHomeChargingOption: (id: number) => void;
  selectedChargingStationFilterFree: boolean;
  selectedChargingStationFilterBOCN: boolean;

  selectedChargingStationFilterHighSpeed: boolean;
  toggledChargingStationFilterFree: () => void;
  toggledChargingStationFilterBOCN: () => void;

  toggledChargingStationFilterHighSpeed: () => void;
}

function ChargingPanel(props: ChargingPanelProps) {
  if (props.visible) {
    return (
      <div className="mt-8">
        <div className="w-full mb-6 rounded-sm bg-white h-12 flex items-center justify-around cursor-pointer shadow-lg hover:shadow-xl transition-shadow ease-in duration-200">
          {chargeModes.map((m: chargeMode) => {
            return (
              <div
                className={`${
                  props.selectedChargeMode.id === m.id
                    ? "bg-primary text-white"
                    : ""
                } w-full h-full flex items-center justify-center`}
                onClick={() => {
                  if (m.id === props.selectedChargeMode.id) {
                    return;
                  } else {
                    props.updatedChargeMode(m.id);
                    if (m.id === 1) {
                      props.updatedHomeChargingOption(1);
                    }
                  }
                }}
                key={m.id}
              >
                {m.name}
              </div>
            );
          })}
        </div>
        {props.selectedChargeMode.id === 1 ? (
          <>
            <h1 className="text-2xl">Charging at home</h1>
            <p className="text-lg mt-2 text-gray-400 font-light">
              Just plug in at the end of your day, and every morning you're
              charged. Select an option to see your range.
            </p>
            {homeChargingOptions
              .filter((o: homeChargingOption) => {
                // Filter out the Pro if the Mach E is selected

                return true;
              })
              .map((c) => {
                return (
                  <div
                    key={c.id}
                    onClick={() => {
                      if (!props.selectedHomeChargingOption) {
                        props.updatedHomeChargingOption(c.id);
                      } else if (props.selectedHomeChargingOption.id === c.id) {
                        props.updatedHomeChargingOption(null);
                      } else {
                        props.updatedHomeChargingOption(c.id);
                      }
                    }}
                    className={`${
                      props.selectedHomeChargingOption &&
                      props.selectedHomeChargingOption.id === c.id
                        ? "border-primary"
                        : "border-white"
                    } border-2 bg-white cursor-pointer shadow-lg hover:shadow-xl transition-shadow ease-in duration-200 rounded-sm px-5 py-3 my-5 grid grid-cols-2 gap-4`}
                  >
                    <div className="flex items-center justify-start my-2">
                      <div className="flex-shrink-0">
                        {c.id === 1 ? (
                          <IconStandardCharging />
                        ) : c.id === 2 ? (
                          <IconFastCharging />
                        ) : c.id === 3 ? (
                          <IconFordCharging />
                        ) : (
                          <IconFordChargingPro />
                        )}
                      </div>

                      <div className="flex flex-col ml-4">
                        <div className="text-2xl font-bold">{c.power}</div>
                        <div className="text-sm text-gray-400">
                          {c.description}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-center">
                      <div className="text-2xl font-bold">
                        {c.milesChargedOvernight}
                      </div>
                      <div className="text-sm text-right text-gray-400">
                        Miles charged overnight (one-way)
                      </div>
                    </div>
                  </div>
                );
              })}
            <div
              onClick={() => {
                // props.didProceed();
              }}
              className="rounded-sm mt-6 bg-primary flex items-center justify-center py-3 text-white cursor-pointer shadow-lg hover:shadow-xl transition-shadow duration-200 ease-in"
            >
              Order Ford Connected Charge Station
            </div>
          </>
        ) : (
          <>
            <h1 className="text-2xl text-primary mb-4">
              Over 75,000 chargers, ready to help you keep moving.
            </h1>

            <p className="text-lg text-gray-400 font-light">
              See where the Blue Oval Charge Network can take you.
            </p>

            <div
              onClick={props.toggledChargingStationFilterBOCN}
              className="flex items-center my-4 cursor-pointer bg-primary text-white rounded-full py-2 px-4"
            >
              {props.selectedChargingStationFilterBOCN ? (
                <CheckSquare className="mr-3" />
              ) : (
                <Square className="mr-3" />
              )}
              Blue Oval Charge Network
            </div>
            <div
              onClick={props.toggledChargingStationFilterFree}
              className="flex flex-start items-center cursor-pointer bg-green-400 text-green-900 rounded-full py-2 px-4"
            >
              {props.selectedChargingStationFilterFree ? (
                <CheckSquare className="mr-3" />
              ) : (
                <Square className="mr-3" />
              )}
              Free chargers
            </div>

            <div
              className="flex items-start cursor-pointer mb-5 mt-6 px-5 border-t pt-5"
              onClick={props.toggledChargingStationFilterHighSpeed}
            >
              {props.selectedChargingStationFilterHighSpeed ? (
                <CheckSquare className="mr-3" />
              ) : (
                <Square className="mr-3" />
              )}{" "}
              <div className="flex items-center w-full">
                <div className="flex flex-col items-start w-full">
                  <div className="w-full flex justify-between items-center">
                    <div>Ultra fast</div>
                    <div className="text-sm opacity-50 ml-4">DC 150 kW</div>
                  </div>
                  <div className="text-xs text-gray-400">
                    Full charge 45 minutes and $15
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={() => {
                // props.didProceed();
              }}
              className="rounded-sm mt-12 bg-primary flex items-center justify-center py-3 text-white cursor-pointer shadow-lg hover:shadow-xl transition-shadow duration-200 ease-in"
            >
              Enroll today →
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ChargingPanel;
