import * as turf from "@turf/turf";

const line = turf.lineString([
  [-85.70159912109375, 43.389081939117496],
  [-85.73455810546875, 43.37311218382002],
  [-85.77301025390625, 43.37710501700073],
  [-85.78399658203125, 43.35713822211053],
  [-85.84167480468749, 43.32717570677798],
  [-85.88287353515625, 43.329173667843904],
  [-85.9075927734375, 43.29919735147067],
  [-85.9625244140625, 43.279204926082784],
  [-86.00372314453125, 43.279204926082784],
  [-86.033935546875, 43.2512044908875],
  [-86.05316162109375, 43.23319741022136],
  [-86.11083984375, 43.22319117678931],
  [-86.12182617187499, 43.205175817237304],
  [-86.1492919921875, 43.1811470593997],
  [-86.1932373046875, 43.159112387154174],
  [-86.20697021484375, 43.1270477646888],
  [-86.21520996093749, 43.08493742707592],
  [-86.22344970703124, 43.05484087957652],
  [-86.22344970703124, 43.030753001428344],
  [-86.209716796875, 42.99862111927107],
  [-86.2042236328125, 42.97250158602597],
  [-86.20697021484375, 42.93430692117159],
  [-86.209716796875, 42.88401467044253],
  [-86.21246337890625, 42.8215952943695],
  [-86.21246337890625, 42.76919491914051],
  [-86.21246337890625, 42.73289174571287],
  [-86.187744140625, 42.68041629144619],
  [-86.1492919921875, 42.67839711889055],
  [-86.099853515625, 42.67637788071963],
  [-86.0888671875, 42.63597933867727],
  [-86.0723876953125, 42.64204079304426],
  [-86.04766845703125, 42.60768474453004],
  [-86.01470947265625, 42.61577022637093],
  [-85.9844970703125, 42.595554553719204],
  [-85.95977783203125, 42.56521874494338],
  [-85.9295654296875, 42.56724159136565],
  [-85.88836669921874, 42.559149812115876],
  [-85.88012695312499, 42.524748042348165],
  [-85.80596923828125, 42.532844281713125],
  [-85.75927734375, 42.52879629320373],
  [-85.71533203125, 42.51665075361143],
  [-85.6878662109375, 42.500453028125584],
  [-85.63568115234375, 42.51665075361143],
  [-85.65216064453125, 42.55510352893436],
  [-85.616455078125, 42.53486817758702],
  [-85.56976318359375, 42.54093947168063],
  [-85.50659179687499, 42.56117285531808],
  [-85.48736572265625, 42.58746644784856],
  [-85.43243408203125, 42.59151063198149],
  [-85.38848876953125, 42.58746644784856],
  [-85.3363037109375, 42.61172761665585],
  [-85.32257080078125, 42.633958722673164],
  [-85.286865234375, 42.62587560259137],
  [-85.24566650390625, 42.64608143458068],
  [-85.2044677734375, 42.66224137632748],
  [-85.20172119140624, 42.68647341541784],
  [-85.1605224609375, 42.71069600569497],
  [-85.1605224609375, 42.77322729247907],
  [-85.14129638671875, 42.82360980730198],
  [-85.14129638671875, 42.85583308674896],
  [-85.14678955078125, 42.91419494510531],
  [-85.17425537109375, 42.97049193148626],
  [-85.15777587890625, 42.98857645832184],
  [-85.18524169921875, 43.02472955416351],
  [-85.22918701171875, 43.07691312608711],
  [-85.27587890625, 43.100982876188546],
  [-85.33905029296875, 43.111009147075116],
  [-85.38848876953125, 43.12303850442337],
  [-85.41595458984375, 43.1450861841603],
  [-85.38299560546875, 43.15109780818096],
  [-85.37200927734375, 43.16912913272099],
  [-85.374755859375, 43.19116019158773],
  [-85.40496826171874, 43.193162620926095],
  [-85.4132080078125, 43.201171681272456],
  [-85.41046142578125, 43.213183300738876],
  [-85.4241943359375, 43.249203966977845],
  [-85.45440673828125, 43.26720631662829],
  [-85.4571533203125, 43.2872026848044],
  [-85.4681396484375, 43.315186560290485],
  [-85.4681396484375, 43.33316939281732],
  [-85.49285888671875, 43.34715236003303],
  [-85.52581787109375, 43.36312895068202],
  [-85.57525634765625, 43.36712244111069],
  [-85.61370849609375, 43.369119087738554],
  [-85.65216064453125, 43.36712244111069],
  [-85.67962646484375, 43.369119087738554]
]);
export const poly30Stroke = turf.bezierSpline(line);
