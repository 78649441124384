import { useState } from "react";
import { Range } from "react-range";

function DrivingSpeedSlider(props: any) {
  const initialSpeed = 60;
  const step = 5;

  const [speed, setSpeed] = useState<number>(initialSpeed);

  return (
    <div className="mt-10">
      <div className="flex items-center mb-5">
        <h3 className="text-xl mr-2">
          Driving speed <span className="opacity-50 ml-1 text-sm">mph</span>
        </h3>
      </div>
      <div>
        <Range
          step={step}
          min={10}
          max={100}
          values={[speed]}
          onChange={(values) => setSpeed(values[0])}
          renderMark={({ props }) => (
            <div
              {...props}
              className="bg-slate-300"
              style={{
                ...props.style,
                height: "16px",
                width: "1px"
              }}
            />
          )}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="bg-white border-slate-200 border shadow-lg cursor-pointer rounded-sm w-full h-4"
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="rounded-full flex items-center justify-center bg-white border-2 h-10 w-10 text-sm font-medium border-primary shadow-lg hover:shadow-xl"
            >
              {speed}
            </div>
          )}
        />
        <div className="text-gray-600 text-sm mt-4">
          <div
            className={`${
              speed > 65 ? "opacity-100" : "opacity-0"
            } transition-opacity duration-150 ease-in flex items-center justify-center`}
          >
            High speeds may reduce range.
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrivingSpeedSlider;
