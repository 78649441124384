import React, { useEffect, useRef, useState } from "react";
interface LocationSearchBoxProps {
  focused: boolean;
  visible: boolean;
  onClick: () => void;
  value: string;
  addressOptionHidden: boolean;
}
function LocationSearchBox(props: LocationSearchBoxProps) {
  const [addressOptionHidden, setAddressOptionHidden] = useState(
    props.addressOptionHidden
  );
  const [addressSelected, setAddressSelected] = useState(false);
  const locationInputRef = useRef<HTMLInputElement>(null);
  const addressOption = "555 Locust Ave SE, Grand Rapids, MI 49506";

  function handleAddressFocus(e: any) {
    setAddressOptionHidden(false);
  }

  function handleOptionSelect(e: any) {
    e.preventDefault();
    locationInputRef.current.value = addressOption;
    setAddressOptionHidden(true);
    setAddressSelected(true);
    props.onClick();
  }

  useEffect(() => {
    locationInputRef.current.value = props.value;
  }, [props.value]);

  useEffect(() => {
    if (props.focused) {
      locationInputRef.current.focus();
    }
  }, [props.focused]);
  if (props.visible) {
    return (
      <>
        <input
          ref={locationInputRef}
          className={`draw mt-4 rounded px-2 py-3 w-full z-0 text-base ${
            addressSelected
              ? "bg-white border-slate-200 border"
              : "bg-slate-200"
          }`}
          value={props.value}
          placeholder="Enter your address"
          onFocus={handleAddressFocus}
        ></input>
        <div
          className="bg-white px-2 py-3 z-10  mt-1 shadow-lg"
          hidden={addressOptionHidden}
        >
          <div
            onClick={handleOptionSelect}
            className="cursor-pointer text-base hover:text-black"
          >
            {addressOption}
          </div>
        </div>
      </>
    );
  }
}

export default LocationSearchBox;
