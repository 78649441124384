import { useState } from "react";
import { Range } from "react-range";
interface CabinTemperatureSliderProps {}

function CabinTemperatureSlider(props: CabinTemperatureSliderProps) {
  const initialCabinTemp = 70;

  const insideStep = 5;

  const [cabinTemperature, setCabinTemperature] =
    useState<number>(initialCabinTemp);

  return (
    <div>
      <h3 className="text-xl mr-2 mt-5 mb-5">
        Cabin temperature <span className="opacity-50 ml-1 text-sm">°F</span>
      </h3>
      <Range
        step={insideStep}
        min={60}
        max={80}
        values={[cabinTemperature]}
        onChange={(values) => setCabinTemperature(values[0])}
        renderMark={({ props, index }) => (
          <div
            {...props}
            className={`${
              index * insideStep < cabinTemperature
                ? "bg-slate-300"
                : "bg-slate-200"
            } ${index === 0 ? "hidden" : ""} ${index === 5 ? "hidden" : ""}`}
            style={{
              ...props.style,
              height: "16px",
              width: "1px"
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="bg-white border-slate-200 border shadow-lg cursor-pointer rounded-sm w-full h-4"
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className="rounded-full flex items-center justify-center bg-white border-2 h-10 w-10 text-sm font-medium border-primary shadow-lg hover:shadow-xl"
          >
            {cabinTemperature}°
          </div>
        )}
      />
      <div className="text-blue-300 text-sm mt-3">
        <div
          className={`${
            cabinTemperature < 70 ? "opacity-100" : "opacity-0"
          } transition-opacity duration-150 ease-in flex items-center justify-center`}
        >
          Use of air conditioning may shorten range
        </div>
      </div>
    </div>
  );
}

export default CabinTemperatureSlider;
