import * as React from "react";
import { SVGProps } from "react";

const IconStandardCharging = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={63}
    fill="none"
    {...props}
  >
    <mask id="a" fill="#fff">
      <rect width={41} height={63} rx={2} />
    </mask>
    <rect
      width={41}
      height={63}
      rx={2}
      stroke="#003478"
      strokeWidth={5}
      mask="url(#a)"
    />
    <path
      d="m15.207 16.844-.025-6.805 1.821-.007.026 6.805-1.822.007ZM23.709 16.812l-.026-6.805L25.505 10l.025 6.805-1.821.007ZM20.38 19.813c-1.759.006-2.803 1.372-2.795 3.654l.002.456 5.617-.022-.001-.455c-.009-2.282-1.064-3.64-2.823-3.633ZM15.32 46.755l-.025-6.805 1.821-.007.026 6.805-1.822.007ZM23.822 46.723l-.026-6.805 1.822-.007.025 6.805-1.821.007ZM20.493 49.724c-1.759.006-2.803 1.372-2.795 3.654l.002.455 5.617-.02-.002-.457c-.008-2.281-1.063-3.64-2.822-3.633Z"
      fill="#003478"
    />
  </svg>
);

export default IconStandardCharging;
