import * as turf from "@turf/turf";

const line = turf.lineString([
  [-86.19873046875, 44.55133484083592],
  [-86.24267578125, 44.49650533109345],
  [-86.253662109375, 44.44162421758805],
  [-86.253662109375, 44.41024041296011],
  [-86.2646484375, 44.37098696297173],
  [-86.297607421875, 44.308126684886126],
  [-86.3360595703125, 44.25700308645885],
  [-86.37451171875, 44.209772586984485],
  [-86.4239501953125, 44.15068115978094],
  [-86.484375, 44.08363928284644],
  [-86.51184082031249, 44.040218713142146],
  [-86.495361328125, 43.98886243884903],
  [-86.451416015625, 43.94141717295212],
  [-86.4459228515625, 43.89393401411192],
  [-86.429443359375, 43.8503744993026],
  [-86.429443359375, 43.8186748554532],
  [-86.45690917968749, 43.76712702120528],
  [-86.4898681640625, 43.72744458647464],
  [-86.50634765625, 43.70362249839005],
  [-86.5283203125, 43.644025847699496],
  [-86.5283203125, 43.61221676817573],
  [-86.5008544921875, 43.55651037504758],
  [-86.47338867187499, 43.50872101129684],
  [-86.4459228515625, 43.476840397778936],
  [-86.4349365234375, 43.43696596521823],
  [-86.4239501953125, 43.389081939117496],
  [-86.4019775390625, 43.345154990451114],
  [-86.37451171875, 43.30119623257966],
  [-86.341552734375, 43.229195113965005],
  [-86.3031005859375, 43.1811470593997],
  [-86.27014160156249, 43.113014204188914],
  [-86.2371826171875, 43.08092540794885],
  [-86.209716796875, 43.02071359427862],
  [-86.2042236328125, 42.944360446966314],
  [-86.2042236328125, 42.88401467044253],
  [-86.19873046875, 42.79540065303723],
  [-86.19873046875, 42.72280375732727],
  [-86.2261962890625, 42.67435857693381],
  [-86.23168945312499, 42.62587560259137],
  [-86.24267578125, 42.54498667313236],
  [-86.24267578125, 42.48019996901214],
  [-86.2646484375, 42.407234661551875],
  [-86.3031005859375, 42.32606244456202],
  [-86.3470458984375, 42.26511445833756],
  [-86.3909912109375, 42.21224516288584],
  [-86.429443359375, 42.17561739661684],
  [-86.4898681640625, 42.11859868281563],
  [-86.517333984375, 42.06968462804663],
  [-86.5338134765625, 42.01665183556825],
  [-86.572265625, 41.95540515378059],
  [-86.6162109375, 41.88592102814744],
  [-86.66015624999999, 41.84501267270689],
  [-86.7095947265625, 41.82045509614034],
  [-86.7645263671875, 41.775408403663285],
  [-86.890869140625, 41.72213058512578],
  [-86.8798828125, 41.6770148220322],
  [-86.868896484375, 41.65649719441145],
  [-86.8304443359375, 41.65239288426814],
  [-86.7645263671875, 41.66470503009207],
  [-86.7425537109375, 41.6195489884308],
  [-86.68212890625, 41.60722821271717],
  [-86.627197265625, 41.623655390686395],
  [-86.56677246093749, 41.644183479397455],
  [-86.5338134765625, 41.611335399441735],
  [-86.484375, 41.611335399441735],
  [-86.451416015625, 41.6154423246811],
  [-86.4404296875, 41.566141964768384],
  [-86.4404296875, 41.529141988723104],
  [-86.396484375, 41.49212083968776],
  [-86.341552734375, 41.47566020027821],
  [-86.297607421875, 41.45507852101139],
  [-86.24267578125, 41.47566020027821],
  [-86.19873046875, 41.48800607185427],
  [-86.15478515625, 41.48800607185427],
  [-86.12182617187499, 41.49623534616764],
  [-86.08337402343749, 41.49623534616764],
  [-86.044921875, 41.49623534616764],
  [-86.011962890625, 41.48800607185427],
  [-85.9295654296875, 41.46742831254425],
  [-85.8966064453125, 41.46742831254425],
  [-85.858154296875, 41.42625319507272],
  [-85.8251953125, 41.42625319507272],
  [-85.7757568359375, 41.44272637767212],
  [-85.73181152343749, 41.40565583808169],
  [-85.6439208984375, 41.36856413680967],
  [-85.594482421875, 41.35619553438905],
  [-85.5615234375, 41.352072144512924],
  [-85.517578125, 41.380930388318],
  [-85.484619140625, 41.40153558289846],
  [-85.4681396484375, 41.422134246213616],
  [-85.418701171875, 41.41389556467733],
  [-85.3692626953125, 41.380930388318],
  [-85.3253173828125, 41.347948493443546],
  [-85.2703857421875, 41.372686481864655],
  [-85.242919921875, 41.39741506646459],
  [-85.177001953125, 41.393294288784865],
  [-85.1385498046875, 41.36856413680967],
  [-85.10009765625, 41.347948493443546],
  [-85.0341796875, 41.36031866306708],
  [-85.0177001953125, 41.39741506646459],
  [-84.9298095703125, 41.380930388318],
  [-84.8858642578125, 41.343824581185686],
  [-84.8309326171875, 41.36031866306708],
  [-84.814453125, 41.393294288784865],
  [-84.759521484375, 41.38917324986403],
  [-84.72656249999999, 41.36444153054222],
  [-84.67163085937499, 41.343824581185686],
  [-84.627685546875, 41.36444153054222],
  [-84.5892333984375, 41.40153558289846],
  [-84.5452880859375, 41.41389556467733],
  [-84.5068359375, 41.372686481864655],
  [-84.42993164062499, 41.38917324986403],
  [-84.4189453125, 41.422134246213616],
  [-84.375, 41.40153558289846],
  [-84.3255615234375, 41.39741506646459],
  [-84.2926025390625, 41.37680856570233],
  [-84.2486572265625, 41.41801503608024],
  [-84.210205078125, 41.38917324986403],
  [-84.144287109375, 41.393294288784865],
  [-84.08935546875, 41.42625319507272],
  [-84.0838623046875, 41.47154438707647],
  [-84.0728759765625, 41.51269075845857],
  [-84.034423828125, 41.545589036668105],
  [-83.9849853515625, 41.545589036668105],
  [-83.91357421875, 41.545589036668105],
  [-83.8751220703125, 41.57847058443442],
  [-83.86962890625, 41.623655390686395],
  [-83.82568359375, 41.66060124302088],
  [-83.7322998046875, 41.66060124302088],
  [-83.6553955078125, 41.66470503009207],
  [-83.594970703125, 41.7180304600481],
  [-83.6279296875, 41.79998325207397],
  [-83.6444091796875, 41.84910468610387],
  [-83.6279296875, 41.88592102814744],
  [-83.60595703125, 41.92271616673922],
  [-83.6004638671875, 41.95540515378059],
  [-83.5894775390625, 42.00032514831621],
  [-83.5894775390625, 42.02481360781777],
  [-83.5894775390625, 42.0615286181226],
  [-83.6444091796875, 42.10229818948117],
  [-83.66638183593749, 42.13082130188811],
  [-83.671875, 42.17154633452751],
  [-83.69384765625, 42.232584749313325],
  [-83.7103271484375, 42.28950073090457],
  [-83.7432861328125, 42.334184385939416],
  [-83.75976562499999, 42.370720143531976],
  [-83.7652587890625, 42.39912215986002],
  [-83.7982177734375, 42.459940352216556],
  [-83.8092041015625, 42.50450285299051],
  [-83.836669921875, 42.54498667313236],
  [-83.8421630859375, 42.601619944327965],
  [-83.84765625, 42.65012181368022],
  [-83.8201904296875, 42.69454866207692],
  [-83.7872314453125, 42.7349091465156],
  [-83.78173828125, 42.779275360241904],
  [-83.7762451171875, 42.82360980730198],
  [-83.770751953125, 42.87596410238256],
  [-83.78173828125, 42.92827401776914],
  [-83.78173828125, 42.956422511073335],
  [-83.7542724609375, 42.99259451971113],
  [-83.7872314453125, 43.04881979669318],
  [-83.7982177734375, 43.104993581605505],
  [-83.8201904296875, 43.141078106345866],
  [-83.858642578125, 43.201171681272456],
  [-83.84765625, 43.27720532212024],
  [-83.84765625, 43.31718491566705],
  [-83.86962890625, 43.35713822211053],
  [-83.8916015625, 43.389081939117496],
  [-83.9300537109375, 43.4249985081581],
  [-83.968505859375, 43.48481212891603],
  [-83.968505859375, 43.520671902437606],
  [-83.9959716796875, 43.55252937447483],
  [-84.012451171875, 43.57641143300888],
  [-84.034423828125, 43.60426186809618],
  [-84.0728759765625, 43.624147145668076],
  [-84.05639648437499, 43.671844983221604],
  [-84.0728759765625, 43.71950494269107],
  [-84.0838623046875, 43.75919263886012],
  [-84.0838623046875, 43.80678314779554],
  [-84.0838623046875, 43.8543357707896],
  [-84.05639648437499, 43.88205730390537],
  [-84.0509033203125, 43.91372326852401],
  [-84.0673828125, 43.94537239244209],
  [-84.0838623046875, 43.97305156068593],
  [-84.09484863281249, 44.01257086123085],
  [-84.078369140625, 44.04811573082351],
  [-84.111328125, 44.08758502824516],
  [-84.1278076171875, 44.11125397357155],
  [-84.14978027343749, 44.138855767568785],
  [-84.14978027343749, 44.17826452922576],
  [-84.1168212890625, 44.209772586984485],
  [-84.0838623046875, 44.25306865928177],
  [-84.078369140625, 44.327777761284445],
  [-84.111328125, 44.36313311380771],
  [-84.144287109375, 44.402391829093915],
  [-84.122314453125, 44.4377021634654],
  [-84.1168212890625, 44.476910857223224],
  [-84.13330078125, 44.54742015866826],
  [-84.1717529296875, 44.56699093657141],
  [-84.1937255859375, 44.60220174915696],
  [-84.210205078125, 44.62566377574355],
  [-84.210205078125, 44.67255939212045],
  [-84.2156982421875, 44.727223022457416],
  [-84.2376708984375, 44.758436211143476],
  [-84.26513671875, 44.797428998555645],
  [-84.276123046875, 44.824708282300236],
  [-84.276123046875, 44.86365630540611],
  [-84.2376708984375, 44.890904253917085],
  [-84.19921875, 44.929807512153914],
  [-84.1717529296875, 44.953136827528816],
  [-84.144287109375, 44.97645666320777],
  [-84.144287109375, 45.01530198999212],
  [-84.17724609375, 45.042478050891546],
  [-84.19921875, 45.07352060670971],
  [-84.22119140625, 45.116176603574864],
  [-84.232177734375, 45.158800738352106],
  [-84.26513671875, 45.166547157856016],
  [-84.29809570312499, 45.19752230305682],
  [-84.320068359375, 45.251688256117646],
  [-84.29809570312499, 45.286481972782816],
  [-84.276123046875, 45.31739181570156],
  [-84.2706298828125, 45.34828480683999],
  [-84.276123046875, 45.37144349133922],
  [-84.30908203125, 45.386877348270374],
  [-84.35302734375, 45.398449976304086],
  [-84.35302734375, 45.433153642271385],
  [-84.30908203125, 45.463983441272724],
  [-84.29809570312499, 45.487094732298374],
  [-84.2706298828125, 45.521743896993634],
  [-84.2706298828125, 45.57944511437787],
  [-84.287109375, 45.61019487586737],
  [-84.3145751953125, 45.644768217751924],
  [-84.3695068359375, 45.64092778836502],
  [-84.42993164062499, 45.63324613981234],
  [-84.48486328124999, 45.65628792636447],
  [-84.5343017578125, 45.69083283645816],
  [-84.583740234375, 45.71001523943372],
  [-84.63317871093749, 45.74069339553309],
  [-84.7100830078125, 45.75602615586017],
  [-84.7760009765625, 45.77135470445036],
  [-84.803466796875, 45.75219336063106],
  [-84.8419189453125, 45.73685954736049],
  [-84.88037109375, 45.729191061299915],
  [-84.92431640625, 45.7176863579072],
  [-84.96826171874999, 45.69083283645816],
  [-85.0067138671875, 45.663966415824056],
  [-85.0341796875, 45.644768217751924],
  [-85.067138671875, 45.625563438215956],
  [-85.089111328125, 45.58713413436409],
  [-85.10009765625, 45.56406391514301],
  [-85.10009765625, 45.533288879467456],
  [-85.089111328125, 45.50249699389715],
  [-85.078125, 45.47939202177826],
  [-85.067138671875, 45.4524242413431],
  [-85.0341796875, 45.440862671781744],
  [-84.9957275390625, 45.42544355958042],
  [-84.9517822265625, 45.42158812329091],
  [-84.90234375, 45.42158812329091],
  [-84.7979736328125, 45.4177324237046],
  [-84.825439453125, 45.398449976304086],
  [-84.88037109375, 45.394592696926615],
  [-84.9298095703125, 45.38301927899065],
  [-84.979248046875, 45.37144349133922],
  [-85.0341796875, 45.36372498305678],
  [-85.078125, 45.36372498305678],
  [-85.133056640625, 45.36758436884978],
  [-85.1715087890625, 45.37144349133922],
  [-85.24841308593749, 45.34056313889858],
  [-85.2978515625, 45.30580259943578],
  [-85.36376953125, 45.27488643704891],
  [-85.3857421875, 45.24395342262324],
  [-85.3692626953125, 45.213003555993964],
  [-85.374755859375, 45.14717913418674],
  [-85.374755859375, 45.116176603574864],
  [-85.35827636718749, 45.08515722028692],
  [-85.35827636718749, 45.05412098425883],
  [-85.36376953125, 45.01141864227728],
  [-85.3802490234375, 44.968684437948376],
  [-85.4022216796875, 44.92202896709906],
  [-85.418701171875, 44.87533557756195],
  [-85.45166015624999, 44.84029065139799],
  [-85.484619140625, 44.797428998555645],
  [-85.51895141601562, 44.759411351746245],
  [-85.54779052734375, 44.745757885700236],
  [-85.572509765625, 44.762336674810996],
  [-85.56564331054688, 44.790607161582656],
  [-85.55740356445312, 44.813018740612776],
  [-85.54641723632812, 44.82957822522573],
  [-85.528564453125, 44.84223815129917],
  [-85.50521850585938, 44.85100108620399],
  [-85.49697875976562, 44.87144275016589],
  [-85.49285888671875, 44.88701247981298],
  [-85.49011230468749, 44.90841397875737],
  [-85.49011230468749, 44.92397370210939],
  [-85.48599243164062, 44.9405012917663],
  [-85.484619140625, 44.959939425508466],
  [-85.46951293945312, 44.958967675170506],
  [-85.46676635742186, 44.96576958192208],
  [-85.47225952148438, 44.982285140923764],
  [-85.48049926757812, 44.990055522906864],
  [-85.50247192382812, 44.97937097612598],
  [-85.52032470703125, 44.96771283573075],
  [-85.53268432617188, 44.91716684156227],
  [-85.56289672851562, 44.89576860124879],
  [-85.55465698242188, 44.89187715629887],
  [-85.54092407226562, 44.90063253713748],
  [-85.528564453125, 44.896741421341964],
  [-85.52444458007812, 44.883120442385646],
  [-85.54641723632812, 44.860736117070026],
  [-85.57525634765625, 44.81691551782855],
  [-85.59173583984374, 44.77111175531263],
  [-85.60546875, 44.76428680790121],
  [-85.63018798828125, 44.76818687659432],
  [-85.63842773437499, 44.78475923091046],
  [-85.6494140625, 44.813018740612776],
  [-85.6494140625, 44.84029065139799],
  [-85.63842773437499, 44.88701247981298],
  [-85.62744140625, 44.92202896709906],
  [-85.60546875, 44.94536144236941],
  [-85.62744140625, 44.968684437948376],
  [-85.6329345703125, 44.99199795382439],
  [-85.6219482421875, 45.034714778688596],
  [-85.5780029296875, 45.058001435398296],
  [-85.5780029296875, 45.07739974122637],
  [-85.60546875, 45.09291364605112],
  [-85.60546875, 45.116176603574864],
  [-85.60546875, 45.13943008146787],
  [-85.5670166015625, 45.1510532655634],
  [-85.5560302734375, 45.182036837015886],
  [-85.616455078125, 45.182036837015886],
  [-85.6439208984375, 45.1510532655634],
  [-85.6768798828125, 45.1278045274732],
  [-85.69335937499999, 45.089035564831015],
  [-85.7098388671875, 45.058001435398296],
  [-85.7373046875, 45.03083274759959],
  [-85.770263671875, 44.999767019181284],
  [-85.770263671875, 44.97645666320777],
  [-85.814208984375, 44.93758500391091],
  [-85.84167480468749, 44.933696389694674],
  [-85.89111328125, 44.92591837128866],
  [-85.9130859375, 44.953136827528816],
  [-85.946044921875, 44.953136827528816],
  [-85.97900390625, 44.94536144236941],
  [-85.97900390625, 44.90646871709883],
  [-86.0174560546875, 44.90646871709883],
  [-86.06689453125, 44.91035917458495],
  [-86.06689453125, 44.88701247981298],
  [-86.077880859375, 44.83639545410477],
  [-86.077880859375, 44.797428998555645],
  [-86.0888671875, 44.758436211143476],
  [-86.1328125, 44.715513732021336],
  [-86.165771484375, 44.715513732021336],
  [-86.220703125, 44.715513732021336],
  [-86.2481689453125, 44.692088041727786],
  [-86.24267578125, 44.64911632343077],
  [-86.2371826171875, 44.62566377574355],
  [-86.2042236328125, 44.59829048984011],
  [-86.19873046875, 44.55133484083592]
]);

export const homeBaseRangeExtendedStroke = turf.bezierSpline(line);
